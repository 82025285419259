import React from 'react';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import logo from '../../img/kolovna_emblem_bg_black.svg';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PhoneIcon from '@mui/icons-material/PhoneAndroid';
import SettingsApplications from '@mui/icons-material/Tune';
import { Link } from 'react-router-dom';
import { UC0002, UC0003, UC0005 } from '../../utils/constants';
import { isComponentEnabledForUser, isComponentVisibleForUser } from '../../utils';


const useStyles = makeStyles({
  list: {
    width: 60,
    backgroundColor: 'white',
  },
  listItemIcon: {
    width: 60,
  },
  icon: {
    height: '32px',
    width: '32px',
  },
  logo: {
    height: '56px',
  }
});

export default function LeftDrawer(props) {
  const { userUseCases } = props;
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      onMouseLeave={toggleDrawer(anchor, false)}
    >
      <List>
        {isComponentVisibleForUser(UC0002, userUseCases) &&
          <Link to={isComponentEnabledForUser(UC0002, userUseCases) ? `/${process.env.REACT_APP_PATH}/reservation` : '#'} >
            <ListItem button key='Reservation'>
              <ListItemIcon className={classes.listItemIcon}>
                <EventAvailableIcon className={classes.icon}/>
              </ListItemIcon>
            </ListItem>
          </Link>
        }
        {isComponentVisibleForUser(UC0005, userUseCases) &&
          <Link to={isComponentEnabledForUser(UC0005, userUseCases) ? `/${process.env.REACT_APP_PATH}/mobile` : '#'} >
            <ListItem button key='Mobile'>
              <ListItemIcon className={classes.listItemIcon}>
                <PhoneIcon className={classes.icon}/>
              </ListItemIcon>
            </ListItem>
          </Link>
        }
        {isComponentVisibleForUser(UC0003, userUseCases) &&
          <Link to={isComponentEnabledForUser(UC0003, userUseCases) ? `/${process.env.REACT_APP_PATH}/administration` : '#'} >
            <ListItem button key='Admin'>
              <ListItemIcon className={classes.listItemIcon}>
                <SettingsApplications className={classes.icon}/>
              </ListItemIcon>
            </ListItem>
          </Link>
        }
      </List>
    </div>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <img src={logo}
            onClick={toggleDrawer(anchor, true)}
            onMouseEnter={toggleDrawer(anchor, true)}
            className={classes.logo} />
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
