import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { makeStyles } from '@mui/styles';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PerfectScrollbar from "react-perfect-scrollbar";
import ToolBar from './ToolBar';
import {Grid} from '@mui/material';
import {ToggleButton} from '@mui/lab';
import TocIcon from '@mui/icons-material/Toc';
import PlaceList from "./PlaceList";
import {fetchTenants} from "../../../redux/actions/tenant";
import {fetchPlaces, createPlace, updatePlace, activePlace} from "../../../redux/actions/place";
import PlaceUpdate from '../../../components/modal/PlaceUpdate';
import { getErrorMessage } from '../../../utils';

const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    title: {
        marginBottom: theme.spacing(3.75),
        marginTop: theme.spacing(3.75),
        textTransform: 'uppercase',
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    container: {
        background: theme.palette.base.white,
        marginTop: theme.spacing(4),
    },
}));

function PlaceAdministration(props) {
    const {
        user,
        userUseCases,
        places,
        fetchPlaces,
        createPlace,
        updatePlace,
        activePlace,
        tenants,
        fetchTenants,
    } = props;

    const classes = useStyles();
    const {t} = useTranslation();

    const [table, setTable] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedPlaceName, setSelectedPlaceName] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState('');
    const [isFetchedPlaces, setIsFetchedPlaces] = useState(false);
    const [isFetchedTenants, setIsFetchedTenants] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [saving, setSaving] = useState(false);
    const [confirmModalOpened, setConfirmModalOpened] = useState(false);

    useEffect(()=>{
        if ( !isFetchedPlaces ) {
            fetchPlaces()
                .then(()=>{
                    setIsFetchedPlaces(true);
                })
                .catch((err)=>{
                    setIsFetchedPlaces(false);
                    console.log(err);
                });
        }
    }, [fetchPlaces, isFetchedPlaces]);

    useEffect(()=>{
        if ( !isFetchedTenants ) {
            fetchTenants()
                .then(()=>{
                    setIsFetchedTenants(true);
                })
                .catch((err)=>{
                    setIsFetchedTenants(false);
                    console.log(err);
                });
        }
    }, [fetchTenants, isFetchedTenants]);

    useEffect(()=>{
        if ( places && places.length>0 ) {
            const filPlace = [];
            for (let i = 0; i < places.length; i++) {
                let matchCriteria = true;
                if (selectedPlaceName && selectedPlaceName.trim().length > 0) {
                    if (places[i].name) {
                        if (!(places[i].name.toLowerCase().includes(selectedPlaceName.toLowerCase()))) {
                            matchCriteria = false;
                        }
                    }
                }

                if (selectedStatus && selectedStatus.length > 0) {
                    const res = matchStatus(places[i]);
                    if (!res) {
                        matchCriteria = false;
                    }
                }

                if (matchCriteria) {
                    filPlace.push(places[i]);
                }
            }
            setFilteredPlaces(filPlace);            
        }
    }, [places, selectedPlaceName, selectedStatus]);

    const [filteredPlaces, setFilteredPlaces] = useState([]);

    const handleSelectStatus = (value) => {
        setSelectedStatus(value);
    };

    const handleSelectedPlaceName = (value) => {
        setSelectedPlaceName(value.target.value);
    }

    const [placeConfigModel, setPlaceConfigModel] = useState({
        open: false,
        place: {tenantId: user?.tenantId},
        isNew: true
    });
  
    const handlePlaceUpdate = (place, isNew) => {
        setError('');
        setSnackbarOpen(false);
        setReadOnly(false);

        let newPlace = {...place};
        newPlace.active=newPlace.state==='A'?'true':'false';
        setPlaceConfigModel({isNew: isNew, place: newPlace, open: true});
    };

    const handlePlaceDetail = (place, isNew) => {
        setError('');
        setSnackbarOpen(false);
        setReadOnly(true);

        let newPlace = {...place};
        newPlace.active=newPlace.active===true?'true':(newPlace.active===false?'false':'');
        setPlaceConfigModel({isNew: isNew, place: newPlace, open: true});
    };

    const handleClose = () => {
        setError('');
        setSnackbarOpen(false);
        setPlaceConfigModel({isNew: false, place:{}, open:false});
    }

    const savePlace = (place) => {
        let tmpPlaceConfig = [];
        for ( let i=0; i<place.stands.length; i++ ) {
            if ( place.stands[i].checked ) {
                tmpPlaceConfig.push(
                    {
                        bike:{bikeId:place.stands[i].bikeId},
                        stand:{standId:place.stands[i].standId},
                        standName:place.stands[i].customName,
                        placeConfigId:place.stands[i].placeConfigId,
                    }
                );
            }
        }

        let tmpPlace = {...place,
            state: place.active==='true'?'A':'I',
            controlUnit: {controlUnitId:place.controlUnit},
            placeConfigs:tmpPlaceConfig,
        };
        if ( place.placeId ) {
            updatePlace(tmpPlace)
                .then(()=>{
                    setSaving(false);
                    setIsFetchedPlaces(false);
                    handleClose();
                })
                .catch((error)=>{
                    console.log('update place error', error);
                    setSnackbarOpen(true);
                    setSaving(false);
                    setError(getErrorMessage(error, t));
                });
        } else {
            createPlace(tmpPlace)
                .then(()=>{
                    setSaving(false);
                    setIsFetchedPlaces(false);
                    handleClose();
                })
                .catch((error)=>{
                    console.log('create place error', error);
                    setSnackbarOpen(true);
                    setSaving(false);
                    setError(getErrorMessage(error, t));
                });
        }
    }

    const handleActivate = (place) => {
        activePlace(place.placeId, place.state==='A'?false:true)
            .then(()=>{
                setConfirmModalOpened(false);
                setIsFetchedPlaces(false);
            })
            .catch((error)=>{
                console.log('activate place error', error);
                setSnackbarOpen(true);
                setSaving(false);
                setError(getErrorMessage(error, t));
            });
    }

    const matchStatus = (place) => {
        let res = false;
        for (let i = 0; i < selectedStatus.length; i++) {
            res = ( selectedStatus[i].key === 'ACTIVE' && place.state==='A' ) || ( selectedStatus[i].key === 'INACTIVE' && place.state==='I' );
            if (res) {
                return true;
            }
        }
        return false;
    }

    return (
        <PerfectScrollbar
            options={{
                suppressScrollX: true,
                minScrollbarLength: 50,
            }}
        >
            <div className={classes.container}>
                <ToolBar
                    userUseCases={userUseCases}
                    selectedPlaceName={selectedPlaceName}
                    handleSelectedPlaceName={handleSelectedPlaceName}
                    selectedStatus={selectedStatus}
                    handleSelectStatus={handleSelectStatus}
                    handlePlaceUpdate={handlePlaceUpdate}
                    user={user}
                />

                <Grid container>
                    <Grid item sm={11}>
                    </Grid>
                    <Grid item sm={1}>
                        <ToggleButton
                            style={{width: '30px', height: '30px'}}
                            value="check"
                            selected={table}
                            onChange={() => {
                                setTable(!table);
                            }}
                        >
                            <TocIcon/>
                        </ToggleButton>
                    </Grid>
                </Grid>

                <PlaceList 
                    userUseCases={userUseCases}
                    places={filteredPlaces}
                    table={table}
                    loading={isFetchedPlaces}
                    handlePlaceUpdate={handlePlaceUpdate}
                    handlePlaceDetail={handlePlaceDetail}
                    handleActivate={handleActivate}
                    confirmModalOpened={confirmModalOpened}
                    setConfirmModalOpened={setConfirmModalOpened}
                />

            </div>

            <PlaceUpdate 
                userUseCases={userUseCases}
                placeConfigModel={placeConfigModel}
                handleClose={handleClose}
                readOnly={readOnly}
                savePlace={savePlace}
                saving={saving}
                setSaving={setSaving}
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                error={error}
                tenants={tenants}
            />

        </PerfectScrollbar>
    )
}

const mapStateToProps = (store) => ({
    user: store.authData.user,
    userUseCases: store.authData.userUseCases,
    places: store.placeData.places,
    tenants: store.tenantsData.tenants,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchPlaces,
    createPlace,
    updatePlace,
    activePlace,
    fetchTenants,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PlaceAdministration);
