import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { makeStyles } from '@mui/styles';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PerfectScrollbar from "react-perfect-scrollbar";
import ToolBar from './ToolBar';
import {Grid} from '@mui/material';
import {ToggleButton} from '@mui/lab';
import TocIcon from '@mui/icons-material/Toc';
import BikeList from "./BikeList";
import {fetchBikes, createBike, updateBike, actiavateBike} from "../../../redux/actions/bike";
import BikeUpdate from '../../../components/modal/BikeUpdate';
import { getErrorMessage } from '../../../utils';
import { ENTITY_STATE_ACTIVE, ENTITY_STATE_INACTIVE } from '../../../utils/listitem';

const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    title: {
        marginBottom: theme.spacing(3.75),
        marginTop: theme.spacing(3.75),
        textTransform: 'uppercase',
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    container: {
        background: theme.palette.base.white,
        marginTop: theme.spacing(4),
    },
}));

function BikeAdministration(props) {
    const {
        userUseCases,
        bikes,
        fetchBikes,
        createBike,
        updateBike,
        actiavateBike,
        user
    } = props;

    const classes = useStyles();
    const {t} = useTranslation();

    const [table, setTable] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(undefined);
    const [selectedBikeName, setSelectedBikeName] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState('');
    const [isFetchedBikes, setIsFetchedBikes] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [saving, setSaving] = useState(false);
    const [confirmModalOpened, setConfirmModalOpened] = useState(false);
    const [filteredBikes, setFilteredBikes] = useState([]);
    const [bikeConfigModel, setBikeConfigModel] = useState({
        open: false,
        bike: {},
        isNew: true
    });

    useEffect(()=>{
        if ( !isFetchedBikes ) {
            fetchBikes()
                .then(()=>{
                    setIsFetchedBikes(true);
                })
                .catch((err)=>{
                    setIsFetchedBikes(false);
                    console.log(err);
                });
        }
    }, [fetchBikes, isFetchedBikes]);

    useEffect(()=>{
        if ( bikes && bikes.length>0 ) {
            const filBikes = [];
            for (let i = 0; i < bikes.length; i++) {
                let matchCriteria = true;
                if (selectedBikeName && selectedBikeName.trim().length > 0) {
                    if (bikes[i].name) {
                        if (!(bikes[i].name.toLowerCase().includes(selectedBikeName.toLowerCase()))) {
                            matchCriteria = false;
                        }
                    }
                }

                if (selectedStatus && selectedStatus.length > 0) {
                    const res = matchStatus(bikes[i]);
                    if (!res) {
                        matchCriteria = false;
                    }
                }

                if (matchCriteria) {
                    filBikes.push(bikes[i]);
                }
            }
            setFilteredBikes(filBikes);            
        }
    }, [bikes, selectedBikeName, selectedStatus]);

    const handleSelectStatus = (value) => {
        setSelectedStatus(value);
    };
  
    const handleUpdate = (bike, isNew) => {
        setError('');
        setSnackbarOpen(false);
        setReadOnly(false);

        let newBike = {...bike};
        newBike.active=newBike.active===true?'true':(newBike.active===false?'false':'');
        setBikeConfigModel({isNew: isNew, bike: newBike, open: true});
    };

    const handleDetail = (bike, isNew) => {
        setError('');
        setSnackbarOpen(false);
        setReadOnly(true);

        let newBike = {...bike};
        newBike.active=newBike.active===true?'true':(newBike.active===false?'false':'');
        setBikeConfigModel({isNew: isNew, bike: newBike, open: true});
    };

    const handleClose = () => {
        setError('');
        setSnackbarOpen(false);
        setBikeConfigModel({isNew: false, bike:{}, open:false});
    }

    const saveBike = (bike) => {
        let tmp = {...bike, state: bike.active==='true'?'A':'I' };
        if ( bike.bikeId ) {
            updateBike(tmp)
                .then(()=>{
                    setSaving(false);
                    setIsFetchedBikes(false);
                    handleClose();
                })
                .catch((error)=>{
                    console.log('update bike error', error);
                    setSnackbarOpen(true);
                    setSaving(false);
                    setError(getErrorMessage(error, t));
                });
        } else {
            createBike(tmp)
                .then(()=>{
                    setSaving(false);
                    setIsFetchedBikes(false);
                    handleClose();
                })
                .catch((error)=>{
                    console.log('create bike error', error);
                    setSnackbarOpen(true);
                    setSaving(false);
                    setError(getErrorMessage(error, t));
                });
        }
    }

    const handleActivate = (bike) => {
        actiavateBike(bike.bikeId, bike.state!=='A')
            .then(()=>{
                setConfirmModalOpened(false);
                setIsFetchedBikes(false);
            })
            .catch((error)=>{
                console.log('save state bike error', error);
                setSnackbarOpen(true);
                setSaving(false);
                setError(getErrorMessage(error, t));
            });
}

    const matchStatus = (bike) => {
        let res = false;
        for (let i = 0; i < selectedStatus.length; i++) {
            res = ( selectedStatus[i].key === 'ACTIVE' && bike.state===ENTITY_STATE_ACTIVE ) || 
                ( selectedStatus[i].key === 'INACTIVE' && bike.state===ENTITY_STATE_INACTIVE );
            if (res) {
                return true;
            }
        }
        return false;
    }

    return (
        <PerfectScrollbar
            options={{
                suppressScrollX: true,
                minScrollbarLength: 50,
            }}
        >
            <div className={classes.container}>
                <ToolBar
                    userUseCases={userUseCases}
                    selectedName={selectedBikeName}
                    handleSelectedName={(value) => setSelectedBikeName(value.target.value)}
                    selectedStatus={selectedStatus}
                    handleSelectStatus={handleSelectStatus}
                    handleUpdate={handleUpdate}
                    user={user}
                />

                <Grid container>
                    <Grid item sm={11}>
                    </Grid>
                    <Grid item sm={1}>
                        <ToggleButton
                            style={{width: '30px', height: '30px'}}
                            value="check"
                            selected={table}
                            onChange={() => {
                                setTable(!table);
                            }}
                        >
                            <TocIcon/>
                        </ToggleButton>
                    </Grid>
                </Grid>

                <BikeList 
                    userUseCases={userUseCases}
                    bikes={filteredBikes}
                    table={table}
                    isLoading={!isFetchedBikes}
                    handleUpdate={handleUpdate}
                    handleDetail={handleDetail}
                    handleActivate={handleActivate}
                    confirmModalOpened={confirmModalOpened}
                    setConfirmModalOpened={setConfirmModalOpened}
                />

            </div>

            <BikeUpdate 
                userUseCases={userUseCases}
                configModel={bikeConfigModel}
                handleClose={handleClose}
                readOnly={readOnly}
                save={saveBike}
                saving={saving}
                setSaving={setSaving}
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                error={error}
            />

        </PerfectScrollbar>
    )
}

const mapStateToProps = (store) => ({
    user: store.authData.user,
    userUseCases: store.authData.userUseCases,
    bikes: store.bikeData.bike
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchBikes,
    createBike,
    updateBike,
    actiavateBike,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BikeAdministration);
