import httpService from './http.service';


const createPlace = (place) => httpService
    .post(`/place`, place)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const updatePlace = (place) => httpService
    .put(`/place`, place)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const activePlace = (placeId, active) => httpService
    .put(`/place/${placeId}/?active=${active}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const getPlaces = () => httpService
    .get(`/place/all`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


export default {
    createPlace,
    updatePlace,
    activePlace,
    getPlaces,
};