import React, { useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { CustomTable, Loading } from '../../../components/elements';
import { UC0143, UC0144, UC0145 } from '../../../utils/constants';
import { isComponentEnabledForUser, isComponentVisibleForUser } from '../../../utils';
import { BottomNavigation, BottomNavigationAction, IconButton, Tooltip, Typography } from '@mui/material';
import { ActionButton } from "../../../components/common";
import CertificateIcon from '@mui/icons-material/VerifiedUser';
import SecurityIcon from '@mui/icons-material/Security';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from "@mui/icons-material/Cancel";
import PlusIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import PDSDialog from '../../../components/common/PDSDialog';
import { palette } from '../../../components/modal/BikeUpdate/BikeUpdate';


const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: 100,
        paddingTop: 20,
    },
    entityCard: {
        width: 384,
        height: 200,
        margin: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: theme.palette.base[100],
        borderStyle: 'solid',
        borderWidth: '2px',
        cursor: 'pointer',
        borderColor: theme.palette.base[200],
        boxShadow: '10px 10px 8px #EEEEEE',
    },
    actions: {
        display: 'flex',
        width: '75%',
        justifyContent: 'space-around',
    },
    green: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    green2: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.base.white,
        minWidth: '30%',
        marginRight: theme.spacing(2.5),
    },
    orange: {
        backgroundColor: theme.palette.primary.yellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red: {
        backgroundColor: theme.palette.primary.red,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red2: {
        backgroundColor: theme.palette.primary.red,
        color: theme.palette.base.white,
        minWidth: '30%',
        marginRight: theme.spacing(2.5),
    },
    white: {
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.base.black,
        minWidth: '50%',
    },
    brown: {
        backgroundColor: theme.palette.secondary.darkYellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue2: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.base.white,
        minWidth: '30%',
        marginRight: theme.spacing(2.5),
    },
    title: {
        color: theme.palette.base[700],
        '& .MuiTypography-root': {
            lineHeight: '25px',
        },
    },
    label: {
        color: theme.palette.base[400],
        fontSize: 16,
        textAlign: 'center',
        marginLeft: 20
    },
    value: {
        color: theme.palette.base[700],
        fontSize: 12,
        minHeight: 35,
        textAlign: 'center',
        marginLeft: 0
    },
    label2: {
        color: theme.palette.base[400],
        fontSize: 16,
        textAlign: 'center',
    },
    value2: {
        color: theme.palette.base[700],
        fontSize: 12,
        minHeight: 35,
        textAlign: 'center',
    },
    row1: {
        width: 380,
        height: 90,
        fontSize: 14,
        padding: 5,
        marginTop: -10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    row2: {
        width: 380,
        height: 20,
        fontSize: 22,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        marginTop: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    rowColumn: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
    },
    rowColumn2: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 25,
    },
    attachment: {
        // height: 30,
        width: 120,
    },
}));

const tableOptions = {
    headerStyle: {
        padding: '15px 8px 15px 34px',
        lineHeight: '12px',
        color: '#1B1B28',
        textAlign: 'center',
    },
    paging: false
};
const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
};

const BikeList = props => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        userUseCases,
        bikes,
        isLoading,
        table,
        handleDetail,
        handleUpdate,
        confirmModalOpened,
        setConfirmModalOpened,
        handleActivate
    } = props;

    const [bikeToActive, setBikeToActive] = useState();
    const [actionInProgress, setActionInProgress] = useState(false);


    const renderBikeState = (param) => {
        if (!param) {
            return <Tooltip title={t('INACTIVE')}><SecurityIcon color="error" style={{ fontSize: 40 }} /></Tooltip>
        } else {
            if (param.state === 'A')
                return <Tooltip title={t('ACTIVE')}><CertificateIcon color="primary" style={{ fontSize: 40 }} /></Tooltip>
            else
                return <Tooltip title={t('INACTIVE')}><SecurityIcon color="error" style={{ fontSize: 40 }} /></Tooltip>
        }
    }

    const renderColor = (color) => {
        if (color) {
            return <div style={{ backgroundColor: color, width: 20, borderWidth: 1, borderColor: 'black', borderStyle: 'solid' }}>&nbsp;</div>
        } else {
            return <>{t('NOT_DEFINED')}</>
        }
    }

    const renderImg = (param) => {
        if (param && param.img) {
            return <img src={`${param.img}`}
                className={classes.attachment}
            />
        } else {
            return <>{t('NOT_DEFINED')}</>
        }
    }

    const columns = [
        {
            title: t('STATE'),
            field: 'state',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'center', paddingLeft: 5
            },
            render: (rowData) => (
                renderBikeState(rowData)
            )
        },
        {
            title: t('BIKE_NAME'),
            field: 'name',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.name
            )
        },
        {
            title: t('BIKE_CATEGORY'),
            field: 'category',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                t(rowData.category)
            )
        },
        {
            title: t('BIKE_TYPE'),
            field: 'type',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },

            render: (rowData) => (
                t(rowData.type)
            ),
        },
        {
            title: t('BIKE_SIZE'),
            field: 'size',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },

            render: (rowData) => (
                t(rowData.size)
            ),
        },
        {
            title: t('BIKE_COLOR'),
            field: 'color',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                renderColor(rowData && palette[rowData.color])
            ),
        },
        {
            title: '',
            field: 'action',
            sorting: false,
            cellStyle: {
                ...cellStyle,
                padding: '12px 4px',
            },
            headerStyle: {
                padding: '18px 34px',
            },
            render: (rowData) => (
                <div className={classes.actions}>
                    {isComponentVisibleForUser(UC0144, userUseCases) &&
                        <ActionButton
                            handleClick={() => { handleDetail(rowData) }}
                            disabled={!isComponentEnabledForUser(UC0144, userUseCases) || actionInProgress}
                            className={classes.green2}
                            action="detail"
                            title={t('SHOW_BIKE_DETAIL')}
                        />
                    }
                    {isComponentVisibleForUser(UC0143, userUseCases) &&
                        <ActionButton
                            handleClick={() => { handleUpdate(rowData, false) }}
                            disabled={!isComponentEnabledForUser(UC0143, userUseCases) || actionInProgress}
                            className={classes.red2}
                            action="edit"
                        />
                    }
                    {isComponentVisibleForUser(UC0145, userUseCases) &&
                        <ActionButton
                            handleClick={() => {
                                setBikeToActive(rowData);
                                setConfirmModalOpened(true);
                            }}
                            disabled={!isComponentEnabledForUser(UC0145, userUseCases) || actionInProgress}
                            className={classes.blue2}
                            action={rowData.state === 'A' ? t('DEACTIVATE') : t('ACTIVATE')}
                        />
                    }
                </div>
            ),
        },

    ];

    return (
        <div className={classes.container}>
            {confirmModalOpened
                && (
                    <PDSDialog
                        open={confirmModalOpened}
                        title={`${bikeToActive.state === 'A' ? t('BIKE_INACTIVATION') : t('BIKE_ACTIVATION')}`}
                        onClose={() => {
                            setConfirmModalOpened(false);
                            setBikeToActive(null);
                        }}
                        size="sm"
                        headerColor='#5985EE'
                        height={300}
                        onSubmit={() => { handleActivate(bikeToActive); }}
                        actionType={bikeToActive.state === 'A' ? 'roleDeactivate' : 'roleActivate'}
                    >

                        <div className={classes.content}>
                            {bikeToActive.state === 'A' ? t('CONFIRM_BIKE_ACTIVE', { name: bikeToActive.name }) : t('CONFIRM_BIKE_DEACTIVE', { name: bikeToActive.name })}
                        </div>
                    </PDSDialog>
                )}
            {!isLoading ? table ? <CustomTable
                title=""
                columns={columns}
                data={bikes}
                options={tableOptions}
                isLoading={isLoading}
                loadAllData={false}
            /> :
                bikes.map((bikeItem, index) => (
                    <div className={classes.entityCard} key={index}>
                        <div className={classes.row1}>
                            <div className={classes.state}>
                                {renderBikeState(bikeItem)}
                            </div>
                            <div className={classes.title}>
                                <Tooltip title={t('BIKE_NAME')}>
                                    <Typography variant="h4">{bikeItem.name}</Typography>
                                </Tooltip>
                            </div>
                            <div className={classes.actionIcon}>
                                {isComponentVisibleForUser(UC0143, userUseCases) &&
                                    <Tooltip title={t('EDIT')}>
                                        <IconButton
                                            onClick={() => {
                                                handleUpdate(bikeItem, false)
                                            }}
                                            disabled={!isComponentEnabledForUser(UC0143, userUseCases)}
                                            size="large">
                                            <EditIcon color="disable" style={{ fontSize: 30 }} />
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                        </div>
                        <div className={classes.row2}>
                            <div className={classes.rowColumn}>
                                {t("NUMBER_OF_ASSIGNMENTS")} {bikeItem.numOfAssigments}
                            </div>
                        </div>
                        <BottomNavigation
                            className={classes.root}
                        >
                            {isComponentVisibleForUser(UC0144, userUseCases) &&
                                <Tooltip title={t('SHOW_BIKE_DETAIL')}>
                                    <BottomNavigationAction onClick={() => { handleDetail(bikeItem) }}
                                        disabled={actionInProgress || !isComponentEnabledForUser(UC0144, userUseCases)}
                                        className={classes.blue}
                                        label="Detail"
                                        icon={<InfoIcon className={classes.actionIcon} />}
                                    />
                                </Tooltip>
                            }
                            {isComponentVisibleForUser(UC0145, userUseCases) &&
                                <Tooltip title={bikeItem.state === 'A' ? t('SHOW_BIKE_DEACTIVE') : t('SHOW_BIKE_ACTIVE')}>
                                    <BottomNavigationAction onClick={() => {
                                        setBikeToActive(bikeItem);
                                        setConfirmModalOpened(true);
                                    }}
                                        disabled={actionInProgress || !isComponentEnabledForUser(UC0145, userUseCases)}
                                        className={classes.orange}
                                        label="Active"
                                        icon={bikeItem.state === 'A' ? <CancelIcon className={classes.actionIcon} /> : <PlusIcon className={classes.actionIcon} />}
                                    />
                                </Tooltip>
                            }
                        </BottomNavigation>
                    </div>
                )) : <Loading />
            }
        </div>
    );
}

BikeList.propTypes = {};

BikeList.defaultProps = {};

export default BikeList;