import types from '../actionTypes';
import placeService from '../../services/place.service';

export const createPlace = (place) => (dispatch, getState) => {
    if (getState().placeData.isCreating) {
        return;
    }

    dispatch({
        type: types.PLACE_CREATE_REQUEST,
    });

    return placeService.createPlace(place)
        .then(() => {
            dispatch({
                type: types.PLACE_CREATE_SUCCESS,
                payload: {place: place},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PLACE_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updatePlace = (place) => (dispatch, getState) => {
    if (getState().placeData.isUpdating) {
        return;
    }

    dispatch({
        type: types.PLACE_UPDATE_REQUEST,
    });

    return placeService.updatePlace(place)
        .then(() => {
            dispatch({
                type: types.PLACE_UPDATE_SUCCESS,
                payload: {place: place},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PLACE_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const activePlace = (placeId, active) => (dispatch, getState) => {
    if (getState().placeData.isActivating) {
        return;
    }

    dispatch({
        type: types.PLACE_ACTIVE_REQUEST,
    });

    return placeService.activePlace(placeId, active)
        .then((place) => {
            dispatch({
                type: types.PLACE_ACTIVE_SUCCESS,
                payload: {place: place},
            });
            return place;
        })
        .catch((error) => {
            dispatch({
                type: types.PLACE_ACTIVE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchPlaces = () => (dispatch, getState) => {
    if (getState().placeData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.PLACE_FETCH_REQUEST,
    });

    return placeService.getPlaces()
        .then((places) => {
            dispatch({
                type: types.PLACE_FETCH_SUCCESS,
                payload: { places: places },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PLACE_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};