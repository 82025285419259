import React, {useCallback, useEffect, useRef, useState} from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import {CustomTable} from '../../components/elements';
import { ActionButton, EventType } from '../../components/common';
import { isComponentEnabledForUser, isComponentVisibleForUser } from '../../utils';
import { UC0175, UC0177, UC0178, UC0179 } from '../../utils/constants';
import PDSDialog from '../../components/common/PDSDialog';

  
const useStyles = makeStyles((theme) => ({
    container: {
      background: theme.palette.base.white,
      padding: theme.spacing(3.75, 2),
      boxShadow: `3px 3px 5px ${theme.palette.base[200]}`,
    },
    containerModal: {
      '& .MuiDialog-paper': {
        width: 834,
        maxWidth: 'unset',
        height: 'fit-content',
        padding: theme.spacing(4.25, 3.25),
        boxShadow: theme.palette.shadow.main,
      },
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    mr: {
      marginRight: theme.spacing(2.5),
    },
    title: {
      marginBottom: theme.spacing(4.25),
      color: theme.palette.base[500],
  
      '& .MuiTypography-root': {
        fontSize: 24,
      },
    },
}));

const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#60636B',
    textAlign: 'center',
    padding: '20px 16px 20px 16px',
};

const MobileList = (props) => {
    const classes = useStyles();

    const {
      userUseCases,
      isLoading,
      mobileDevices,
      handleActivate,
      handleDelete,
      confirmModalOpened,
      setConfirmModalOpened,
      confirmModalOpened2,
      setConfirmModalOpened2,
    } = props;
  
    const { t } = useTranslation();
    const [mdmToActive, setMdmToActive] = useState();
    const [mdmToRemove, setMdmToRemove] = useState();

    const columns = [
        {
          title: t('DEVICE_ID'),
          field: 'deviceId',
          cellStyle,
        },
        {
          title: t('ACTIVE'),
          field: 'state',
          cellStyle,
          render: (rowData) => (
            <EventType type={rowData.state === 'MD_A' ? 'active' : 'inactive'} />
          ),
        },
        {
          title: t('ACTIVATION_EMAIL'),
          field: 'email',
          cellStyle,
        },
        {
          title: t('REGISTRED_BY'),
          field: 'user.userName',
          cellStyle,
        },
        {
          title: t('CREATED'),
          field: 'date',
          cellStyle,
        },
        {
          title: '',
          sorting: false,
          cellStyle,
          headerStyle: {
            padding: '15px 34px 15px 34px',
          },
          render: (rowData) => {
            return <>
              { ( 
                ( rowData.state === 'MD_A' && isComponentVisibleForUser(UC0178, userUseCases) ) || 
                ( rowData.state === 'MD_I' && isComponentVisibleForUser(UC0177, userUseCases) ) ||
                ( rowData.state === 'MD_C' && isComponentVisibleForUser(UC0177, userUseCases) ) 
              ) &&
                <ActionButton
                    action={rowData.state === 'MD_A' ? 'deactivate' : 'activate'}
                    handleClick={()=>{
                      setMdmToActive(rowData);
                      setConfirmModalOpened(true);
                    }}
                    disabled={
                      (rowData.state === 'MD_A' && !isComponentEnabledForUser(UC0178, userUseCases)) ||
                      (rowData.state === 'MD_C' && !isComponentEnabledForUser(UC0177, userUseCases)) ||
                      (rowData.state === 'MD_I' && !isComponentEnabledForUser(UC0177, userUseCases))
                    }
                />
              }
            </>
          },
        },
        {
          title: '',
          sorting: false,
          cellStyle,
          headerStyle: {
            padding: '15px 34px 15px 34px',
          },
          render: (rowData) => {
            return <>
              { isComponentVisibleForUser(UC0179, userUseCases) &&
                <ActionButton
                  action={'delete'}
                  handleClick={()=>{
                    setMdmToRemove(rowData);
                    setConfirmModalOpened2(true);
                  }}                  
                  disabled={!isComponentEnabledForUser(UC0179, userUseCases)}
                />
              }
            </>
          },
        },
      ];
    
      return (
        <>
        {confirmModalOpened
        && (    
            <PDSDialog
                open={confirmModalOpened}
                title={`${mdmToActive.state==='MD_A'?t('MDM_INACTIVATION'):t('MDM_ACTIVATION')}`}
                onClose={() => {
                    setConfirmModalOpened(false);
                    setMdmToActive(null);
                }}
                size="sm"
                headerColor={mdmToActive.state==='MD_A'?'red':'#5985EE'}
                height={300}
                onSubmit={handleActivate(mdmToActive)}
                actionType={mdmToActive.state==='MD_A'?'mdmDeactivate':'mdmActivate'}
            >
                
                <div className={classes.content}>
                {mdmToActive.state==='MD_A'?t('CONFIRM_MDM_DEACTIVE', {mdm: mdmToActive.deviceId}):t('CONFIRM_MDM_ACTIVE', {mdm: mdmToActive.deviceId})}
                </div>
            </PDSDialog>
        )}

        {confirmModalOpened2
        && (    
            <PDSDialog
                open={confirmModalOpened2}
                title={t('MDM_REMOVE')}
                onClose={() => {
                    setConfirmModalOpened2(false);
                    setMdmToRemove(null);
                }}
                size="sm"
                headerColor={'red'}
                height={300}
                onSubmit={handleDelete(mdmToRemove)}
                actionType={'delete'}
            >
                
                <div className={classes.content}>
                {t('CONFIRM_MDM_REMOVE', {mdm: mdmToRemove.deviceId})}
                </div>
            </PDSDialog>
        )}

        <CustomTable
          title=""
          columns={columns}
          data={mobileDevices}
          options={{
            headerStyle: {
              padding: '15px 8px 15px 34px',
              borderTop: '1px solid #DEE2E5',
              lineHeight: '12px',
              color: '#1B1B28',
              textAlign: 'center',
            },
          }}
          isLoading={isLoading}
          rowsPerPage={20}
        />
        </>
      );

}

export default MobileList;