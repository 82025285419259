export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ";
export const DATE_FORMAT = "YYYY-MM-DD";

export const UC0000 = "UC0000";  // Seznam číselníku
export const UC0001 = "UC0001";  // Odhlásit
export const UC0002 = "UC0002";  // Menu rezervace
export const UC0003 = "UC0003";  // Menu administrace
export const UC0004 = "UC0004";  // Seznam UC
export const UC0005 = "UC0005";  // Menu MDM
export const UC0100 = "UC0100";  // Rozcestník administrace
export const UC0101 = "UC0101";  // Seznam rezervací
export const UC0102 = "UC0102";  // Založení rezervace
export const UC0103 = "UC0103";  // Úprava rezervace
export const UC0104 = "UC0104";  // Detail rezervace
export const UC0105 = "UC0105";  // Deaktivace rezervace
export const UC0106 = "UC0106";  // Smazání rezervace
export const UC0107 = "UC0107";  // Export rezervací
export const UC0108 = "UC0108";  // Zobrazení rezervací jiných uživatelů
export const UC0111 = "UC0111";  // Seznam nájemců
export const UC0112 = "UC0112";  // Založení nájemce
export const UC0113 = "UC0113";  // Úprava nájemce
export const UC0114 = "UC0114";  // Detail nájemce
export const UC0115 = "UC0115";  // Deaktivace nájemce
export const UC0121 = "UC0121";  // Seznam míst
export const UC0122 = "UC0122";  // Založení místa
export const UC0123 = "UC0123";  // Úprava místa
export const UC0124 = "UC0124";  // Detail místa
export const UC0125 = "UC0125";  // Deaktivace místa
export const UC0131 = "UC0131";  // Seznam stojanů
export const UC0132 = "UC0132";  // Založení stojanu
export const UC0133 = "UC0133";  // Úprava stojanu
export const UC0134 = "UC0134";  // Detail stojanu
export const UC0135 = "UC0135";  // Deaktivace stojanu
export const UC0136 = "UC0136";  // Manuální otevření stojanu
export const UC0141 = "UC0141";  // Seznam kol
export const UC0142 = "UC0142";  // Založení kola
export const UC0143 = "UC0143";  // Úprava kola
export const UC0144 = "UC0144";  // Detail kola
export const UC0145 = "UC0145";  // Deaktivace kola
export const UC0151 = "UC0151";  // Seznam rolí
export const UC0152 = "UC0152";  // Založení role
export const UC0153 = "UC0153";  // Úprava role
export const UC0154 = "UC0154";  // Detail role
export const UC0155 = "UC0155";  // Deaktivace role
export const UC0161 = "UC0161";  // Seznam uživatelů
export const UC0162 = "UC0162";  // Založení uživatele
export const UC0163 = "UC0163";  // Úprava uživatele
export const UC0164 = "UC0164";  // Detail uživatele
export const UC0165 = "UC0165";  // Deaktivace uživatele
export const UC0175 = "UC0175";  // Seznam zařízení
export const UC0176 = "UC0176";  // Žádost o registraci nového zařízení
export const UC0177 = "UC0177";  // Aktivace registrace
export const UC0178 = "UC0178";  // Deaktivace registrace
export const UC0179 = "UC0179";  // Smazání registrace
export const UC0500 = "UC0500";  // Přihlášení do mobilní aplikace
export const UC0501 = "UC0501";  // Seznam profilů
export const UC0502 = "UC0502";  // Zobrazení aktuálních rezervací
export const UC0503 = "UC0503";  // Otevření zámku stojanu kola
export const UC0504 = "UC0504";  // Vytvoření rezervace
export const UC0505 = "UC0505";  // Uzavření rezervace
export const UC0506 = "UC0506";  // Odhlášení
export const UC0507 = "UC0507";  // O aplikaci
export const UC0508 = "UC0508";  // Vstupní stránka (Landing page)
export const UC0509 = "UC0509";  // Detail rezervace
