import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { makeStyles } from '@mui/styles';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PerfectScrollbar from "react-perfect-scrollbar";
import ToolBar from './ToolBar';
import {Grid} from '@mui/material';
import {ToggleButton} from '@mui/lab';
import TocIcon from '@mui/icons-material/Toc';
import StandList from "./StandList";
import {fetchStands} from "../../../redux/actions/stand";

const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    title: {
        marginBottom: theme.spacing(3.75),
        marginTop: theme.spacing(3.75),
        textTransform: 'uppercase',
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    container: {
        background: theme.palette.base.white,
        marginTop: theme.spacing(4),
    },
}));

function StandAdministration(props) {
    const {
        userUseCases,
        stands,
        fetchStands,
    } = props;

    const classes = useStyles();
    const [table, setTable] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedStandName, setSelectedStandName] = useState('');
    const [isFetchedStands, setIsFetchedStands] = useState(false);
    const [filteredStands, setFilteredStands] = useState([]);

    useEffect(()=>{
        if ( !isFetchedStands ) {
            fetchStands()
                .then(()=>{
                    setIsFetchedStands(true);
                })
                .catch((err)=>{
                    setIsFetchedStands(false);
                    console.log(err);
                });
        }
    }, [fetchStands, isFetchedStands]);

    useEffect(()=>{
        if ( stands && stands.length>0 ) {
            const filStand = [];
            for (let i = 0; i < stands.length; i++) {
                let matchCriteria = true;
                if (selectedStandName && selectedStandName.trim().length > 0) {
                    if (stands[i].name) {
                        if (!(stands[i].name.toLowerCase().includes(selectedStandName.toLowerCase()))) {
                            matchCriteria = false;
                        }
                    }
                }

                if (selectedStatus && selectedStatus.length > 0) {
                    const res = matchStatus(stands[i]);
                    if (!res) {
                        matchCriteria = false;
                    }
                }

                if (matchCriteria) {
                    filStand.push(stands[i]);
                }
            }
            setFilteredStands(filStand);            
        }
    }, [stands, selectedStandName, selectedStatus]);

    const handleSelectedStandName = (value) => {
        setSelectedStandName(value.target.value);
    }
    const handleSelectStatus = (value) => {
        setSelectedStatus(value);
    };

    const matchStatus = (stand) => {
        let res = false;
        for (let i = 0; i < selectedStatus.length; i++) {
            res = ( selectedStatus[i].key === 'ACTIVE' && stand.active===true ) || ( selectedStatus[i].key === 'INACTIVE' && stand.active===false );
            if (res) {
                return true;
            }
        }
        return false;
    }

    return (
        <PerfectScrollbar
            options={{
                suppressScrollX: true,
                minScrollbarLength: 50,
            }}
        >
            <div className={classes.container}>
                <ToolBar
                    userUseCases={userUseCases}
                    selectedStandName={selectedStandName}
                    handleSelectedStandName={handleSelectedStandName}
                    selectedStatus={selectedStatus}
                    handleSelectStatus={handleSelectStatus}
                />

                <Grid container>
                    <Grid item sm={11}>
                    </Grid>
                    <Grid item sm={1}>
                        <ToggleButton
                            style={{width: '30px', height: '30px'}}
                            value="check"
                            selected={table}
                            onChange={() => {
                                setTable(!table);
                            }}
                        >
                            <TocIcon/>
                        </ToggleButton>
                    </Grid>
                </Grid>

                <StandList
                    userUseCases={userUseCases}
                    stands={filteredStands}
                    table={table}
                    loading={isFetchedStands}
                />

            </div>
        </PerfectScrollbar>
    )
}

const mapStateToProps = (store) => ({
    user: store.authData.user,
    userUseCases: store.authData.userUseCases,
    stands: store.standData.stands
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchStands,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StandAdministration);
