import types from '../actionTypes';

const INITIAL_STATE = {
    isFetchingAllList: false,
    isUpdating: false,
    isCreating: false,
    errors: '',
    reservations: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.RESERVATION_CREATE_REQUEST:
            return {
                ...state,
                isCreating: true,
            };
        case types.RESERVATION_CREATE_FAIL:
            return {
                ...state,
                isCreating: false,
                errors: action.payload.error,
            };
        case types.RESERVATION_CREATE_SUCCESS:
            return {
                ...state,
                isCreating: false,
            };

        case types.RESERVATION_UPDATE_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case types.RESERVATION_UPDATE_FAIL:
            return {
                ...state,
                isUpdating: false,
                errors: action.payload.error,
            };
        case types.RESERVATION_UPDATE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
            };

        case types.RESERVATION_DELETE_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case types.RESERVATION_UPDATE_FAIL:
            return {
                ...state,
                isUpdating: false,
                errors: action.payload.error,
            };
        case types.RESERVATION_DELETE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
            };

        case types.RESERVATION_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAllList: false,
                reservations: action.payload.reservations,
            };
        case types.RESERVATION_FETCH_FAIL:
            return {
                ...state,
                isFetchingAllList: false,
                errors: action.payload.error,
            };
        case types.RESERVATION_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAllList: true,
            };
        default:
            return state;
    }
};
