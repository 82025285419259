import types from '../actionTypes';
import controlUnitService from '../../services/controlUnit.service';

export const fetchControlUnits = () => (dispatch, getState) => {
    if (getState().controlUnitData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.CONTROL_UNIT_FETCH_REQUEST,
    });

    return controlUnitService.getControlUnits()
        .then((controlUnits) => {
            dispatch({
                type: types.CONTROL_UNIT_FETCH_SUCCESS,
                payload: { controlUnits: controlUnits },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CONTROL_UNIT_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};
