import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import {ActionButton} from '../../common';
import PDSSnackbar from "../../common/Snackbar";
import {fetchCodeLists} from '../../../redux/actions/codelists'
import BikeUpdate from "./BikeUpdate";
import {CircularProgress, Typography} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 830,
            minHeight: '60%',
            maxWidth: 1200,
            // boxShadow: theme.palette.shadow.main,
        },
        height: '100%',
    },
    actions: {
        padding: 0,
    },
    title: {
        height: 96,
        background: theme.palette.secondary.main,

        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),

    },
    button: {
        width: 180,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 14,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'left',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
}));

const requiredAttributes = ["name", "active", "type", "category", "size", "img", "color", "electric"];

const Bike = (props) => {
    const {
        handleClose,
        readOnly,
        save,
        configModel,
        error,
        saving,
        setSaving,
        snackbarOpen,
        setSnackbarOpen,
        fetchCodeLists,
        codeLists
    } = props;


    const classes = useStyles();

    const {t} = useTranslation();

    const [updatedBike, setUpdatedBike] = useState();
    const [errorStep1, setErrorStep1] = useState({});

    
    useEffect(() => {
        if (!codeLists || codeLists.length === 0) 
            fetchCodeLists()
    }, [codeLists]);   

    useEffect(() => {
        if (configModel.bike) {
            setUpdatedBike({...configModel.bike, active:configModel.bike.state==='A'?'true':'false'});
        }
        setErrorStep1({});
    }, [configModel]);   

    const isValid = () => {
        let ret = true;
        const error = {};

        for (const attr of requiredAttributes) {
            if (!updatedBike[attr] || String(updatedBike[attr]).length === 0) {
                error[attr] = 'required';
                ret = false;
            }
        }

        console.log('error', error);
        setErrorStep1(error);

        return ret;
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleChange = (name) => (event) => {
        if (event && event.target) {
            setUpdatedBike({...updatedBike, [name]: event.target.value});
        } else {
            setUpdatedBike({...updatedBike, [name]: event});
        }
    }

    return (
        <Dialog
            className={classes.container}
            open={configModel.open}
            onClose={handleClose}
        >
            <DialogTitle className={classnames(classes.title)}>
                <Typography variant="h2">{t(readOnly?'DETAIL_BIKE':(configModel.isNew ? 'CREATE_BIKE' : 'UPDATE_BIKE'))}</Typography>
            </DialogTitle>

            <PDSSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity={'error'} message={error}/>

            <DialogContent className={classes.content}>
                <div className={classes.container}>
                    <BikeUpdate 
                        handleChange={handleChange}
                        updatedBike={updatedBike}
                        setUpdatedBike={setUpdatedBike}
                        error={errorStep1}
                        readOnly={readOnly}
                        codeLists={codeLists}
                        isNew={configModel.isNew}
                    />
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigationCancel}>
                    <div className={classes.wrapper}>

                        <ActionButton
                            action="close"
                            handleClick={handleClose}
                            className={classes.button}
                        />
                    </div>

                </div>
                <div className={classes.buttonNavigation}>
                    <div className={classes.wrapper}>
                        {!readOnly &&
                            <>
                                <ActionButton
                                    
                                    loading={saving}
                                    action="save" handleClick={() => {
                                        const ret = isValid();
                                        if (ret) {
                                            setSaving(true);
                                            save(updatedBike);
                                        }
                                    }}
                                    className={classes.button}
                                    disabled={saving}
                                />
                                {saving && <CircularProgress size={24} className={classes.buttonProgress}/>}
                            </>
                        }
                    </div>
                </div>
            </DialogActions>
        </Dialog>

    );
}

Bike.propTypes = {};

const mapStateToProps = (store) => ({
    codeLists: store.codelistData.codelist,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCodeLists,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Bike);


