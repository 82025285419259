import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { BallSpinner } from 'react-spinners-kit';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function Loading(props) {
  const classes = useStyles();
  return (
    <div name="spinner" className={classes.container}>
    	<BallSpinner size={50} color="#686769" loading={ true } />
    </div>
  );
}

Loading.propTypes = {
  color: PropTypes.string,
};

Loading.defaultProps = {
  color: 'secondary',
};

export default Loading;
