import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import {ActionButton} from "../common";
import IconButton from "@mui/material/IconButton/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Check from "@mui/icons-material/Check";
import { Divider } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    
    content: {
        display: 'flex',
        flexDirection: props => props.flexDirection,
        justifyContent: 'space-evenly',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: '#aaaaaa',
    },
    title: {
        background: props => props.headerColor,
        paddingRight: theme.spacing(2),
        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: "black",
            '& .MuiButtonBase-root': {
                color: "black",
            },
            '& input + fieldset': {
                borderColor: "black"
            },
        },
    },
    actions: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        marginBottom: theme.spacing(2),
        "& .MuiButtonBase-root": {
            borderRadius: 30,
        },
    },
    dialogPaper: {
        height: props => `${props.height}px`,
        borderRadius: 25,
    },
    divider: {
        color: '#aaaaaa',
        margin: '1px'
    }
}));

const PDSDialog = ({
                       open,
                       title,
                       onClose,
                       onSubmit,
                       size,
                       height,
                       headerColor,
                       children,
                       actionType,
                       flexDirection,
                   }) => {
    const classes = useStyles({headerColor, height, flexDirection});
    const {t} = useTranslation();

    return (
        <Dialog
            disableEnforceFocus
            maxWidth={size}
            open={open}
            onClose={onClose}
            classes={{paper: classes.dialogPaper}}
            onKeyUp={(e) => {
                if (open) {
                    const ENTER = 13;
                    console.warn("submittting");
                    if (e.keyCode === ENTER && !e.shiftKey) {
                        onSubmit && onSubmit();
                    }
                }
            }}
        >
            <DialogTitle className={classes.title}>
                <Typography variant="h2">{title}</Typography>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    size="large">
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>

            <Divider className={classes.divider}/>

            <DialogContent className={classes.content}>
                {children}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <ActionButton
                    action="closeModal"
                    handleClick={onClose}
                />
                {onSubmit && <ActionButton
                    muiIcon={<Check/>}
                    type="submit"
                    action={actionType}
                    handleClick={onSubmit}
                />}
            </DialogActions>
        </Dialog>
    );
}

PDSDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    height: PropTypes.number,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    children: PropTypes.node,
    headerColor: PropTypes.string,
    actionType: PropTypes.string,
    flexDirection: PropTypes.string,    
};


PDSDialog.defaultProps = {
    children: null,
    height: 550,
    size: 'sm',
    headerColor: '#F5894A',
    actionType: 'saveModal',
    flexDirection: 'column',
};

export default PDSDialog;


