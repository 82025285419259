import types from '../actionTypes';

const INITIAL_STATE = {
    isCreating: false,
    isUpdating: false,
    isFetchingAllList: false,
    isFetchingAvailableList: false,
    isFetchingAllListByTenantId: false,
    errors: '',
    bike: [],
    availableBike: [],
    bikesByTenantId: [],
    availableBikeFrom: null,
    availableBikeTo: null,
    byTenantId: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.BIKE_FETCH_SUCCESS_BY_TENANT:
            return {
                ...state,
                isFetchingAllListByTenantId: false,
                bikesByTenantId: action.payload.bikes,
                byTenantId: action.payload.tenantId,
            };
        case types.BIKE_FETCH_FAIL_BY_TENANT:
            return {
                ...state,
                isFetchingAllListByTenantId: false,
                errors: action.payload.error,
            };
        case types.BIKE_FETCH_REQUEST_BY_TENANT:
            return {
                ...state,
                isFetchingAllListByTenantId: true,
            };

        case types.BIKE_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAllList: false,
                bike: action.payload.bikes,
            };
        case types.BIKE_FETCH_FAIL:
            return {
                ...state,
                isFetchingAllList: false,
                errors: action.payload.error,
            };
        case types.BIKE_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAllList: true,
            };

        case types.BIKE_CREATE_SUCCESS:
            return {
                ...state, isCreating: false
            };
        case types.BIKE_CREATE_REQUEST:
            return {
                ...state, isCreating: true
            };
        case types.BIKE_CREATE_FAIL:
            return {
                ...state, 
                isCreating: true,
                errors: action.payload.error,
            };

        case types.BIKE_UPDATE_SUCCESS:
            return {
                ...state, isUpdating: false
            };
        case types.BIKE_UPDATE_REQUEST:
            return {
                ...state, isUpdating: true
            };
        case types.BIKE_UPDATE_FAIL:
            return {
                ...state, 
                isUpdating: true,
                errors: action.payload.error,
            };
        case types.BIKE_AVAILABLE_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAvailableList: false,
                availableBike: action.payload.bikes,
                availableBikeFrom: action.payload.dateFrom,
                availableBikeTo: action.payload.dateTo,
            };
        case types.BIKE_AVAILABLE_FETCH_FAIL:
            return {
                ...state,
                isFetchingAvailableList: false,
                errors: action.payload.error,
            };
        case types.BIKE_AVAILABLE_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAvailableList: true,
            };
        default:
            return state;
    }
};
