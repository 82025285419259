import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ColorPicker } from 'mui-color';
import { DropzoneAreaBase } from 'react-mui-dropzone';
import { BorderColor } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    formFields: {
        height: '100%',
        width: '100%',
        flexDirection: 'row',

        alignItems: 'flex-start',
        paddingTop: '50px',
    },
    editorWrapper: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(3.25, 4, 4, 4),
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    row2: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: '50px',
    },
    item: {
        height: '190px',
        border: `1px solid ${theme.palette.base[400]}`,
        marginTop: '10px',
        marginLeft: '10px',
        padding: '20px'
    },
    myDropZone: {
        minHeight: '140px',
    },
    attachment: {
        height: 140,
        // width: 720,
    },

}));

function TenantUpdate(props) {
    const classes = useStyles();

    const {
        handleChange,
        updatedTenant,
        setUpdatedTenant,
        error,
        readOnly,
    } = props;

    const { t } = useTranslation();
    const [file, setFile] = useState([]);

    const handleAdd = (newFile) => {
        setFile(newFile);
        setUpdatedTenant({ ...updatedTenant, logoBase64: newFile[0].data.substring('data:image/png;base64,'.length) });
    };

    return (
        <div className={classes.formFields}>
            <form autoComplete='off'>
                <Box mb={2} height="100%" width="100%">
                    <Grid container spacing={2} sm={12}>
                        <Grid container spacing={1} sm={6} sx={12} style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <Grid item xs sm={12} spacing={1} >
                                <TextField
                                    variant="standard"
                                    style={{ height: '80px' }}
                                    autoFocus
                                    helperText={error && error.name
                                        ?
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                        : null}
                                    fullWidth
                                    required
                                    disabled={readOnly}
                                    id="name"
                                    value={updatedTenant.name || ''}
                                    onChange={handleChange('name', false)}
                                    label={t('TENANT_NAME')}
                                />

                            </Grid>
                            <Grid item xs sm={12} spacing={1} >

                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    disabled={false}
                                    style={{ height: '80px' }}
                                    required
                                >
                                    <InputLabel id="parking-label">
                                        {t('TENANT_ACTIVE')}
                                    </InputLabel>
                                    <Select
                                        variant="standard"
                                        autoWidth
                                        disabled={readOnly}
                                        value={updatedTenant.active || ''}
                                        id="active"
                                        onChange={handleChange('active', true)}
                                    >
                                        <MenuItem value=''>-</MenuItem>
                                        <MenuItem value={'true'}>{t('YES')}</MenuItem>
                                        <MenuItem value={'false'}>{t('NO')}</MenuItem>
                                    </Select>
                                    <FormHelperText>  {error && error.active && (
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    )}</FormHelperText>
                                </FormControl>

                            </Grid>
                            <Grid item xs sm={12} spacing={1} >

                                <TextField
                                    variant="standard"
                                    style={{ height: '80px' }}
                                    fullWidth
                                    disabled={readOnly}
                                    id="address"
                                    value={updatedTenant.address || ''}
                                    onChange={handleChange('address', false)}
                                    label={t('ADDRESS')}
                                />
                            </Grid>


                            <Grid item sm={3} spacing={1} >
                                <div style={readOnly ? { color: '#B1B1B1' } : {}}>{t('PRIMARY_COLOR')}</div>
                            </Grid>
                            <Grid item sm={3} spacing={1}>
                                {!readOnly &&
                                    <ColorPicker
                                        name="primaryColor"
                                        value={updatedTenant.primaryColor}
                                        onChange={handleChange('primaryColor')}
                                    />
                                }
                                {readOnly &&
                                    <div style={{ backgroundColor: updatedTenant.primaryColor, width: 20 }}>&nbsp;</div>
                                }
                            </Grid>
                            <Grid item sm={6} spacing={1}></Grid>
                        </Grid>
                        <Grid container spacing={1} sm={6} sx={12} style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <Grid item sm={12} spacing={1} >
                                <FormControl variant="standard" fullWidth disabled={false} style={{ height: '80px' }} required>
                                    <InputLabel id="parking-label">
                                        {t('GRANULARITY')}
                                    </InputLabel>
                                    <Select
                                        variant="standard"
                                        autoWidth
                                        disabled={readOnly}
                                        value={updatedTenant.granularity || ''}
                                        id="granularity"
                                        onChange={handleChange('granularity', true)}>
                                        <MenuItem value=''>-</MenuItem>
                                        <MenuItem value={'TG_DAY'}>{t('TG_DAY')}</MenuItem>
                                        <MenuItem value={'TG_HOUR'}>{t('TG_HOUR')}</MenuItem>
                                    </Select>
                                    <FormHelperText>  {error && error.granularity && (
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    )}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs sm={12} spacing={1} >
                                <TextField
                                    variant="standard"
                                    style={{ height: '80px' }}
                                    fullWidth
                                    disabled={readOnly}
                                    id="telephone"
                                    value={updatedTenant.telephone || ''}
                                    onChange={handleChange('telephone', false)}
                                    label={t('TELEPHONE')}
                                />
                            </Grid>

                            <Grid item xs sm={12} spacing={1} >
                                <TextField
                                    variant="standard"
                                    style={{ height: '80px' }}
                                    fullWidth
                                    disabled={readOnly}
                                    id="email"
                                    value={updatedTenant.email || ''}
                                    onChange={handleChange('email', false)}
                                    label={t('EMAIL')}
                                />
                            </Grid>

                            <Grid item sm={3} spacing={1} >
                                <div style={readOnly ? { color: '#B1B1B1' } : {}}>{t('SECONDARY_COLOR')}</div>
                            </Grid>
                            <Grid item sm={3} spacing={1}>
                                {!readOnly &&
                                    <ColorPicker
                                        name="secondaryColor"
                                        value={updatedTenant.secondaryColor}
                                        onChange={handleChange('secondaryColor')}
                                    />
                                }
                                {readOnly &&
                                    <div style={{ backgroundColor: updatedTenant.secondaryColor, width: 20 }}>&nbsp;</div>
                                }
                            </Grid>
                            <Grid item sm={6} spacing={1}></Grid>
                        </Grid>
                        <Grid container spacing={1} sm={12} style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <Grid item xs sm={12} spacing={1} ></Grid>
                            <Grid item sm={12} spacing={1}>
                                <TextField
                                    variant="standard"
                                    style={{ height: '80px' }}
                                    fullWidth
                                    disabled={readOnly}
                                    id="userCondition"
                                    value={updatedTenant.userCondition || ''}
                                    onChange={handleChange('userCondition', false)}
                                    label={t('USER_CONDITION')}
                                />
                            </Grid>                            
                        </Grid>
                        <Grid container spacing={1} sm={12} style={{ marginLeft: '10px' }}>
                            <div style={readOnly ? { color: '#B1B1B1' } : {}}>{t('LOGO')}</div>
                        </Grid>
                        <Grid container spacing={1} sm={12} className={classes.item} >
                            {!readOnly &&
                                <Grid item sm={6} spacing={1}>
                                    <DropzoneAreaBase
                                        dropzoneClass={classes.myDropZone}
                                        fileObjects={file}
                                        acceptedFiles={['image/*']}
                                        showPreviewsInDropzone={false}
                                        onAdd={handleAdd}
                                        dropzoneText={t('DROP_ZONE_TEXT')}
                                        maxFileSize={10000000}
                                        filesLimit={1}
                                        disabled={readOnly}
                                    />
                                </Grid>
                            }
                            <Grid item sm={readOnly ? 12 : 6} spacing={1}>
                                {updatedTenant && updatedTenant.logoBase64 &&
                                    <img src={'data:image/png;base64,' + updatedTenant.logoBase64}
                                        className={classes.attachment}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </div>
    );
}


TenantUpdate.propTypes = {};
TenantUpdate.defaultProps = {};


const mapStateToProps = (store) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TenantUpdate);