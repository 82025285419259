import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import {ActionButton} from '../../common';
import PDSSnackbar from "../../common/Snackbar";
import PlaceUpdate from "./PlaceUpdate";
import {CircularProgress, Typography} from "@mui/material";
import {fetchStandsByTenantId, openStand} from "../../../redux/actions/stand";
import {fetchBikesByTenantId} from "../../../redux/actions/bike";
import {fetchControlUnits} from "../../../redux/actions/controlUnit";
import { getErrorMessage } from '../../../utils';

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 880,
            minHeight: '60%',
            maxWidth: 1600,
            // boxShadow: theme.palette.shadow.main,
        },
        height: '100%',
    },
    actions: {
        padding: 0,
    },
    title: {
        height: 96,
        background: theme.palette.secondary.main,

        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),

    },
    button: {
        width: 180,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 14,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'left',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
}));


function Place(props) {
    const {
        userUseCases,
        handleClose,
        readOnly,
        savePlace,
        placeConfigModel,
        saving,
        setSaving,
        snackbarOpen,
        setSnackbarOpen,

        fetchStandsByTenantId,
        standsTenantId,
        standsByTenant,
        fetchBikesByTenantId,
        bikesByTenant,
        bikesTenantId,
        fetchControlUnits,
        controlUnits,
        openStand,
        tenants,
    } = props;


    const classes = useStyles();

    const {t} = useTranslation();

    const [error, setError] = useState('');


    const [updatedPlace, setUpdatedPlace] = useState();
    const [errorStep1, setErrorStep1] = useState({});
    const [headerChecked, setHeaderChecked] = useState(1);
    const [isFetchedStands, setIsFetchedStands] = useState(false);
    const [isFetchedBikes, setIsFetchedBikes] = useState(false);
    const [isFetchedControlUnits, setIsFetchedControlUnits] = useState(false);
    
    const getStands = (tenantId) => {
        const tmp = [];
        if ( standsByTenant && tenantId === standsTenantId ) {
            if (placeConfigModel.place.placeConfigs && placeConfigModel.place.placeConfigs.length>0) {
                for ( let i=0; i<standsByTenant.length; i++) {
                    let exist=false;
                    for ( let j=0; j<placeConfigModel.place.placeConfigs.length; j++ ) {
                        if ( placeConfigModel.place.placeConfigs[j].stand.standId === standsByTenant[i].standId ) {
                            // vlozim z placeConfig
                            tmp.push(
                                {
                                    ...placeConfigModel.place.placeConfigs[j].stand, 
                                    customName: placeConfigModel.place.placeConfigs[j].standName, 
                                    bikeId: placeConfigModel.place.placeConfigs[j].bike.bikeId,
                                    checked:1,
                                    placeConfigId: placeConfigModel.place.placeConfigs[j].placeConfigId,
                                }
                            );
                            exist=true;
                            break;
                        } 
                    }
                    if (!exist) {
                        // vlozim prazdny place
                        tmp.push({...standsByTenant[i], customName:standsByTenant[i].name, checked:0});
                    }
                }
            } else {
                for ( let i=0; i<standsByTenant.length; i++) {
                    tmp.push({...standsByTenant[i], customName:standsByTenant[i].name, checked:0});
                }
            }
        }
        return tmp;
    }

    useEffect(() => {
        if (placeConfigModel.place) {
            setUpdatedPlace({...placeConfigModel.place, 
                active:placeConfigModel.place.active,
                stands: getStands(placeConfigModel.place?.tenantId),
                bikes: bikesByTenant,
                controlUnit:placeConfigModel.place.controlUnit?placeConfigModel.place.controlUnit.controlUnitId:0,
                controlUnits:controlUnits,
            });
        }
        setErrorStep1({});
    }, [placeConfigModel]);

    useEffect(() => {
        setUpdatedPlace((_updatedPlace) => { 
            return {..._updatedPlace, 
                stands: getStands(updatedPlace?.tenantId),
            };
        });
    }, [standsByTenant])

    useEffect(() => {
        setUpdatedPlace((_updatedPlace) => { 
            return {..._updatedPlace, 
                bikes: bikesByTenant,
            };
        });
    }, [bikesByTenant])

    useEffect(() => {
        if (updatedPlace?.tenantId && updatedPlace?.tenantId !== standsTenantId) {
            fetchStandsByTenantId(updatedPlace.tenantId)
            .then(()=>{
                setIsFetchedStands(true);
            })
            .catch((err)=>{
                setIsFetchedStands(false);
                console.log(err);
            })
        }
    }, [updatedPlace]);

    useEffect(() => {
        if (updatedPlace?.tenantId && updatedPlace?.tenantId !== bikesTenantId) {
            fetchBikesByTenantId(updatedPlace.tenantId)
            .then(()=>{
                setIsFetchedBikes(true);
            })
            .catch((err)=>{
                setIsFetchedBikes(false);
                console.log(err);
            })
        }
    }, [updatedPlace]);

    useEffect(() => {
        if (!isFetchedControlUnits) {
            fetchControlUnits()
            .then(()=>{
                setIsFetchedControlUnits(true);
            })
            .catch((err)=>{
                setIsFetchedControlUnits(false);
                console.log(err);
            })
        }
    }, [fetchControlUnits, 
        isFetchedControlUnits]);

    const isValid = () => {
        let ret = true;
        const errorStep = {};
        if (!updatedPlace.name || updatedPlace.name.trim().length === 0) {
            errorStep.name = 'required';
            ret = false;
        }
        if (!updatedPlace.active) {
            errorStep.active = 'required';
            ret = false;
        }
        if (!updatedPlace.controlUnit) {
            errorStep.controlUnit = 'required';
            ret = false;
        }
        if (!updatedPlace.tenantId) {
            errorStep.tenantId = 'required';
            ret = false;
        }
        if ( updatedPlace.stands ) {
            if ( !errorStep.stands ) 
                errorStep.stands = [];

            for ( let i=0; i<updatedPlace.stands.length; i++ ) {
                errorStep.stands.push({bikeId:''});
                if ( updatedPlace.stands[i].checked===1 && !updatedPlace.stands[i].bikeId ) {
                    errorStep.stands[i].bikeId = 'required';
                    ret = false;
                }
            }
        }
        console.log('error', errorStep);
        setErrorStep1(errorStep);

        return ret;
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleChange = (name) => (event) => {
        if (event && event.target) {
            setUpdatedPlace({...updatedPlace, [name]: event.target.value});
        } else {
            setUpdatedPlace({...updatedPlace, [name]: event});
        }
    }

    const rowClickHandel = (row) => {
        if ( readOnly ) return;
        if ( updatedPlace && updatedPlace.stands ) {
            let tmp = [];
            for ( let i=0; i<updatedPlace.stands.length; i++) {
                if ( row.standId===updatedPlace.stands[i].standId ) {
                    let newValue = updatedPlace.stands[i].checked===1?0:updatedPlace.stands[i].checked+1;
                    tmp.push({...updatedPlace.stands[i], checked: newValue});
                } else {
                    tmp.push({...updatedPlace.stands[i]});
                }
            }
            setUpdatedPlace({...updatedPlace, stands: tmp});
        }

    }

    const headerClickHandel = () => {
        if ( readOnly ) return;
        let newValue = headerChecked===1?0:headerChecked+1;
        setHeaderChecked(newValue);
        if ( updatedPlace && updatedPlace.stands ) {
            let tmp = [];
            for ( let i=0; i<updatedPlace.stands.length; i++) {
                tmp.push({...updatedPlace.stands[i], checked: newValue});
            }
            setUpdatedPlace({...updatedPlace, stands: tmp});
        }
    }

    const handleOpenKey = (standId, controlUnitId) => {
        openStand(standId, controlUnitId)
            .then(()=>{
            })
            .catch((err)=>{
                setError(getErrorMessage(err, t));
                setSnackbarOpen(true);                
            });
    }

    return (
        <Dialog
            className={classes.container}
            open={placeConfigModel.open}
            onClose={handleClose}
        >
            <DialogTitle className={classnames(classes.title)}>
                <Typography variant="h2">{t(readOnly?'DETAIL_PLACE':(placeConfigModel.isNew ? 'CREATE_PLACE' : 'UPDATE_PLACE'))}</Typography>
            </DialogTitle>

            <PDSSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity={'error'} message={error}/>

            <DialogContent className={classes.content}>
                <div className={classes.container}>
                    <PlaceUpdate 
                        userUseCases={userUseCases}
                        handleChange={handleChange}
                        updatedPlace={updatedPlace}
                        setUpdatedPlace={setUpdatedPlace}
                        error={errorStep1}
                        readOnly={readOnly}
                        rowClickHandel={rowClickHandel}
                        headerClickHandel={headerClickHandel}
                        headerChecked={headerChecked}
                        handleOpenKey={handleOpenKey}
                        tenants={tenants}
                        isNew={placeConfigModel.isNew}
                    />
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigationCancel}>
                    <div className={classes.wrapper}>

                        <ActionButton
                            action="close"
                            handleClick={handleClose}
                            className={classes.button}
                        />
                    </div>

                </div>
                <div className={classes.buttonNavigation}>
                    <div className={classes.wrapper}>
                        {!readOnly &&
                            <>
                                <ActionButton
                                    
                                    loading={saving}
                                    action="save" handleClick={() => {
                                        const ret = isValid();
                                        if (ret) {
                                            setSaving(true);
                                            savePlace(updatedPlace);
                                        }
                                    }}
                                    className={classes.button}
                                    disabled={saving}
                                />
                                {saving && <CircularProgress size={24} className={classes.buttonProgress}/>}
                            </>
                        }
                    </div>
                </div>
            </DialogActions>
        </Dialog>

    );
}

Place.propTypes = {};

const mapStateToProps = (store) => ({
    standsByTenant: store.standData.standsByTenant,
    standsTenantId: store.standData.byTenantId,
    bikesByTenant: store.bikeData.bikesByTenantId,
    bikesTenantId: store.bikeData.byTenantId,
    controlUnits: store.controlUnitData.controlUnits,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchStandsByTenantId,
    fetchBikesByTenantId,
    fetchControlUnits,
    openStand,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Place);


