import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import { makeStyles } from '@mui/styles';
import {getErrorMessage, getCodeList} from '../../../utils';
import ToolBar from './ToolBar';
import PerfectScrollbar from "react-perfect-scrollbar";
import UserList from "./UserList";
import {fetchAllRoles} from "../../../redux/actions/roles";
import {fetchAllUsers, createUser, updateUser, updateUserState} from "../../../redux/actions/users";
import {fetchCodeLists} from "../../../redux/actions/codelists"
import {fetchTenants} from "../../../redux/actions/tenant"
import {Grid} from '@mui/material';
import {ToggleButton} from '@mui/lab';
import TocIcon from '@mui/icons-material/Toc';
import User from '../../../components/modal/UserUpdate';


const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    title: {
        marginBottom: theme.spacing(3.75),
        marginTop: theme.spacing(3.75),
        textTransform: 'uppercase',
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    container: {
        background: theme.palette.base.white,
        marginTop: theme.spacing(4),
    },
}));


function UserAdministration(props) {
    const {
        userUseCases,
        subjects,
        branches,
        roles,
        users,
        parkings,
        createUser,
        updateUser,
        updateUserState,
        fetchAllRoles,
        fetchAllUsers,
        fetchTenants,
        isFetchingAllList,
        user,
        tenants,
        allCodeList,
        fetchCodeLists,
    } = props;


    const classes = useStyles();
    const {t} = useTranslation();

    const [isFetchedRole, setIsFetchedRole] = useState(false);
    const [isFetchedUser, setIsFetchedUser] = useState(false);
    const [isFetchedTenants, setIsFetchedTenants] = useState(false);
    const [isFetchingAllCodeList, setIsFetchingAllCodeList] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [saving, setSaving] = useState(false);
    const [headerChecked, setHeaderChecked] = useState(0);
    const [tableUpdateData, setTableUpdateData] = useState();
    const [userConfigModel, setUserConfigModel] = useState({
        open: false,
        user: {},
        isNew: true
    });

    useEffect(() => {
        if (!isFetchedRole) {
            setIsLoading(true);
            fetchAllRoles().then(()=>{
                setIsFetchedRole(true)         
            }).catch((err) => {
                console.log(err);
                setIsFetchedRole(false);
            });
        }

        if (!isFetchedUser) {
            fetchAllUsers().then(()=>{
                setIsFetchedUser(true)
            }).catch((err) => {
                console.log(err);
                setIsFetchedUser(false);
            });
        }

        if (!isFetchedTenants) {
            fetchTenants().then(()=>{
                setIsFetchedTenants(true)
            }).catch((err) => {
                console.log(err);
                setIsFetchedTenants(false);
            });
        }

        if ( !isFetchingAllCodeList ) {
            fetchCodeLists().then(()=>{
                setIsFetchingAllCodeList(true);
            }).catch((err) => {
                console.log(err);
                setIsFetchingAllCodeList(false);
            });
        }
        setIsLoading(false)

    }, [fetchAllUsers, isFetchedUser, fetchAllRoles, isFetchedRole, fetchCodeLists, isFetchingAllCodeList, fetchTenants, setIsFetchedTenants]);


    const [selectedName, setSelectedName] = useState('');
    const [selectedUCName, setSelectedUCName] = useState('');

    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedAdminRole, setSelectedAdminRole] = useState();

    const [selectedRole, setSelectedRole] = useState();

    const [table, setTable] = useState(false);

    const handleSelectedName = (value) => {
        setSelectedName(value.target.value);
    };
    const handleSelectedUCName = (value) => {
        setSelectedUCName(value.target.value);

    };
    const handleSelectedStatus = (value) => {
        setSelectedStatus(value);
    };
    const handleSelectedAdminRole = (value) => {
        setSelectedAdminRole(value);
    };
    const handleSelectedRole = (value) => {
        setSelectedRole(value);
    };

    const matchStatus = (user) => {
        let res = false;
        for (let i = 0; i < selectedStatus.length; i++) {
            res = ( selectedStatus[i].key === 'ACTIVE' && user.active===true ) || ( selectedStatus[i].key === 'INACTIVE' && user.active===false );
            if (res) {
                return true;
            }
        }
        return false;
    }

    const matchAdmin = (user) => {
        let res = false;
        for (let i = 0; i < selectedAdminRole.length; i++) {
            res = ( selectedAdminRole[i].key === 'YES' && user.admin===true ) || ( selectedAdminRole[i].key === 'NO' && user.admin===false );
            if (res) {
                return true;
            }
        }
        return false;
    }

    const matchRole = (user) => {
        for (let i = 0; i < selectedRole.length; i++) {
            for ( let j = 0; j < user.roles.length; j++) {
                if ( user.roles[j].roleId == selectedRole[i].key )
                    return true;
            }
        }
        return false;
    }

    const matchUC = (user) => {
        for (let i = 0; i < selectedUCName.length; i++) {
            for ( let j = 0; j < user.roles.length; j++) {
                for ( let k = 0; k < user.roles[j].useCases.length; k++) {
                    if ( user.roles[j].useCases[k].code.includes(selectedUCName) || user.roles[j].useCases[k].description.includes(selectedUCName))
                        return true;
                }
            }
        }
        return false;
    }

    useEffect(() => {
        const filUser = [];
        if ( users ) {

            for (let i = 0; i < users.length; i++) {
                let matchCriteria = true;
                if (selectedName && selectedName.trim().length > 0) {
                    if (
                        ( !users[i].firstName || !users[i].firstName.toLowerCase().includes(selectedName.toLowerCase()) )
                        &&
                        ( !users[i].lastName || !users[i].lastName.toLowerCase().includes(selectedName.toLowerCase()) )
                        &&
                        ( !users[i].username || !users[i].username.toLowerCase().includes(selectedName.toLowerCase()) )
                    ) {
                        matchCriteria = false;
                    }
                }

                if (selectedUCName && selectedUCName.trim().length > 0) {
                    const res = matchUC(users[i]);
                    if (!res) {
                        matchCriteria = false;
                    }                   
                }

                if (selectedStatus && selectedStatus.length > 0) {
                    const res = matchStatus(users[i]);
                    if (!res) {
                        matchCriteria = false;
                    }
                }

                if (selectedAdminRole && selectedAdminRole.length > 0) {
                    const res = matchAdmin(users[i]);
                    if (!res) {
                        matchCriteria = false;
                    }
                }

                if (selectedRole && selectedRole.length > 0) {
                    const res = matchRole(users[i]);
                    if (!res) {
                        matchCriteria = false;
                    }
                }

                if (matchCriteria) {
                    filUser.push(users[i]);
                }
            }
        }
        setFilteredRole(filUser);
    }, [users, selectedName, selectedUCName, selectedStatus, selectedAdminRole, selectedRole]);


    const [filteredRole, setFilteredRole] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState('');

    const [rolesValues, setRolesValues] = useState();
    const [codeList, setCodeList] = useState();
    const [confirmModalOpened, setConfirmModalOpened] = useState(false);

    useEffect(() => {
        if ( roles) {
            setRolesValues(roles.map(a => {
                return {value: a.roleId, label: a.name, key: a.roleId}
            }));

            let newData = roles.map((row)=>{
                return {... row, checked:0}
            });
    
            setTableUpdateData(newData);
        }
    }, [roles]);

    const [subjectValues, setSubjectValues] = useState();

    useEffect(() => {
        if ( allCodeList )
            setCodeList(getCodeList(allCodeList, 'DS_ROLE'));
    }, [allCodeList])

    const closeUserDetail = () => {
        setError('');
        setSnackbarOpen(false);
        setUserConfigModel({isNew: false, user: {}, open: false, readOnly: false});
    }

    const handleUserUpdate = (updatedUser, isNew, readOnly) => {
        // parking ID of user

        let user = {...updatedUser}
        if ( user.parkingIds ) {
            let tmp=[];
            for ( let i=0; i<user.parkingIds.split(',').length; i++) {
                for ( let j=0; j<parkings.length; j++) {
                    if ( parkings[j].parkingId==user.parkingIds.split(',')[i] ) {
                        tmp.push(parkings[j]);
                        break;
                    }
                }
            }
            user.parkings=tmp;
        }

        // SIP parking ID
        if ( user.sipParkingIds ) {
            let tmp=[];
            for ( let i=0; i<user.sipParkingIds.split(',').length; i++) {
                for ( let j=0; j<parkings.length; j++) {
                    if ( parkings[j].parkingId==user.sipParkingIds.split(',')[i] ) {
                        tmp.push(parkings[j]);
                        break;
                    }
                }
            }
            user.sipParkings=tmp;
        }

        // admin role
        if ( user.dataSet ) {
            let tmp=[];
            for ( let i=0; i<user.dataSet.split(',').length; i++) {
                for ( let j=0; j<codeList.length; j++) {
                    if ( codeList[j].code==user.dataSet.split(',')[i] ) {
                        tmp.push(codeList[j]);
                        break;
                    }
                }
            }
            user.reportingRoles=tmp;
        }

        // set checked 
        if ( user && user.roles && roles ) {
            let tmp = [];
            
            if ( readOnly ) {
                for ( let j=0; j<user.roles.length; j++) {
                    roles.map((row) => {
                        if ( row.roleId === user.roles[j].roleId ) {
                            let ee = {... row};
                            tmp.push(ee);
                        }
                    });
                }
            } else {
                tmp = roles.map((row) => {
                    return {... row, checked:false};
                })
                for (let i=0; i<tmp.length; i++) {
                    for ( let j=0; j<user.roles.length; j++) {
                        if ( tmp[i].roleId === user.roles[j].roleId ) {
                            tmp[i].checked = 1;
                        }
                    }
                }
            }
            
            setTableUpdateData(tmp);
        } else if (  user && roles ) {
            let tmp = roles.map((row) => {
                return {... row, checked:false};
            })
            setTableUpdateData(tmp);
        }

        user.active=user.active===true?'true':(user.active===false?'false':'');
        user.admin=user.admin===true?'true':(user.admin===false?'false':'');


        setError('');
        setSnackbarOpen(false);
        setUserConfigModel({isNew, user: user, open: true, readOnly: readOnly});
    };
   
    const rowClickHandel = (rowData) => {
        let newData = tableUpdateData.map((row)=>{
            return {... row}
        });

        for (let i=0; i<newData.length; i++) {
            if ( newData[i].name===rowData.name ) {
                if ( !newData[i].checked || newData[i].checked===0)
                    newData[i].checked=1;
                else
                    newData[i].checked=0;
            }
        }

        setTableUpdateData(newData);
    }

    const headerClickHandel = () => {
        let valueChecked=0;
        if ( headerChecked || headerChecked===0 ) {
            if (headerChecked===1) 
                valueChecked=0;
            else 
                valueChecked=1;
        } 

        let newData = tableUpdateData.map((row)=>{
            return {... row}
        });

        for (let i=0; i<newData.length; i++) {
            newData[i].checked=valueChecked;
        }

        setTableUpdateData(newData);
        setHeaderChecked(valueChecked);
    }

    const handleActivate = (user) => {
        updateUserState(user.id, !user.active).then(()=>{
            setConfirmModalOpened(false);
            setIsFetchedUser(false);
        }).catch((err) => {
            console.log(err);
            setIsFetchedUser(false);
        });
    }

    const saveUser = (user) => {

        if ( user && user.active === 'YES') user.active=true;
        if ( user && user.active === 'NO') user.active=false;
        if ( user && user.admin === 'YES') user.admin=true;
        if ( user && user.admin === 'NO') user.admin=false;
        
        if ( tableUpdateData ) {
            user.roles=[];
            
            tableUpdateData.map((item) => {
                if ( item.checked===1 )
                    user.roles.push(item)
            });
        }

        if ( user && user.roles) {
            user.roles.map((item) => {
                item.useCases = [];
            })
        }

        user = {...user}

        const action = (user && user.id ) ? updateUser : createUser;
        action(user).then((prod) => {
            setIsLoading(true);
            setIsFetchedUser(false);
            setSaving(false);
            closeUserDetail();
        }).catch((error) => {
            setIsLoading(false);
            setSaving(false);
            setError(getErrorMessage(error, t));
            setSnackbarOpen(true);
        });
    };


    return (
        <PerfectScrollbar
            options={{
                suppressScrollX: true,
                minScrollbarLength: 50,
            }}
        >

            <div className={classes.container}>

                <ToolBar
                    userUseCases={userUseCases}
                    handleUserUpdate={handleUserUpdate}

                    selectedName={selectedName}
                    handleSelectedName={handleSelectedName}
                    selectedUCName={selectedUCName}
                    handleSelectedUCName={handleSelectedUCName}
                    selectedStatus={selectedStatus}
                    handleSelectedStatus={handleSelectedStatus}
                    selectedAdminRole={selectedAdminRole}
                    handleSelectedAdminRole={handleSelectedAdminRole}
                    selectedRole={selectedRole}
                    handleSelectedRole={handleSelectedRole}

                    roles={rolesValues}
                    subjects={subjectValues}
                    user={user}
                />
                <Grid container>
                    <Grid item sm={11}>
                    </Grid>
                    <Grid item sm={1}>
                        <ToggleButton
                            style={{width: '30px', height: '30px'}}
                            value="check"
                            selected={table}
                            onChange={() => {
                                setTable(!table);
                            }}
                        >
                            <TocIcon/>
                        </ToggleButton>
                    </Grid>
                </Grid>
                <UserList
                    refresh={() => {
                        setIsFetchedUser(false);
                    }}
                    table={table}
                    save={saveUser}
                    userUseCases={userUseCases}
                    role={filteredRole}
                    handleUserDetail={handleUserUpdate}
                    isLoading={isFetchingAllList}
                    setSnackbarOpen={setSnackbarOpen}
                    setSnackbarError={error}
                    handleActivate={handleActivate}
                    confirmModalOpened={confirmModalOpened}
                    setConfirmModalOpened={setConfirmModalOpened}
                />

            </div>


            <User 
                userConfigModel={userConfigModel}
                userUseCases={userUseCases}
                roles={tableUpdateData}
                saveUser={saveUser}
                error={error}
                snackbarOpen={snackbarOpen}
                handleClose={closeUserDetail}
                setSnackbarOpen={setSnackbarOpen}
                setSnackbarError={error}
                subjects={subjects}
                branches={branches}
                hasCertificate={false}
                parkings={parkings}
                codeList={codeList}
                rowClickHandel={rowClickHandel}
                headerClickHandel={headerClickHandel}
                headerChecked={headerChecked}
                saving={saving}
                setSaving={setSaving}
                tenants={tenants}
                loggedUser={user}
            />


        </PerfectScrollbar>
    );
}

const mapStateToProps = (store) => ({
    user: store.authData.user,
    userUseCases: store.authData.userUseCases,
    roles: store.rolesData.roles,
    users: store.usersData.parkingUsers,
    tenants: store.tenantsData.tenants,
    isFetchingAllList: store.usersData.isFetchingParkingList,
    allCodeList: store.codesData.allCodeList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllRoles,
    fetchAllUsers, createUser, updateUser, updateUserState, 
    fetchCodeLists,
    fetchTenants,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserAdministration);
