import { makeStyles } from '@mui/styles';
import Grid from "@mui/material/Grid";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useEffect, useState } from 'react';
import PDSDialog from '../../components/common/PDSDialog';
import { isComponentEnabledForUser, isComponentVisibleForUser } from '../../utils';
import { UC0103, UC0106, UC0136 } from '../../utils/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import logo from '../../img/kolovna_emblem.svg';
import { RESERVATION_HID_STATE_NEW, RESERVATION_ITEM_STATE_RETURNED } from '../../utils/listitem';

const useStyles = makeStyles((theme) => ({
    container: {
        // marginTop: theme.spacing(3.75),
        padding: theme.spacing(1),
        //background: theme.palette.base.white,
    },
    list: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        overflow: 'auto',
        maxHeight: 'calc(100vh - 260px)',
    },
    attachment: {
        maxWidth: '100%',
        padding: '5px',
    },
    returnedBike: {
        maxWidth: 250,
        maxHeight: 350,
        padding: '5px',
        opacity: 0,
        position: 'relative',
        top: 0,
        left: 0,
    },
    returnedBikeContainer: {
        margin: theme.spacing(1),
        borderRadius: 16,
    },
    attachmentContainer: {
        margin: theme.spacing(1),
        borderRadius: 16,
    },
    cardText: {
        textAlign: 'left',
    },
    rightText: {
        textAlign: 'right',
    },
    background: {
    },
    logo: {
        width: 150,
        height: 150,
    },
    arrow: {
        paddingTop: 10,
        marginLeft: 5,
        marginRight: 5,
    },
    bikesList: {
        margin: theme.spacing(2),
        maxHeight: 'calc(100vh - 350px)',
        overflow: 'auto'
    },
    keyButton: {
        //position: 'fixed',
        top: -15,
        left: '-30%',
        borderRadius: 50,
        height: 50,
        width: 50,
        minWidth: 0,
    },
    place: {
        backgroundColor: theme.palette.background.gray,
        borderRadius: 50,
        margin: theme.spacing(2),
        paddingTop: theme.spacing(0.3),
        paddingBottom: theme.spacing(0.3),
    }
}));

const ReservationDetail = ({
    selectedReservation,
    userUseCases,
    setReservationToOpenLock,
    setShowOpenLock,
    setShowReservationItem
}) => {
    const classes = useStyles();
    const { i18n } = useTranslation();

    const formatDate = (date) => {
        var options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
        return date.toLocaleDateString(i18n.language, options);
    }

    const ReservationItemReturnedDetail = ({ item }) => (
        <Grid item xs={3}>
            <Paper elevation={5}
                key={item.placeConfig.placeConfigId}
                className={classes.returnedBikeContainer} 
                onClick={() => setShowReservationItem({item, reservation: selectedReservation})}
                style={{
                    background: `url(data:image/jpeg;base64,${item.returnImgBase64})`,
                    backgroundSize: '100%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}>
                <img src={`data:image/jpeg;base64,${item.returnImgBase64}`}
                    className={classes.returnedBike}
                />

                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Paper elevation={0} className={classes.place}>
                            <Typography variant='subtitle2'>{item.placeConfig.place.name}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper elevation={0} className={classes.place}>
                            <Typography variant='subtitle2'>{item.placeConfig.stand.name}</Typography>
                        </Paper>
                    </Grid>
                </Grid>

            </Paper>
        </Grid>
    );

    const ReservationItemDetail = ({ item }) => (
        <Grid item xs={3}>
            <Paper elevation={5} key={item.placeConfig.placeConfigId} className={classes.attachmentContainer}>
                {isComponentVisibleForUser(UC0136, userUseCases) &&
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (isComponentEnabledForUser(UC0136, userUseCases)) {
                                setReservationToOpenLock(item);
                                setShowOpenLock(true);
                            }
                        }}
                        className={classes.keyButton}
                    >
                        <VpnKeyIcon />
                    </Button>
                }
                <img src={item.placeConfig.bike.img}
                    className={classes.attachment}
                />
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                            <Typography variant='subtitle2'>{item.placeConfig.bike.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper elevation={0} className={classes.place}>
                            <Typography variant='subtitle2'>{item.placeConfig.place.name}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper elevation={0} className={classes.place}>
                            <Typography variant='subtitle2'>{item.placeConfig.stand.name}</Typography>
                        </Paper>
                    </Grid>
                </Grid>

            </Paper>
        </Grid>
    );

    return (
        <Grid container
            spacing={1}
            key={`detail_${selectedReservation.reservationId}`}
        >
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
                <Typography variant='h2' className={classes.cardText}>{selectedReservation.name}</Typography>
            </Grid>
            <Grid item xs={5}>
                <Typography variant='h4' className={classes.rightText}>
                    {formatDate(new Date(selectedReservation.dateFrom))}
                    <ArrowRightAltIcon className={classes.arrow} />
                    {formatDate(new Date(selectedReservation.dateTo))}
                </Typography>
            </Grid>
            <Grid item xs={1}></Grid>

            <Grid item xs={11.5}>
                <Grid container spacing={1} className={classes.bikesList}>
                    {
                        selectedReservation.items.map(item => {
                            if (item.state === RESERVATION_ITEM_STATE_RETURNED) {
                                return <ReservationItemReturnedDetail item={item} />
                            } else {
                                return <ReservationItemDetail item={item} />;
                            }
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ReservationDetail;