import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import {CircularProgress} from "@mui/material";

const getColor = (theme, color) => {
    switch (color) {
        case 'dark':
            return {
                base: theme.palette.base[800],
                hover: '#35353E',
                active: '#000000',
                disabled: '#828282',
            };
        case 'green':
            return {
                base: 'rgb(107, 187, 174)',
                hover: 'rgb(107, 187, 174, 0.75)',
                active: 'rgb(107, 187, 174)',
                disabled: 'rgb(107, 187, 174, 0.5)',
            };
        case 'yellow':
            return {
                base: '#F5894A',
                hover: '#FFAD6E',
                active: '#F57A38',
                disabled: '#FEB0A7',
            };
        case 'red':
            return {
                base: theme.palette.primary.red,
                hover: '#FF5D4B',
                active: '#FC3822',
                disabled: '#FEB0A7',
            };
        case 'grey':
            return {
                base: theme.palette.base[100],
                hover: '#F6F6F6',
                active: '#EBEBEB',
                disabled: '#EEEEEE',
            };
        case 'white':
            return {
                base: theme.palette.base.white,
                hover: '#F6F6F6',
                active: '#FAF8FF',
                disabled: '#EEEEEE',
            };
        case 'ping':
            return {
                base: '#e71c62',
                hover: '#ff0054',
                active: '#e71c62',
                disabled: '#FEB0A7',
            };
        case 'blue':
        default:
            return {
                base: theme.palette.primary.main,
                hover: theme.palette.background.lightBlue,
                active: theme.palette.background.lightestBlue,
                disabled: '#A8C1FF',
            };
    }
};

const useStyles = makeStyles((theme) => ({
    button: {
        minWidth: (props) => props.width,
        height: (props) => props.height,
        backgroundColor: (props) => getColor(theme, props.color).base,
        borderRadius: 2,
        boxShadow: theme.palette.shadow.main,
        color: (props) => (props.color === 'grey' || props.color === 'white' || props.color === 'blue' ? '#252525' : 'white'),

        '&:hover': {
            backgroundColor: (props) => getColor(theme, props.color).hover,
        },

        '&:active': {
            backgroundColor: (props) => getColor(theme, props.color).active,
        },

        '&:disabled': {
            backgroundColor: (props) => getColor(theme, props.color).disabled,
            color: (props) => (props.color === 'grey' ? '#D4D5D9' : 'white'),
        },
    },
    buttonIcon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(0.5),
    },
}));

function CustomButton({
                          className,
                          label,
                          width,
                          height,
                          color,
                          disabled,
                          icon,
                          muiIcon,
                          onClick,
                      }) {
    const classes = useStyles({width, height, color});
    
    return (
        <Button
            className={classnames(classes.button, className)}
            disabled={disabled}
            onClick={onClick}
            startIcon={muiIcon}
        >
            <div className={classes.buttonIcon}>{icon}</div>
            {label}
        </Button>

    );
}

CustomButton.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.array,
    onClick: PropTypes.func,
};

CustomButton.defaultProps = {
    className: '',
    width: 160,
    height: 40,
    color: 'blue',
    disabled: false,
    icon: [],
    onClick: undefined,
};

export default CustomButton;
