import types from '../actionTypes';

const INITIAL_STATE = {
    isFetchingAllList: false,
    isCreating: false,
    isUpdating: false,
    isActivating: false,
    errors: '',
    tenants: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.TENANT_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAllList: false,
                tenants: action.payload.tenants,
            };
        case types.TENANT_FETCH_FAIL:
            return {
                ...state,
                isFetchingAllList: false,
                errors: action.payload.error,
            };
        case types.TENANT_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAllList: true,
            };

        case types.TENANT_CREATE_SUCCESS:
            return {
                ...state, isCreating: false
            };
        case types.TENANT_CREATE_REQUEST:
            return {
                ...state, isCreating: true
            };
        case types.TENANT_CREATE_FAIL:
            return {
                ...state, 
                isCreating: true,
                errors: action.payload.error,
            };

        case types.TENANT_UPDATE_SUCCESS:
            return {
                ...state, isUpdating: false
            };
        case types.TENANT_UPDATE_REQUEST:
            return {
                ...state, isUpdating: true
            };
        case types.TENANT_CREATE_FAIL:
            return {
                ...state, 
                isUpdating: false,
                errors: action.payload.error,
            };

        case types.TENANT_ACTIVE_SUCCESS:
            return {
                ...state, isActivating: false
            };
        case types.TENANT_ACTIVE_REQUEST:
            return {
                ...state, isActivating: true
            };
        case types.TENANT_ACTIVE_FAIL:
            return {
                ...state, 
                isActivating: false,
                errors: action.payload.error,
            };

        default:
            return state;
    }
};
