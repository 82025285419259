import httpService from './http.service';


const getReservations = (dateFrom, dateTo, userId, tenantId, state) => httpService
    .get(`/reservation/webapp?dateFrom=${dateFrom?encodeURIComponent(dateFrom):''}&dateTo=${dateTo?encodeURIComponent(dateTo):''}&userId=${userId?userId:''}&tenantId=${tenantId?tenantId:''}&state=${state?state:''}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


const createReservation = (reservation) => httpService
    .post('/reservation', reservation)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const updateReservation = (reservation) => httpService
    .put('/reservation', reservation)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const deleteReservation = (reservationId) => httpService
    .remove(`/reservation/${reservationId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

export default {
    getReservations,
    createReservation,
    updateReservation,
    deleteReservation,
};
