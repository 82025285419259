import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import { ActionButton } from '../../common';
import classnames from 'classnames';
import CustomizedSteppers from '../../layout/CustomizedSteppers';
import { useEffect, useState } from 'react';
import BasicDateRangePicker from '../../layout/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Grid from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import TextField from "@mui/material/TextField";
import { Loading } from '../../elements';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../utils/constants';
import { E0141 } from '../../../utils/errors';
import PDSSnackbar from '../../common/Snackbar';
import { Typography } from '@mui/material';
import { te } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 930,
            maxWidth: 1400,
            overflow: 'hidden',
            borderRadius: 30
        },
        height: '100%',
    },
    title: {
        height: 200,
        margin: '-1px -1px -1px -1px',
        backgroundImage: 'url(/log_modal.png)',
        backgroundSize: 'cover',
    },
    titleRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '50px'
    },
    actions: {
        padding: 0,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: '50px',
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
        gap: '20px'
    },
    reservationButton: {
        borderRadius: 30,
        fontSize: 16,
        color: 'black',
        height: 36,
        marginLeft: 100
    },
    reservationButton2: {
        borderRadius: 30,
        fontSize: 16,
        color: 'black',
        height: 36,
        width: 160
    },
    content1: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        marginTop: '20px',
        gap: '20px'
    },
    content2: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px'
    },
    content3: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px'
    },
    iconClose: {
        cursor: 'pointer',
        float: 'right',
        marginTop: '-50px',
        width: '20px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 215,
    },
    attachment: {
        height: 120,
        padding: '5px',
    },
    attachmentEmtpy: {
        height: 120,
        padding: '15px',
    },
    attachmentContainer: {
        borderRadius: 16,
        paddingBottom: theme.spacing(1),
    },
    attachmentContainerRowSelected: {
        backgroundColor: theme.palette.primary.main + " !important",
    },
    place: {
        top: '-30px',
        width: 183,
        height: 60,
        color: '#fff',
        background: 'rgb(107, 187, 174)',
        borderRadius: '0px 0px 10px 10px',
        textAlign: 'center',
    },

    place2: {
        backgroundColor: theme.palette.background.gray,
        borderRadius: 50,
        margin: theme.spacing(0.8),
        paddingTop: theme.spacing(0.3),
        paddingBottom: theme.spacing(0.3),
    },

    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    message: {
        fontSize: 13,
    },
    iconBike: {
        marginLeft: '-20px',
        color: '#6BBBAE'
    },
    bikesList: {
        marginTop: '10px',
        maxHeight: '90%',
        overflow: 'auto'
    },
    bikesListContainer: {
        overflow: 'none',
    },
    bikeCard: {
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

const NewReservation = (props) => {
    const {
        openNew,
        handleClose,
        handleSave,
        availableBikes,
        fetchAvailableBikes,
        places,
        codeLists,
        editReservation,
        editValues,
        reservationsErrors,
        user
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const steps = ['Calendar', 'Reservation', 'Confirm'];
    const [activeStep, setActiveStep] = useState(0);
    const [error, setError] = useState('');
    const [errorName, setErrorName] = useState(false);
    const [errorFrom, setErrorFrom] = useState(false);
    const [errorTo, setErrorTo] = useState(false);
    const [errorBike, setErrorBike] = useState(false);
    const [reservationName, setReservationName] = useState('');
    const [bikesFetched, setBikesFetched] = useState(false);
    const [readyToSave, setReadyToSave] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [newDatesValues, setNewDatesValues] = useState(false);

    useEffect(() => {
        if (reservationsErrors.response?.data?.errorCode !== undefined) {
            let args = reservationsErrors.response?.data?.errorParams;
            if (reservationsErrors.response?.data?.errorCode === E0141) {
                args = args.map(e => e.name).join(", ");
            }
            setError(t(reservationsErrors.response?.data?.errorCode, { args: args }));
            setSnackbarOpen(true);
        }
    }, [reservationsErrors]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const [value, setValue] = useState([null, null]);
    const handleChange = (newValue) => {
        setValue(newValue);
        if (editReservation) {
            setNewDatesValues(true);
        }
    }

    const handleNext = () => {
        if (activeStep === 0) {
            if (!value || !value[0] || !value[1] || !reservationName) {
                setErrorName(reservationName ? false : true);
                setErrorFrom(value[0] ? false : true);
                setErrorTo(value[1] ? false : true);
            } else {
                setErrorName(false);
                setErrorFrom(false);
                setErrorTo(false);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else if (activeStep === 1) {
            if (selected) {
                setErrorBike(false);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
                setErrorBike(true);
            }
        }

    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [place, setPlace] = useState('');
    const [bikeType, setBikeType] = useState('');
    const [bikeCategory, setBikeCategory] = useState('');
    const [bikeSize, setBikeSize] = useState('');

    const [offeringBikes, setOfferingBikes] = useState('');
    const [selected, setSelected] = useState(false);

    const handleChangePlace = (event) => {
        setPlace(event.target.value);
    };
    const handleChangeBikeType = (event) => {
        setBikeType(event.target.value);
    };
    const handleChangeBikeCategory = (event) => {
        setBikeCategory(event.target.value);
    }
    const handleChangeBikeSize = (event) => {
        setBikeSize(event.target.value);
    }

    const handleBikeSelect = (placeConfig) => {
        if (activeStep !== 1) return;
        let tmp = [];
        
        if (offeringBikes) {
            offeringBikes.forEach(item => {
                if (item.placeConfigId === placeConfig.placeConfigId) {
                    item.selected = item.selected ? false : true;
                }
                tmp.push({ ...item });
            });
        }

        setSelected(offeringBikes.reduce((acc, bike) => bike.selected || acc, false));
        setOfferingBikes(tmp);
    }

    const handleSaveLocal = () => {
        const dateFrom = value[0].format(DATE_TIME_FORMAT);
        const dateTo = value[1].format(DATE_TIME_FORMAT);
        let tmp = [];

        offeringBikes.forEach(item => {
            if (item.selected) {
                tmp.push(item);
            }
        });

        handleSave({
            // date time zone offset calculation
            dateFrom: dateFrom,
            dateTo: dateTo,
            name: reservationName,
            bikes: tmp,
            reservationId: editValues ? editValues.reservationId : 0
        })
    }

    const hadleClickName = (event) => {
        if (event) {
            setReservationName(event.target.value.substr(0,40));
        }
    }

    const filterBikes = () => {
        if (bikesFetched) {
            let filteredBikes = availableBikes.map((item) => {
                return { ...item };
            })

            if (place) {
                filteredBikes = filteredBikes.filter(item => item.place.placeId === place);
            }
            if (bikeType) {
                filteredBikes = filteredBikes.filter(item => item.bike.type === bikeType);
            }
            if (bikeCategory) {
                filteredBikes = filteredBikes.filter(item => item.bike.category === bikeCategory);
            }
            if (bikeSize) {
                filteredBikes = filteredBikes.filter(item => item.bike.size === bikeSize);
            }

            if (editReservation && !newDatesValues) {
                filteredBikes.forEach(bike => {
                    editValues.myBikes.forEach(item => {
                        if (bike.placeConfigId === item.placeConfigId) {
                            bike.selected = true;
                            setSelected(true);
                        }
                    })
                });
            }

            setOfferingBikes(filteredBikes);
        }
    }

    useEffect(() => {
        filterBikes();
    }, [place, bikeType, bikeCategory, bikeSize, bikesFetched]);

    useEffect(() => {
        if (editReservation)
            setActiveStep(0);

        if (editValues) {
            setValue(editValues.date);
            setReservationName(editValues.name);
        }
    }, [editReservation, editValues]);

    useEffect(() => {
        if (value !== undefined && value !== null &&
            value[0] !== undefined && value[0] !== null &&
            value[1] !== undefined && value[1] !== null) {
            const dateFrom = value[0].format(DATE_TIME_FORMAT);
            const dateTo = value[1].format(DATE_TIME_FORMAT);
            setBikesFetched(false);
            setOfferingBikes([]);
            fetchAvailableBikes(dateFrom, dateTo, editValues ? editValues.reservationId : 0).then(() => {
                setBikesFetched(true);
            });
        }
    }, [value]);

    const emptyMenuItem = () => {
        return (
            <MenuItem value="">
                <em>{t('NONE')}</em>
            </MenuItem>
        );
    }

    const renderMenuItemPlaces = () => {
        if (places) {
            return places.map((place) => (
                <MenuItem value={place.placeId}>{place.name}</MenuItem>
            ));
        }
    }

    const renderMenuItemCodeList = (type) => {
        if (codeLists) {
            for (let i = 0; i < codeLists.length; i++) {
                if (codeLists[i].code === type) {
                    return codeLists[i].items.map((item) => (
                        <MenuItem value={item.code}>{t(item.code)}</MenuItem>
                    ));
                }
            }
        }
    }

    const renderBikes = (onlySelected) => {
        let tmp = [];
        if (offeringBikes && offeringBikes.length > 0) {
            offeringBikes.forEach((item) => {
                if (onlySelected) {
                    if (item.selected) tmp.push(item);
                } else {
                    tmp.push(item);
                }
            });

            return tmp.map((placeConfig) => (
                <Grid item xs={3} className={classes.bikeCard}>
                    <Paper
                        elevation={5} key={placeConfig.placeConfigId}
                        className={`${classes.attachmentContainer} ${placeConfig.selected && !onlySelected && classes.attachmentContainerRowSelected}`}
                        onClick={() => { handleBikeSelect(placeConfig) }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <img src={placeConfig.bike.img}
                                    className={classes.attachment}
                                // alt={showItem(placeConfig.bike)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align='center' variant='subtitle2'>{placeConfig.bike.name}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper elevation={0} className={classes.place2}>
                                    <Typography align='center' variant='subtitle2'>{placeConfig.place.name}</Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper elevation={0} className={classes.place2}>
                                    <Typography align='center' variant='subtitle2'>{placeConfig.stand.name}</Typography>
                                </Paper>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>
            ));
        } else if (offeringBikes && offeringBikes.length === 0) {
            return (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant='h4' align='center'>
                            {t("NO_BIKES")}
                        </Typography>
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <>
                    <Grid container className={classes.center}>
                        <Grid item xs={12}><Loading /></Grid>
                    </Grid>
                    <div className={classes.attachment}>
                        <span className={errorBike ? classes.errorMessage : classes.message}></span>
                    </div>
                </>
            );
        }
    }

    const isDeferentPlace = () => {
        if (offeringBikes && offeringBikes.length > 0) {
            let result = false;
            let lastBike = null;
            for (let i = 0; i < offeringBikes.length; i++) {
                if (offeringBikes[i].selected) {
                    if (lastBike === null) {
                        lastBike = offeringBikes[i];
                    } else {
                        result = offeringBikes[i].place.name !== lastBike.place.name ? true : false;
                        if (result)
                            return true;
                        else
                            lastBike = offeringBikes[i];
                    }
                }
            }
        } else {
            return false;
        }
    }

    const renderFilter = (disabled) => {
        return <>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="placeInput">{t('PLACE')}</InputLabel>
                <Select
                    variant="standard"
                    labelId="placeLabel"
                    id="place"
                    value={place}
                    onChange={handleChangePlace}
                    label="Place"
                    disabled={disabled}>
                    {emptyMenuItem()}
                    {renderMenuItemPlaces()}
                </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="bikeTypeInput">{t('BIKE_TYPE')}</InputLabel>
                <Select
                    variant="standard"
                    labelId="bikeTypeLabel"
                    id="bikeType"
                    value={bikeType}
                    onChange={handleChangeBikeType}
                    label="bikeType"
                    disabled={disabled}>
                    {emptyMenuItem()}
                    {renderMenuItemCodeList('BI_T')}
                </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="bikeCategoryInput">{t('BIKE_CATEGORY')}</InputLabel>
                <Select
                    variant="standard"
                    labelId="bikeCategoryLabel"
                    id="bikeCategory"
                    value={bikeCategory}
                    onChange={handleChangeBikeCategory}
                    label="bikeCategory"
                    disabled={disabled}>
                    {emptyMenuItem()}
                    {renderMenuItemCodeList('BI_C')}
                </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="bikeSize">{t('BIKE_SIZE')}</InputLabel>
                <Select
                    variant="standard"
                    labelId="bikeSizeLabel"
                    id="bikeSize"
                    value={bikeSize}
                    onChange={handleChangeBikeSize}
                    label="bikeSize"
                    disabled={disabled}>
                    {emptyMenuItem()}
                    {renderMenuItemCodeList('BI_S')}
                </Select>
            </FormControl>
        </>;
    }

    return (
        <Dialog
            maxWidth="lg"
            className={classes.container}
            open={openNew || editReservation}
            onClose={handleClose}
        >
            <PDSSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity={'error'} message={error} />
            <DialogTitle className={classnames(classes.title)}>
                <div className={classnames(classes.titleRow)}>
                    {activeStep === 0 &&
                        <Typography variant='h2' style={{ color: 'white', fontWeight: 600 }}>{t('CREATE_RESERVATION')}</Typography>
                    }
                    {activeStep === 1 &&
                        <Typography variant='h2' style={{ color: 'white', fontWeight: 600 }}>{t('BIKES_SELECTION')}</Typography>
                    }
                    {activeStep === 2 &&
                        <Typography variant='h2' style={{ color: 'white', fontWeight: 600 }}>{t('RESERVATION_SUMMARY')}</Typography>
                    }
                    <CustomizedSteppers activeStep={activeStep} steps={steps} />
                </div>
            </DialogTitle>

            <DialogContent>
                {activeStep === 0 &&
                    <div className={classes.content1}>

                        <Grid container
                            spacing={3}
                            direction="row"
                            paddingLeft={4}
                        >
                            <Grid item xs={3}></Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={reservationName}
                                    variant="outlined"
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: 'left' },
                                        },
                                        sx: { borderRadius: 30 }
                                    }}
                                    onChange={hadleClickName}
                                    label={t('RESERVATION_NAME')}
                                    fullWidth
                                    error={errorName}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8}>
                                <BasicDateRangePicker
                                    startText={t('BEGIN')}
                                    endText={t('END')}
                                    value={value}
                                    handleChange={handleChange}
                                    showStatic={true}
                                    requiredFrom={true}
                                    errorFrom={errorFrom}
                                    requiredTo={true}
                                    errorTo={errorTo}
                                    allowHours={true}
                                    disableHistoric={true}
                                />
                            </Grid>
                            <Grid item xs={2}></Grid>
                        </Grid>
                    </div>
                }
                {activeStep === 1 &&
                    <Grid alignItems="center" justifyContent="space-around" container spacing={1} className={classes.bikesListContainer}>
                        <Grid item xs={12}>
                            <div className={classes.content2}>
                                {renderFilter(false)}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3} className={classes.bikesList} >
                                {renderBikes(false)}
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {activeStep === 2 &&
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <div style={{ paddingLeft: '51px', paddingRight: '51px', marginTop: '20px' }}>
                                <TextField
                                    value={reservationName}
                                    variant="outlined"
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: 'left' },
                                        },
                                        sx: { borderRadius: 30 }
                                    }}
                                    onChange={hadleClickName}
                                    label={t('RESERVATION_NAME')}
                                    fullWidth
                                    disabled={true}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.content2}>
                                <BasicDateRangePicker
                                    startText={t('BEGIN')}
                                    endText={t('END')}
                                    value={value}
                                    handleChange={handleChange}
                                    showStatic={false}
                                    requiredFrom={true}
                                    errorFrom={errorFrom}
                                    requiredTo={true}
                                    errorTo={errorTo}
                                    disabled={true}
                                    disableHistoric={true}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}> </Grid>
                        <Grid item xs={12}>
                            {t('USER_CONDITION_TEXT')} <a href={user.tenantCondition} target="_blank">{user.tenantCondition}</a>
                        </Grid>
                        <Grid item xs={12}> </Grid>
                        {isDeferentPlace() &&
                            <div style={{ color: 'red', paddingLeft: '8px' }}>
                                <Grid item xs={12}> {t('SHOW_WARING_PLACE')} </Grid>
                                <Grid item xs={12}> </Grid>
                            </div>
                        }
                        <Grid item xs={12}>
                            {t('BIKE_CHOOSED')}
                            <Grid container spacing={2} className={classes.content3} >
                                {renderBikes(true)}
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </DialogContent>

            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigation}>
                    <ActionButton
                        disabled={activeStep <= 0}
                        action="backGrey"
                        handleClick={handleBack}
                        className={classes.reservationButton}
                    />
                    {activeStep < 2 &&
                        <ActionButton
                            disabled={(activeStep === 1 && !(selected))}
                            action="continue"
                            handleClick={handleNext}
                            className={classes.reservationButton2}
                        />
                    }
                    {activeStep === 2 &&
                        <ActionButton
                            action="save"
                            handleClick={handleSaveLocal}
                            className={classes.reservationButton2}
                        />
                    }
                </div>

            </DialogActions>
        </Dialog>
    );
}

export default NewReservation;