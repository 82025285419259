import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { makeStyles } from '@mui/styles';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PerfectScrollbar from "react-perfect-scrollbar";
import ToolBar from './ToolBar';
import {Grid} from '@mui/material';
import {ToggleButton} from '@mui/lab';
import TocIcon from '@mui/icons-material/Toc';
import TenantList from "./TenantList";
import {fetchTenants, createTenant, updateTenant, activeTenant} from "../../../redux/actions/tenant";
import TenantUpdate from '../../../components/modal/TenantUpdate';
import { getErrorMessage } from '../../../utils';

const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    title: {
        marginBottom: theme.spacing(3.75),
        marginTop: theme.spacing(3.75),
        textTransform: 'uppercase',
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    container: {
        background: theme.palette.base.white,
        marginTop: theme.spacing(4),
    },
}));

function TenantAdministration(props) {
    const {
        userUseCases,
        tenants,
        fetchTenants,
        createTenant,
        updateTenant,
        activeTenant,
    } = props;

    const classes = useStyles();
    const {t} = useTranslation();

    const [table, setTable] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedTenantName, setSelectedTenantName] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState('');
    const [isFetchedTenants, setIsFetchedTenants] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [saving, setSaving] = useState(false);
    const [confirmModalOpened, setConfirmModalOpened] = useState(false);

    useEffect(()=>{
        if ( !isFetchedTenants ) {
            fetchTenants()
                .then(()=>{
                    setIsFetchedTenants(true);
                })
                .catch((err)=>{
                    setIsFetchedTenants(false);
                    console.log(err);
                });
        }
    }, [fetchTenants, isFetchedTenants]);

    useEffect(()=>{
        if ( tenants && tenants.length>0 ) {
            const filTenant = [];
            for (let i = 0; i < tenants.length; i++) {
                let matchCriteria = true;
                if (selectedTenantName && selectedTenantName.trim().length > 0) {
                    if (tenants[i].name) {
                        if (!(tenants[i].name.toLowerCase().includes(selectedTenantName.toLowerCase()))) {
                            matchCriteria = false;
                        }
                    }
                }

                if (selectedStatus && selectedStatus.length > 0) {
                    const res = matchStatus(tenants[i]);
                    if (!res) {
                        matchCriteria = false;
                    }
                }

                if (matchCriteria) {
                    filTenant.push(tenants[i]);
                }
            }
            setFilteredTenants(filTenant);            
        }
    }, [tenants, selectedTenantName, selectedStatus]);

    const [filteredTenants, setFilteredTenants] = useState([]);

    const handleSelectStatus = (value) => {
        setSelectedStatus(value);
    };

    const handleSelectedTenantName = (value) => {
        setSelectedTenantName(value.target.value);
    }

    const [tenantConfigModel, setTenantConfigModel] = useState({
        open: false,
        tenant: {},
        isNew: true
    });
  
    const handleTenantUpdate = (tenant, isNew) => {
        setError('');
        setSnackbarOpen(false);
        setReadOnly(false);

        let newTenant = {...tenant};
        newTenant.active=newTenant.active===true?'true':(newTenant.active===false?'false':'');
        setTenantConfigModel({isNew: isNew, tenant: newTenant, open: true});
    };

    const handleTenantDetail = (tenant, isNew) => {
        setError('');
        setSnackbarOpen(false);
        setReadOnly(true);

        let newTenant = {...tenant};
        newTenant.active=newTenant.active===true?'true':(newTenant.active===false?'false':'');
        setTenantConfigModel({isNew: isNew, tenant: newTenant, open: true});
    };

    const handleClose = () => {
        setError('');
        setSnackbarOpen(false);
        setTenantConfigModel({isNew: false, tenant:{}, open:false});
    }

    const saveTenant = (tenant) => {
        let tmp = {...tenant, 
            primaryColor: tenant.primaryColor&&tenant.primaryColor.hex?'#'+tenant.primaryColor.hex:tenant.primaryColor, 
            secondaryColor: tenant.secondaryColor&&tenant.secondaryColor.hex?'#'+tenant.secondaryColor.hex:tenant.secondaryColor, 
            state: tenant.active==='true'?'A':'I'
        };
        if ( tenant.tenantId ) {
            updateTenant(tmp)
                .then(()=>{
                    setSaving(false);
                    setIsFetchedTenants(false);
                    handleClose();
                })
                .catch((error)=>{
                    console.log('update tenant error', error);
                    setSnackbarOpen(true);
                    setSaving(false);
                    setError(getErrorMessage(error, t));
                });
        } else {
            createTenant(tmp)
                .then(()=>{
                    setSaving(false);
                    setIsFetchedTenants(false);
                    handleClose();
                })
                .catch((error)=>{
                    console.log('create tenant error', error);
                    setSnackbarOpen(true);
                    setSaving(false);
                    setError(getErrorMessage(error, t));
                });
        }
    }

    const handleActivate = (tenant) => {
        activeTenant(tenant.tenantId, tenant.state==='A'?false:true)
            .then(()=>{
                setConfirmModalOpened(false);
                setIsFetchedTenants(false);
            })
            .catch((error)=>{
                console.log('save state tenant error', error);
                setSnackbarOpen(true);
                setSaving(false);
                setError(getErrorMessage(error, t));
            });
}

    const matchStatus = (tenant) => {
        let res = false;
        for (let i = 0; i < selectedStatus.length; i++) {
            res = ( selectedStatus[i].key === 'ACTIVE' && tenant.state==='A' ) || ( selectedStatus[i].key === 'INACTIVE' && tenant.state==='I' );
            if (res) {
                return true;
            }
        }
        return false;
    }

    return (
        <PerfectScrollbar
            options={{
                suppressScrollX: true,
                minScrollbarLength: 50,
            }}
        >
            <div className={classes.container}>
                <ToolBar
                    userUseCases={userUseCases}
                    selectedTenantName={selectedTenantName}
                    handleSelectedTenantName={handleSelectedTenantName}
                    selectedStatus={selectedStatus}
                    handleSelectStatus={handleSelectStatus}
                    handleTenantUpdate={handleTenantUpdate}
                />

                <Grid container>
                    <Grid item sm={11}>
                    </Grid>
                    <Grid item sm={1}>
                        <ToggleButton
                            style={{width: '30px', height: '30px'}}
                            value="check"
                            selected={table}
                            onChange={() => {
                                setTable(!table);
                            }}
                        >
                            <TocIcon/>
                        </ToggleButton>
                    </Grid>
                </Grid>

                <TenantList 
                    userUseCases={userUseCases}
                    tenants={filteredTenants}
                    table={table}
                    loading={isFetchedTenants}
                    handleTenantUpdate={handleTenantUpdate}
                    handleTenantDetail={handleTenantDetail}
                    handleActivate={handleActivate}
                    confirmModalOpened={confirmModalOpened}
                    setConfirmModalOpened={setConfirmModalOpened}
                />

            </div>

            <TenantUpdate 
                userUseCases={userUseCases}
                tenantConfigModel={tenantConfigModel}
                handleClose={handleClose}
                readOnly={readOnly}
                saveTenant={saveTenant}
                saving={saving}
                setSaving={setSaving}
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                error={error}
            />

        </PerfectScrollbar>
    )
}

const mapStateToProps = (store) => ({
    user: store.authData.user,
    userUseCases: store.authData.userUseCases,
    tenants: store.tenantsData.tenants
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchTenants,
    createTenant,
    updateTenant,
    activeTenant,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TenantAdministration);
