import { makeStyles } from '@mui/styles';
import Grid from "@mui/material/Grid";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useEffect, useState } from 'react';
import PDSDialog from '../../components/common/PDSDialog';
import { isComponentEnabledForUser, isComponentVisibleForUser } from '../../utils';
import { UC0103, UC0106, UC0136 } from '../../utils/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import logo from '../../img/kolovna_emblem.svg';
import { RESERVATION_HID_STATE_NEW } from '../../utils/listitem';
import ReservationDetail from './ReservationCard';

const useStyles = makeStyles((theme) => ({
    container: {
        // marginTop: theme.spacing(3.75),
        paddingTop: "0 !important",
        minHeight: 'calc(100vh - 260px)',
        padding: theme.spacing(1),
        //background: theme.palette.base.white,
    },
    list: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        overflow: 'auto',
        maxHeight: 'calc(100vh - 260px)',
    },
    containerDetail: {
        // marginTop: theme.spacing(3.75),
        padding: theme.spacing(1),
        background: theme.palette.base.white,
        zIndex: 2,
        borderRadius: 30,
        margin: theme.spacing(2, 0, 0, 2),
    },
    attachment: {
        maxWidth: '100%',
        padding: '5px',
    },
    reservationRow: {
        // borderBottom: "1px solid #dee2e5",
        margin: theme.spacing(2),
        padding: theme.spacing(1),
        borderRadius: 20,
        backgroundColor: theme.palette.background.lightBlue,
    },
    reservationRowSelected: {
        backgroundColor: theme.palette.primary.main + " !important",
    },
    reservationRowInactive: {
        backgroundColor: theme.palette.background.lightestBlue,
    },
    reservationButton: {
        backgroundColor: theme.palette.background.gray,
        borderRadius: 30,
        fontSize: 16,
        color: 'black',
        height: 36,
    },
    attachmentContainer: {
        margin: theme.spacing(1),
        borderRadius: 16,
    },
    cardText: {
        textAlign: 'left',
    },
    rightText: {
        textAlign: 'right',
    },
    background: {
    },
    containerLogo: {
        position: 'fixed',
        width: 150,
        height: 150,
        top: '50%',
        left: '50%',
        transform: "translate(-50%, -50%)",
        zIndex: 1,
    },
    logo: {
        width: 150,
        height: 150,
    },
    arrow: {
        paddingTop: 10,
        marginLeft: 5,
        marginRight: 5,
    },
    bikesList: {
        margin: theme.spacing(2),
        maxHeight: 'calc(100vh - 350px)',
        overflow: 'auto'
    },
    keyButton: {
        //position: 'fixed',
        top: -15,
        left: '-30%',
        borderRadius: 50,
        height: 50,
        width: 50,
        minWidth: 0,
    },
    place: {
        backgroundColor: theme.palette.background.gray,
        borderRadius: 50,
        margin: theme.spacing(2),
        paddingTop: theme.spacing(0.3),
        paddingBottom: theme.spacing(0.3),
    }
}));

const ReservationList = (props) => {
    const classes = useStyles();
    const { i18n } = useTranslation();

    const formatDate = (date) => {
        var options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
        return date.toLocaleDateString(i18n.language, options);
    }

    const {
        userUseCases,
        reservations,
        handleEdit,
        handleDelete,
        handleOpenKey,
        setShowReservationItem
    } = props;

    const { t } = useTranslation();

    const [showDelete, setShowDelete] = useState(false);
    const [showOpenLock, setShowOpenLock] = useState(false);
    const [reservationToDelete, setReservationToDelete] = useState();
    const [reservationToOpenLock, setReservationToOpenLock] = useState();
    const [selectedReservation, setSelectedReservation] = useState(null);

    const handleEditRow = (reservationId) => () => {
        handleEdit(reservationId);
    }

    const handleDeleteLoc = (reservation) => {
        setShowDelete(false);
        handleDelete(reservation.reservationId);
    }

    const handleOpenLock = (reservationItem) => {
        setShowOpenLock(false);
        handleOpenKey(reservationItem.placeConfig.stand.standId, reservationItem.placeConfig.stand.lockIdentification);
    }

    useEffect(()=>{
        if ( reservations && reservations.length > 0 ) {
            setSelectedReservation(reservations[0]);
        }
            
    }, reservations);

    const isEditable = (item) => item.state === RESERVATION_HID_STATE_NEW && new Date(item.dateFrom).getTime() > new Date().getTime();

    const renderReservationCards = () => {
        return reservations.map((item) => (
            <Grid container
                spacing={1.5}
                key={item.reservationId}
                className={`${classes.reservationRow} 
                    ${item.state !== RESERVATION_HID_STATE_NEW && classes.reservationRowInactive}
                    ${selectedReservation && selectedReservation.reservationId === item.reservationId && classes.reservationRowSelected}`}
                onClick={() => setSelectedReservation(item)}
            >
                <Grid item xs={9}>
                    <Typography variant='h3' className={classes.cardText}>{item.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant='subtitle2' fontStyle='italic' className={classes.cardText}>
                        {t(item.state)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body1' className={classes.cardText}>{item.createBy}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle2' className={classes.cardText}>
                        {formatDate(new Date(item.dateFrom))}
                        <ArrowRightAltIcon className={classes.arrow} />
                        {formatDate(new Date(item.dateTo))}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                </Grid>
    
                <Grid item xs={4}>
                    {isEditable(item) && isComponentVisibleForUser(UC0103, userUseCases) &&
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (isComponentEnabledForUser(UC0103, userUseCases)) {
                                    handleEdit(item.reservationId);
                                }
                            }}
                            className={classes.reservationButton}
                        >
                            {t("EDIT")}
                        </Button>
                    }
                </Grid>
    
    
                <Grid item xs={4}>
                </Grid>
    
                <Grid item xs={2}>
                    {isEditable(item) && isComponentVisibleForUser(UC0106, userUseCases) &&
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (isComponentEnabledForUser(UC0106, userUseCases)) {
                                    setReservationToDelete(item);
                                    setShowDelete(true);
                                }
                            }}
                            className={classes.reservationButton}
                        >
                            <DeleteIcon />
                        </Button>
                    }
                </Grid>
                <Grid item xs={12}>
                </Grid>
            </Grid>
        ));
    }

    console.log("reservationToOpenLock", reservationToOpenLock);
    return (
        <Grid container spacing={1.5} className={classes.background}>
            <Grid item xs={3} className={classes.container}>
                <div className={classes.list}>
                    {reservations && renderReservationCards()}
                </div>
            </Grid>

            {selectedReservation &&
                <Grid item xs={8.5} className={classes.containerDetail}>
                    <ReservationDetail 
                        setReservationToOpenLock={setReservationToOpenLock}
                        setShowOpenLock={setShowOpenLock}
                        userUseCases={userUseCases}
                        selectedReservation={selectedReservation}
                        setShowReservationItem={setShowReservationItem} />
                </Grid>
            }

            <div className={classes.containerLogo}>
                <img src={logo} className={classes.logo} />
            </div>

            {showDelete &&
                <PDSDialog
                    open={showDelete}
                    title={t('DELETE_CONFIRMATION_TITLE')}
                    onClose={() => {
                        setShowDelete(false)
                        setReservationToDelete(null);
                    }}
                    size="sm"
                    headerColor="white"
                    height={300}
                    onSubmit={() => { handleDeleteLoc(reservationToDelete); }}
                    actionType={'delete'}
                >
                    <div className={classes.content}>
                        {t('DELETE_CONFIRMATION_MESSAGE', { reservationName: reservationToDelete.name })}
                    </div>
                </PDSDialog>
            }

            {showOpenLock &&
                <PDSDialog
                    open={showOpenLock}
                    title={t('OPEN_KEY_CONFIRMATION_TITLE')}
                    onClose={() => {
                        setShowOpenLock(false)
                        setReservationToOpenLock(null);
                    }}
                    size="sm"
                    headerColor="white"
                    height={300}
                    onSubmit={() => { handleOpenLock(reservationToOpenLock); }}
                    actionType={'open'}
                >
                    <div className={classes.content}>
                        {t('OPEN_KEY_CONFIRMATION_MESSAGE', { standName: reservationToOpenLock.placeConfig.stand.name })}
                    </div>
                </PDSDialog>
            }
        </Grid>
    );
}

export default ReservationList;