

export const BUSINESS_TERRITORY_STATE_VALID = "VALID";
export const BUSINESS_TERRITORY_STATE_INVALID = "INVALID";


export const MOBILE_DEVICE_STATE_INIT = "MD_I";
export const MOBILE_DEVICE_STATE_APPROVED = "MD_A";
export const MOBILE_DEVICE_STATE_CANCEL = "MD_C";

export const INVITATION_STATE_PENDING = "PENDING";
export const INVITATION_STATE_ACKNOWLEDGED = "ACKNOWLEDGED";


export const RESERVATION_HID_STATE_NEW = "NEW";
export const RESERVATION_HID_STATE_SENT = "SENT";
export const RESERVATION_HID_STATE_ENDED = "ENDED";
export const RESERVATION_HID_STATE_CANCELED = "CANCELED";


export const RESERVATION_ITEM_STATE_NEW = "NEW";
export const RESERVATION_ITEM_STATE_USED = "USED";
export const RESERVATION_ITEM_STATE_RETURNED = "RETURNED";

export const ENTITY_STATE_ACTIVE = "A";
export const ENTITY_STATE_INACTIVE = "I";

export const TENANT_GRANULARITY_DAY = "TG_DAY";
export const TENANT_GRANULARITY_HOUR = "TG_HOUR";
