import types from '../actionTypes';
import mobiledeviceService from '../../services/mobiledevice.service';


export const updateState = (id, active) => (dispatch, getState) => {
    if (getState().mobiledeviceData.isUpdating) {
        return;
    }

    dispatch({
        type: types.MOBILEDEVICE_UPDATE_REQUEST,
    });

    return mobiledeviceService.setActive(id, active)
        .then(() => {
            dispatch({
                type: types.MOBILEDEVICE_UPDATE_SUCCESS,
                payload: {},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.MOBILEDEVICE_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const deleteDevice = (id) => (dispatch, getState) => {
    if (getState().mobiledeviceData.isUpdating) {
        return;
    }

    dispatch({
        type: types.MOBILEDEVICE_DELETE_REQUEST,
    });

    return mobiledeviceService.deleteDevice(id)
        .then(() => {
            dispatch({
                type: types.MOBILEDEVICE_DELETE_SUCCESS,
                payload: {},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.MOBILEDEVICE_DELETE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchDevices = () => (dispatch, getState) => {
    if (getState().mobiledeviceData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.MOBILEDEVICE_FETCH_REQUEST,
    });

    return mobiledeviceService.getDevices()
        .then((mobiledevices) => {
            dispatch({
                type: types.MOBILEDEVICE_FETCH_SUCCESS,
                payload: { mobiledevices },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.MOBILEDEVICE_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};
