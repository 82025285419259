import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import Grid from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import { Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 970,
            maxWidth: 1400,
            overflow: 'hidden',
            borderRadius: 30
        },
        height: '100%',
    },
    title: {
        height: 200,
        margin: '-1px -1px -1px -1px',
        backgroundImage: 'url(/log_modal.png)',
        backgroundSize: 'cover',
    },
    titleRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '50px'
    },
    actions: {
        padding: 0,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: '50px',
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
        gap: '20px'
    },
    reservationButton: {
        borderRadius: 30,
        fontSize: 16,
        color: 'black',
        height: 36,
        marginLeft: 100
    },
    reservationButton2: {
        borderRadius: 30,
        fontSize: 16,
        color: 'black',
        height: 36,
        width: 160
    },
    content1: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        marginTop: '20px',
        gap: '20px'
    },
    content2: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px'
    },
    content3: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px'
    },
    iconClose: {
        cursor: 'pointer',
        float: 'right',
        marginTop: '-50px',
        width: '20px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 215,
    },
    attachment: {
        maxHeight: '100%',
        maxWidth: '100%',
        padding: theme.spacing(4),

    },
    attachmentEmtpy: {
        height: 120,
        padding: '15px',
    },
    attachmentContainer: {
        borderRadius: 16,
        paddingBottom: theme.spacing(1),
    },
    mint: {
        color: theme.palette.primary.main,
    },
    attachmentContainerRowSelected: {
        backgroundColor: theme.palette.primary.main + " !important",
    },
    place: {
        backgroundColor: theme.palette.background.gray,
        borderRadius: 50,
        marginRight: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
        textAlign: 'center',
    },
    place2: {
        backgroundColor: theme.palette.background.gray,
        borderRadius: 50,
        margin: theme.spacing(0.8),
        paddingTop: theme.spacing(0.3),
        paddingBottom: theme.spacing(0.3),
    },

    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    message: {
        fontSize: 13,
    },
    iconBike: {
        marginLeft: '-20px',
        color: '#6BBBAE'
    },
    bikesList: {
        marginTop: '10px',
        maxHeight: '90%',
        overflow: 'auto'
    },
    bikesListContainer: {
        overflow: 'none',
    },
    bikeCard: {
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
    }
}));

const ReturnedBike = (props) => {
    const {
        open,
        handleClose,
        reservationItem,
        reservation,
    } = props;

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const formatDate = (date) => {
        var options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return date.toLocaleDateString(i18n.language, options);
    }

    return (
        <Dialog
            maxWidth="lg"
            className={classes.container}
            open={open}
            onClose={handleClose}
        >
            <DialogContent>
                <IconButton onClick={handleClose} className={classes.closeButton} aria-label="delete">
                    <CloseIcon />
                </IconButton>
                <Grid container
                    spacing={3}
                    direction="row"
                    paddingLeft={4}
                >
                    <Grid item xs={7}>
                        <Grid container
                            rowSpacing={5}
                            columnSpacing={0}
                            direction="row"
                        >
                            <Grid item xs={12}>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h2'>{reservationItem.placeConfig.bike.name}</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant='h4' className={classes.mint}>{t("LENDED")}:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant='h4'>{formatDate(new Date(reservation.dateFrom))}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Paper elevation={0} className={classes.place}>
                                    <Typography variant='subtitle2'>{reservationItem.placeConfig.place.name}</Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={2}>
                                <Paper elevation={0} className={classes.place}>
                                    <Typography variant='subtitle2'>{reservationItem.placeConfig.stand.name}</Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant='h4' className={classes.mint}>{t("RETURNED")}:</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='h4'>{formatDate(new Date(reservationItem.returnedDate))}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container
                            spacing={1}
                            direction="row"
                        >
                            <Grid item xs={12}>
                                <img src={`data:image/jpeg;base64,${reservationItem.returnImgBase64}`}
                                    className={classes.attachment}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default ReturnedBike;