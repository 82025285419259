import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@mui/x-date-pickers';
import { getLocale, getLocaleDateTimeShortString, getLocaleDateTimeString } from "../../i18n";
import { getAMPM } from '../../i18n';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/de';
import 'dayjs/locale/cs';
import 'dayjs/locale/ru';
import 'dayjs/locale/ro';
import 'dayjs/locale/pl';




dayjs.extend(isBetweenPlugin);

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...((isFirstDay || dayIsBetween || isLastDay) && {
    borderRadius: 0,
    backgroundColor: '#6BCBAE',
    color: theme.palette.common.white,
    '&:hover, &:focus, &:selected': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    backgroundColor: '#6BBBAE',
    '&.Mui-selected, &:hover.Mui-selected, &:focus.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    backgroundColor: '#6BBBAE',
    '&.Mui-selected, &:hover.Mui-selected, &:focus.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  }),
}));

const CustomDateTimePicker = styled(DateTimePicker)(({ theme }) => ({
  'MuiPickersDay-today': {
    backgroundColor: '#6BCBAE',
    color: theme.palette.common.white,
    '&:hover, &:focus, &:selected': {
      backgroundColor: '#6BBBAE',
    }
  },
  'MuiPickersDay-root Mui-selected': {
    backgroundColor: '#6BCBAE',
    color: theme.palette.common.white,
    '&:hover, &:focus, &:selected': {
      backgroundColor: '#6BBBAE',
    }
  },
  '& > .MuiInputBase-root': {
    borderRadius: 30,
  }
}));

const CenteredGrid = styled(Grid)(({ theme }) => ({
  "text-align": "center"
}));

const Day = (props) => {
  const { day, selectedRange, ...other } = props;
  const [start, end] = selectedRange;

  if (start === null) {
    return <PickersDay day={day} {...other} />;
  }

  const dayIsBetween = end !== null && day.isBetween(start, end, null, '[]');
  const isFirstDay = day.isSame(start, 'day');
  const isLastDay = end !== null && day.isSame(end, 'day');

  return (
    <CustomPickersDay
      {...other}
      day={day}
      disableMargin
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
    />
  );
}

Day.propTypes = {
  /**
   * The date to show.
   */
  day: PropTypes.object.isRequired,
  selectedRange: PropTypes.object,
};

const BasicDateRangePicker = (props) => {
  const {
    startText,
    endText,
    value,
    handleChange,
    showStatic,
    requiredFrom,
    errorFrom,
    requiredTo,
    errorTo,
    disabled,
    allowHours,
    disableHistoric = false,
  } = props;

  const { t, i18n } = useTranslation();
  const nextFullHour = dayjs().add(1, "hour").minute(0).second(0).millisecond(0);

  const handleChangePicker = (i, newValue, disableHistoric) => {
    if (disableHistoric && nextFullHour.isAfter(newValue)) {
      newValue = nextFullHour;
    }

    if (i === 0) {
      if (value[1] !== null && newValue.isAfter(value[1])) {
        handleChange([newValue, newValue]);
      } else {
        handleChange([newValue, value[1]]);
      }
    } else  {
      if (value[0] !== null && newValue.isBefore(value[0])) {
        handleChange([newValue, newValue]);
      } else {
        handleChange([value[0], newValue]);
      }
    }
  };

  const handleChangeInner = (newValue) => {
    if (nextFullHour.isAfter(newValue)) {
      newValue = nextFullHour;
    }
    
    if (value[0] === null) {
      handleChange([newValue, null]);
    } else if (value[1] === null) {
      if (newValue.isBefore(value[0])) {
        handleChange([newValue, value[0]]);
      } else {
        handleChange([value[0], newValue]);
      }
    } else {
      handleChange([newValue, null]);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
      <Grid container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <CenteredGrid item xs={5}>
          <CustomDateTimePicker
            label={startText}
            value={value[0]}
            onChange={(newValue) => handleChangePicker(0, newValue, disableHistoric)}
            format={getLocaleDateTimeShortString(i18n.language)}
            disabled={!!disabled}
            views={allowHours ? ['day', 'hours'] : ['day']}
            slotProps={{ textField: { required: requiredFrom, error: !!errorFrom, helperText: errorFrom } }}
            minDateTime={disableHistoric ? nextFullHour : undefined}
            ampm={getAMPM(i18n.language)}
            dayOfWeekFormatter={(day) => `${day}.`}
          />
        </CenteredGrid>
        <CenteredGrid item xs={2}>
          {t('TO')}
        </CenteredGrid>
        <CenteredGrid item xs={5}>
          <CustomDateTimePicker
            label={endText}
            value={value[1]}
            onChange={(newValue) => handleChangePicker(1, newValue, disableHistoric)}
            format={getLocaleDateTimeShortString(i18n.language)}
            disabled={!!disabled}
            views={allowHours ? ['day', 'hours'] : ['day']}
            slotProps={{ textField: { required: requiredTo, error: !!errorTo, helperText: errorTo } }}
            minDateTime={value[0]}
            ampm={getAMPM(i18n.language)}
            dayOfWeekFormatter={(day) => `${day}.`}
          />
        </CenteredGrid>
        {showStatic &&
          <>
            <Grid item xs={6}>
              <DateCalendar
                value={value[0]}
                onChange={(newValue) => handleChangeInner(newValue)}
                slots={{ day: Day }}
                slotProps={{
                  day: {
                    selectedRange: value,
                  },
                }}
                disabled={!!disabled}
                defaultCalendarMonth={dayjs()}
                minDate={disableHistoric ? nextFullHour : undefined}
              />
            </Grid>
            <Grid item xs={6}>
              <DateCalendar
                value={value[1]}
                onChange={(newValue) => handleChangeInner(newValue)}
                slots={{ day: Day }}
                slotProps={{
                  day: {
                    selectedRange: value,
                  },
                }}
                disabled={!!disabled}
                defaultCalendarMonth={dayjs().add(1, 'month')}
                minDate={disableHistoric ? nextFullHour : undefined}
              />
            </Grid>
          </>}
      </Grid>
    </LocalizationProvider>
  );
}

export default BasicDateRangePicker;
