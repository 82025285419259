import React, { useEffect } from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import { makeStyles } from '@mui/styles';
import BasicDateRangePicker from '../../components/layout/DatePicker';
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import DownloadIcon from '@mui/icons-material/Download';
import Add from "@mui/icons-material/Add";
import { isComponentEnabledForUser, isComponentVisibleForUser } from '../../utils';
import { UC0102, UC0108 } from '../../utils/constants';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { UC0107 } from '../../utils/constants';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        width: '100%',
        padding: theme.spacing(2, 1, 1, 2),
        backgroundColor: theme.palette.background.paper,
    },
    addButtonRow: {
        paddingLeft: '10px',
        backgroundColor: 'rgb(107, 187, 174)',
    },
    addButton: {
        backgroundColor: 'rgb(107, 187, 174)',
    },
}));

const ToolBar = (props) => {
    const {
        user,
        users,
        userUseCases,
        handleFilter,
        hadleNewReservation,
        tenants,
        getReservationsData,
    } = props;

    const classes = useStyles();
    const {t} = useTranslation();

    const reservationStatuses = [
        {
            value: 'NEW',
            key: 'NEW'
        },
        {
            value: 'ENDED',
            key: 'ENDED'
        },
        {
            value: 'CANCELED',
            key: 'CANCELED'
        },
        {
            value: 'UNUSED',
            key: 'UNUSED'
        },
    ]

    const [filterValue, setFilterValue] = useState({});
    const [value, setValue] = useState([dayjs().subtract(1, 'month').hour(0).minute(0).millisecond(0), null]);
    const [selectedUser, setSelectedUser] = useState('');
    const [usersView, setUsersView] = useState([]);

    const [selectedReservationStatus, setReservationSelectedStatus] = useState(reservationStatuses[0]);

    const [tenantsView, setTentantsView] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState('');

    const handleChange = (newValue) => {
        setValue(newValue);
        setFilterValue({...filterValue, dates: newValue});
        handleFilter({...filterValue, dates: newValue});
    }

    const handleSelectUser = (newValue) => {
        setSelectedUser(newValue);
        setFilterValue({...filterValue, user: newValue});
        handleFilter({...filterValue, user: newValue});
    }

    const handleSelectTenants = (newValue) => {
        setSelectedTenant(newValue);
        setFilterValue({...filterValue, tenant: newValue});
        handleFilter({...filterValue, tenant: newValue});
    }

    const handleSelectReservationStatus = (newValue) => {
        setReservationSelectedStatus(newValue);
        setFilterValue({...filterValue, reservationStatus: newValue});
        handleFilter({...filterValue, reservationStatus: newValue});
    }

    useEffect(()=>{
        let tmpValue = {...filterValue};
        if ( tenants && user ) {
            let tmp = [];
            for ( let i=0; i<tenants.length; i++) {
                tmp.push({
                    value: tenants[i].tenantId,
                    label: tenants[i].name,
                    key: tenants[i].tenantId
                });
                if ( user.tenantId===tenants[i].tenantId ) {
                    setSelectedTenant({
                        value: tenants[i].tenantId,
                        label: tenants[i].name,
                        key: tenants[i].tenantId
                    });
                    tmpValue.tenant = {
                        value: tenants[i].tenantId,
                        label: tenants[i].name,
                        key: tenants[i].tenantId
                    };
                }
            }
            setTentantsView(tmp);
        }
        if ( users && user ) {
            let tmp = [];
            for ( let i=0; i<users.length; i++) {
                let showName = ( users[i].firstName ? users[i].firstName + ' ' : '' ) + ( users[i].lastName ? users[i].lastName : '' );
                tmp.push({
                    value: users[i].id,
                    label: showName,
                    key: users[i].id
                });
                if ( user.id===users[i].id ) {
                    setSelectedUser({
                        value: users[i].id,
                        label: showName,
                        key: users[i].id
                    });
                    tmpValue.user = {
                        value: users[i].id,
                        label: showName,
                        key: users[i].id
                    };
                }
            }
            setUsersView(tmp);
        }
        setFilterValue(tmpValue);
    }, [tenants, user, users]);

    return (
        <div classes={classes.toolbar}>
            <Grid container columnSpacing={3} rowSpacing={0} style={{height: '100%', width: '100%', paddingTop: '16px', paddingBottom: '16px', paddingLeft: '24px'}}>
                <Grid item sm={4}>
                    <BasicDateRangePicker
                        startText={t('BEGIN')}
                        endText={t('END')}
                        value={value}
                        handleChange={handleChange}
                        disableHistoric={false}
                    />
                </Grid>
                <Grid item sm={2}>
                    <Autocomplete
                        disabled={user!==undefined && user!==null && user.admin!==null ? !user.admin : false}
                        value={selectedTenant}
                        onChange={(event, newValue) => {
                            handleSelectTenants(newValue);
                        }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        filterSelectedOptions
                        id="tenants"
                        options={tenantsView}
                        getOptionLabel={(option) => {
                            return option.label?option.label:'';
                        }}
                        style={{width: '100%'}}
                        renderInput={(params) => (
                            <TextField variant="standard" {...params} label={t('TENANT')} fullWidth />
                        )}
                        renderTags={(value, getTagProps) => (
                            value.map((option, index) => {
                                    return <Chip
                                        style={{
                                            backgroundColor: option.color,
                                            color: 'black'
                                        }}
                                        variant="outlined"
                                        label={option.label}
                                        {...getTagProps({index})}
                                    />
                                }
                            )
                        )
                        }
                    />                    
                </Grid>
                <Grid item sm={2}>
                    <Autocomplete
                        disabled={!isComponentEnabledForUser(UC0108, userUseCases)}
                        value={selectedUser}
                        onChange={(event, newValue) => {
                            handleSelectUser(newValue);
                        }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        filterSelectedOptions
                        id="users"
                        options={usersView}
                        getOptionLabel={(option) => {
                            return option.label ? option.label : '';
                        }}
                        style={{width: '100%'}}
                        renderInput={(params) => (
                            <TextField variant="standard" {...params} label={t('USER')} fullWidth />
                        )}
                        renderTags={(value, getTagProps) => (
                            value.map((option, index) => {
                                    return <Chip
                                        style={{
                                            backgroundColor: option.color,
                                            color: 'black'
                                        }}
                                        variant="outlined"
                                        label={option.label}
                                        {...getTagProps({index})}
                                    />
                                }
                            )
                        )
                        }
                    />
                </Grid>
                <Grid item sm={2}>
                    <Autocomplete
                        value={selectedReservationStatus}
                        onChange={(event, newValue) => {
                            handleSelectReservationStatus(newValue);
                        }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        filterSelectedOptions
                        id="reservationStatuses"
                        options={reservationStatuses}
                        getOptionLabel={(option) => {
                            return t(option.key);
                        }}
                        style={{width: '100%'}}
                        renderInput={(params) => (
                            <TextField variant="standard" {...params} label={t('RESERVATION_STATE')} fullWidth />
                        )}
                        renderTags={(value, getTagProps) => (
                            value.map((option, index) => {
                                    return <Chip
                                        style={{
                                            backgroundColor: option.color,
                                            color: 'black'
                                        }}
                                        variant="outlined"
                                        label={option.label}
                                        {...getTagProps({index})}
                                    />
                                }
                            )
                        )
                        }
                    />
                </Grid>
                <Grid item sm={1}>
                </Grid>
                <Grid item sm={1} style={{display: 'flex', flexDirection: 'row', columnGap: '30px'}}>
                    { isComponentVisibleForUser(UC0107, userUseCases) &&
                        <div classes={classes.addButtonRow}>
                            <CSVLink 
                                data={getReservationsData()}
                                separator={';'}
                                filename={`reservation_${new Date().toISOString().slice(0,10)}.csv`}
                                enclosingCharacter={`"`}
                            >   
                                <IconButton
                                    className={classes.addButton}
                                    edge="start"
                                    color="black"
                                    aria-label="add reservation"
                                    size="large"
                                    disabled={!isComponentEnabledForUser(UC0107, userUseCases)}>
                                        <DownloadIcon />
                                </IconButton>
                            </CSVLink>
                        </div>
                    }
                    { isComponentVisibleForUser(UC0102, userUseCases) &&
                        <div classes={classes.addButtonRow}>
                        <IconButton
                            className={classes.addButton}
                            edge="start"
                            color="inherit"
                            aria-label="add reservation"
                            onClick={hadleNewReservation}
                            size="large"
                            disabled={!isComponentEnabledForUser(UC0102, userUseCases)}>
                            <Add className={classes.addIcon}/>
                        </IconButton>
                        </div>
                    }
                </Grid>
            </Grid>
        </div>
    );
}

export default ToolBar;