import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { DropzoneAreaBase } from 'react-mui-dropzone';
import { ArrowUpward, ChevronRight } from "@mui/icons-material";
import MaterialTable from '@material-table/core';
import CustomCheckBox from '../../elements/CustomCheckBox2';
import Search from '@mui/icons-material/Search';
import Clear from '@mui/icons-material/Clear';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PDSDialog from '../../common/PDSDialog';
import { isComponentEnabledForUser, isComponentVisibleForUser } from '../../../utils';
import { UC0136 } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
    formFields: {
        height: '100%',
        width: '100%',
        flexDirection: 'row',

        alignItems: 'flex-start',
        paddingTop: '50px',
    },
    editorWrapper: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(3.25, 4, 4, 4),
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    row2: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: '50px',
    },
    item: {
        height: '190px',
        border: `1px solid ${theme.palette.base[400]}`,
        marginTop: '10px',
        // marginLeft: '10px',
        padding: '20px'
    },
    myDropZone: {
        minHeight: '140px',
    },
    attachment: {
        height: 140,
        // width: 720,
    },

}));

function PlaceUpdate(props) {
    const classes = useStyles();

    const {
        userUseCases,
        handleChange,
        updatedPlace,
        setUpdatedPlace,
        error,
        readOnly,

        rowClickHandel,
        headerClickHandel,
        headerChecked,
        handleOpenKey,
        tenants,
        isNew,
        user,
        isFetchingStandListByTenant,
        isFetchingBikeListByTenant,
    } = props;

    const { t } = useTranslation();
    const [file, setFile] = useState([]);

    const [showOpenLock, setShowOpenLock] = useState(false);
    const [reservationToOpenLock, setReservationToOpenLock] = useState();


    const handleAdd = (newFile) => {
        setFile(newFile);
        setUpdatedPlace({ ...updatedPlace, imgBase64: newFile[0].data.substring('data:image/png;base64,'.length) });
    };

    const handleOpenLock = (placeConfigItem) => {
        setShowOpenLock(false);
        handleOpenKey(placeConfigItem.standId, updatedPlace.controlUnit);
    }

    const handleRowChange = (rowData, type) => (event) => {
        let tmp = [];
        for (let i = 0; i < updatedPlace.stands.length; i++) {
            if (updatedPlace.stands[i].standId !== rowData.standId)
                tmp.push(updatedPlace.stands[i]);
            else
                tmp.push({ ...rowData, [type]: event.target.value });
        }
        setUpdatedPlace({ ...updatedPlace, stands: tmp });
    }

    return (
        <>
            <div className={classes.formFields}>
                <form autoComplete='off'>
                    <Box mb={2} height="100%" width="100%">
                        <Grid container spacing={2} sm={12}>
                            <Grid container spacing={1} sm={4} sx={12} style={{ marginLeft: "auto", marginRight: "auto" }}>
                                <Grid item xs sm={12} spacing={1} >
                                    <TextField
                                        variant="standard"
                                        style={{ height: '80px' }}
                                        autoFocus
                                        helperText={error && error.name
                                            ?
                                            <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                            : null}
                                        fullWidth
                                        required
                                        disabled={readOnly}
                                        id="name"
                                        value={updatedPlace.name || ''}
                                        onChange={handleChange('name', false)}
                                        label={t('PLACE_NAME')}
                                    />

                                    <TextField
                                        variant="standard"
                                        style={{ height: '80px' }}
                                        autoFocus
                                        fullWidth
                                        disabled={readOnly}
                                        id="address"
                                        value={updatedPlace.address || ''}
                                        onChange={handleChange('address', false)}
                                        label={t('ADDRESS')}
                                    />

                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        disabled={false}
                                        style={{ height: '80px' }}
                                        required
                                    >
                                        <InputLabel id="placeActive-label">
                                            {t('PLACE_ACTIVE')}
                                        </InputLabel>
                                        <Select
                                            variant="standard"
                                            autoWidth
                                            disabled={readOnly}
                                            value={updatedPlace.active || ''}
                                            id="active"
                                            onChange={handleChange('active', true)}
                                        >
                                            <MenuItem value=''>-</MenuItem>
                                            <MenuItem value={'true'}>{t('YES')}</MenuItem>
                                            <MenuItem value={'false'}>{t('NO')}</MenuItem>
                                        </Select>
                                        <FormHelperText>  {error && error.active && (
                                            <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                        )}</FormHelperText>
                                    </FormControl>


                                    <FormControl
                                        variant="standard"
                                        required
                                        fullWidth
                                        disabled={false}
                                        style={{ height: '60px' }}>
                                        <InputLabel id="tenant-label">
                                            {t('TENANT')}
                                        </InputLabel>
                                        <Select
                                            variant="standard"
                                            autoWidth
                                            disabled={!user.admin || readOnly}
                                            value={updatedPlace.tenantId || 
                                                (isNew && !user.admin && user.tenantId) || 
                                                ''}
                                            id="tenantId"
                                            onChange={handleChange('tenantId', updatedPlace.tenantId)}>
                                            <MenuItem value=''>-</MenuItem>
                                            {tenants.map(item => (
                                                <MenuItem value={item.tenantId}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>  {error && error.active && (
                                            <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                        )}</FormHelperText>
                                    </FormControl>

                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        disabled={false}
                                        style={{ height: '80px' }}
                                        required
                                    >
                                        <InputLabel id="placeUnit-label">
                                            {t('CONTROL_UNIT')}
                                        </InputLabel>
                                        <Select
                                            variant="standard"
                                            autoWidth
                                            disabled={readOnly}
                                            value={updatedPlace.controlUnit || ''}
                                            id="controlUnit"
                                            onChange={handleChange('controlUnit', true)}
                                        >
                                            <MenuItem value=''>-</MenuItem>
                                            {updatedPlace.controlUnits &&
                                                updatedPlace.controlUnits.map((controlUnit) => (
                                                    <MenuItem value={controlUnit.controlUnitId}>{controlUnit.identification}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                        <FormHelperText>  {error && error.controlUnit && (
                                            <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                        )}</FormHelperText>
                                    </FormControl>

                                    <div style={readOnly ? { color: '#B1B1B1' } : {}}>{t('PLACE_IMG')}</div>

                                    <Grid container sm={12} className={classes.item} >
                                        {!readOnly &&
                                            <Grid item sm={6} >
                                                <DropzoneAreaBase
                                                    dropzoneClass={classes.myDropZone}
                                                    fileObjects={file}
                                                    acceptedFiles={['image/*']}
                                                    showPreviewsInDropzone={false}
                                                    onAdd={handleAdd}
                                                    dropzoneText={t('DROP_ZONE_TEXT')}
                                                    maxFileSize={10000000}
                                                    filesLimit={1}
                                                    disabled={readOnly}
                                                />
                                            </Grid>
                                        }
                                        <Grid item sm={readOnly ? 12 : 6} >
                                            {updatedPlace && updatedPlace.imgBase64 &&
                                                <img src={'data:image/png;base64,' + updatedPlace.imgBase64}
                                                    className={classes.attachment}
                                                />
                                            }
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid container spacing={1} sm={8} sx={12} style={{ marginLeft: "auto", marginRight: "auto" }}>
                                <Grid item sm={12} spacing={1} >
                                    <div className={classes.editorWrapper}>
                                        {!updatedPlace?.tenantId ? t("NO_STANDS") :
                                        <MaterialTable
                                            title=""
                                            search="false"
                                            icons={{
                                                SortArrow: ArrowUpward,
                                                DetailPanel: ChevronRight,
                                                Search: Search,
                                                ResetSearch: Clear
                                            }}
                                            isLoading={isFetchingStandListByTenant || isFetchingBikeListByTenant}
                                            data={updatedPlace.stands}

                                            columns={[
                                                {
                                                    title: '',
                                                    field: 'openLock',
                                                    width: '10%',
                                                    render: rowData =>
                                                        <>
                                                            {(readOnly && isComponentVisibleForUser(UC0136, userUseCases) && rowData.checked > 0) &&
                                                                <VpnKeyIcon
                                                                    onClick={() => {
                                                                        if (isComponentEnabledForUser(UC0136, userUseCases)) {
                                                                            setReservationToOpenLock(rowData);
                                                                            setShowOpenLock(true);
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        </>
                                                },
                                                {
                                                    title: t('STAND_NAME'),
                                                    field: 'name',
                                                    width: '30%',
                                                    render: rowData => <TextField variant="standard" InputProps={{ readOnly: true, }} value={rowData.name} disabled={true} />
                                                },
                                                {
                                                    title: t('CUSTOM_STAND_NAME'),
                                                    field: 'customName',
                                                    width: '30%',
                                                    render: rowData => <TextField variant="standard" value={rowData.customName} onChange={handleRowChange(rowData, 'customName')} disabled={readOnly} />
                                                },
                                                {
                                                    title: t('BIKE'),
                                                    field: 'bikeName',
                                                    width: '30%',
                                                    render: rowData =>
                                                        <FormControl
                                                            variant="standard"
                                                            fullWidth
                                                            disabled={false}

                                                            required
                                                        >
                                                            <Select
                                                                variant="standard"
                                                                autoWidth
                                                                disabled={readOnly}
                                                                value={rowData.bikeId || ''}
                                                                id={'bikeSelect' + rowData.bikeId}
                                                                onChange={handleRowChange(rowData, 'bikeId')}
                                                            >
                                                                <MenuItem value=''>-</MenuItem>
                                                                {
                                                                    updatedPlace.bikes.map((bike) => (
                                                                        <MenuItem value={bike.bikeId}>{bike.name}</MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                            <FormHelperText>  {error && error.stands && error.stands[rowData.tableData.index] && error.stands[rowData.tableData.index].bikeId && (
                                                                <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                            )}</FormHelperText>
                                                        </FormControl>
                                                },
                                            ]}

                                            options={{
                                                showTitle: false, paging: false, showTextRowsSelected: false,
                                                headerStyle: {
                                                    position: "sticky",
                                                    top: "0"
                                                },
                                                maxBodyHeight: "500px",
                                                search: false,
                                                toolbarButtonAlignment: "left"
                                            }}

                                            actions={[
                                                {
                                                    icon: 'checked',
                                                    tooltip: 'checked',
                                                    onClick: (event, rowData) => { rowClickHandel(rowData) }
                                                },
                                                {
                                                    icon: 'checkAll',
                                                    tooltip: 'check all',
                                                    onClick: (event, rowData) => headerClickHandel(),
                                                    isFreeAction: true,
                                                },
                                            ]}

                                            localization={{
                                                header: {
                                                    actions: ''
                                                },
                                            }}

                                            components={{
                                                Action: props => (
                                                    <CustomCheckBox
                                                        onClick={(event) => props.action.onClick(event, props.data)}
                                                        color={'white'}
                                                        iconNumber={props.data.standId ? props.data.checked : headerChecked}
                                                        paddingTop={props.data.standId ? '0' : '8px'}
                                                        paddingLeft={props.data.standId ? '0' : '30px'}
                                                        width='5'
                                                        height='5'
                                                        text={props.data.standId ? '' : t('CHECK_ALL')}
                                                    />
                                                )
                                            }}
                                        />}
                                    </div>
                                </Grid>


                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </div>

            {showOpenLock &&
                <PDSDialog
                    open={showOpenLock}
                    title={t('OPEN_KEY_CONFIRMATION_TITLE')}
                    onClose={() => {
                        setShowOpenLock(false)
                        setReservationToOpenLock(null);
                    }}
                    size="sm"
                    headerColor='#5985EE'
                    height={300}
                    onSubmit={() => { handleOpenLock(reservationToOpenLock); }}
                    actionType={'open'}
                >
                    <div className={classes.content}>
                        {t('OPEN_KEY_CONFIRMATION_MESSAGE', { standName: reservationToOpenLock.customName })}
                    </div>
                </PDSDialog>
            }
        </>
    );
}


PlaceUpdate.propTypes = {};
PlaceUpdate.defaultProps = {};


const mapStateToProps = (store) => ({
    user: store.authData.user,
    isFetchingStandListByTenant: store.standData.isFetchingListByTenant,
    isFetchingBikeListByTenant: store.bikeData.isFetchingListByTenant,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PlaceUpdate);