import types from '../actionTypes';
import tenantService from '../../services/tenant.service';

export const createTenant = (tenant) => (dispatch, getState) => {
    if (getState().tenantsData.isCreating) {
        return Promise.reject();
    }

    dispatch({
        type: types.TENANT_CREATE_REQUEST,
    });

    return tenantService.createTenant(tenant)
        .then(() => {
            dispatch({
                type: types.TENANT_CREATE_SUCCESS,
                payload: {tenant: tenant},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.TENANT_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateTenant = (tenant) => (dispatch, getState) => {
    if (getState().tenantsData.isUpdating) {
        return Promise.reject();
    }

    dispatch({
        type: types.TENANT_UPDATE_REQUEST,
    });

    return tenantService.updateTenant(tenant)
        .then(() => {
            dispatch({
                type: types.TENANT_UPDATE_SUCCESS,
                payload: {tenant: tenant},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.TENANT_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const activeTenant = (tenantId, active) => (dispatch, getState) => {
    if (getState().tenantsData.isActivating) {
        return Promise.reject();
    }

    dispatch({
        type: types.TENANT_ACTIVE_REQUEST,
    });

    return tenantService.activeTenant(tenantId, active)
        .then((tenant) => {
            dispatch({
                type: types.TENANT_ACTIVE_SUCCESS,
                payload: {tenant: tenant},
            });
            return tenant;
        })
        .catch((error) => {
            dispatch({
                type: types.TENANT_ACTIVE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchTenants = () => (dispatch, getState) => {
    if (getState().tenantsData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.TENANT_FETCH_REQUEST,
    });

    return tenantService.getTenants()
        .then((tenants) => {
            dispatch({
                type: types.TENANT_FETCH_SUCCESS,
                payload: { tenants: tenants },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.TENANT_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};