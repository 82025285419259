import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { DropzoneAreaBase } from 'react-mui-dropzone';
import {fetchTenants} from "../../../redux/actions/tenant";
import { BorderColor } from '@mui/icons-material';
import { ColorButton } from 'mui-color';

const useStyles = makeStyles((theme) => ({
    formFields: {
        height: '100%',
        width: '100%',
        flexDirection: 'row',

        alignItems: 'flex-start',
        paddingTop: '50px',
    },
    editorWrapper: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(3.25, 4, 4, 4),
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    row2: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: '50px',
    },
    item: {
        height: '190px',
        border: `1px solid ${theme.palette.base[400]}`,
        marginTop: '10px',
        marginLeft: '10px',
        padding: '20px'
    },
    myDropZone: {
        minHeight: '140px',
    },
    attachment: {
        height: 140,
        // width: 720,
    },
    paletteRoot: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: '8px 0 0 8px',
    },
    paletteButton: {
        margin: '0 8px 8px 0',
        padding: 0,
    },
}));

const ColorPalette = ({ size, borderWidth, palette, onSelect, disableAlpha, value }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleSelectColor = name => {
        if (onSelect) onSelect(name);
    };

    return (
        <div className={classes.paletteRoot}>
            {Object.keys(palette).map(name => (
                <ColorButton
                    size={size}
                    key={`${name}`}
                    color={palette[name]}
                    className={`muicc-palette-button ${classes.paletteButton}`}
                    borderWidth={name === value ? 2 * borderWidth : borderWidth}
                    tooltip={t(name)}
                    disableAlpha={disableAlpha}
                    value={name}
                    onClick={() => handleSelectColor(name)}
                    borderColor={name === value ? '#6bbbae' : 'white'}
                />
            ))}
        </div>
    );
};

export const palette = {
    BC_BLUE: '#72a5c4',
    BC_BLACK: 'black',
    BC_GREEN: 'green',
    BC_RED: 'red',
    BC_GRAY: 'gray',
    BC_YELLOW: '#d6db1d',
    BC_WHITE: 'white',
    BC_SILVER: '#A9A9A9',
};

function BikeUpdate(props) {
    const classes = useStyles();

    const {
        handleChange,
        updatedBike,
        setUpdatedBike,
        error,
        readOnly,
        codeLists,
        user,
        tenants,
        isNew,
        fetchTenants,
    } = props;

    const { t } = useTranslation();
    const [file, setFile] = useState([]);
    const [isTenantsFetched, setIsTenantsFetched] = useState(false);

    useEffect(() => {
        if (!isTenantsFetched) {
            fetchTenants()
                .then(() => setIsTenantsFetched(true))
                .catch((err) => {
                    console.log(err);
                    setIsTenantsFetched(false);
                });
        }
    }, [isTenantsFetched]);

    const handleAdd = (newFile) => {
        setFile(newFile);
        setUpdatedBike({ ...updatedBike, img: newFile[0].data });
    };

    const renderMenuItemCodeList = (type) => {
        if (codeLists && codeLists.length > 0) {
            for (let i = 0; i < codeLists.length; i++) {
                if (codeLists[i].code === type) {
                    return codeLists[i].items.map((item) => (
                        <MenuItem value={item.code}>{t(item.code)}</MenuItem>
                    ));
                }
            }
        }
    }
    const emptyMenuItem = () => {
        return (
            <MenuItem value="">
                <em>{t('NONE')}</em>
            </MenuItem>
        );
    }
    
    return (
        <div className={classes.formFields}>
            <form autoComplete='off'>
                <Box mb={2} height="100%" width="100%">
                    <Grid container spacing={2} sm={12}>
                        <Grid container spacing={1} sm={6} sx={12} style={{ marginLeft: "auto", marginRight: "auto" }}>

                            <Grid item sm={12} spacing={1} >
                                <TextField
                                    variant="standard"
                                    style={{ height: '80px' }}
                                    autoFocus
                                    helperText={error && error.name
                                        ?
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                        : null}
                                    fullWidth
                                    required
                                    disabled={readOnly}
                                    id="name"
                                    value={updatedBike.name || ''}
                                    onChange={handleChange('name', false)}
                                    label={t('BIKE_NAME')}
                                />
                            </Grid>
                            <Grid item sm={12} spacing={1} >
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    disabled={false}
                                    style={{ height: '80px' }}
                                    required
                                >
                                    <InputLabel id="active-label">
                                        {t('BIKE_ACTIVE')}
                                    </InputLabel>
                                    <Select
                                        variant="standard"
                                        autoWidth
                                        disabled={readOnly}
                                        value={updatedBike.active || ''}
                                        id="active"
                                        onChange={handleChange('active', true)}
                                    >
                                        <MenuItem value=''>-</MenuItem>
                                        <MenuItem value={'true'}>{t('YES')}</MenuItem>
                                        <MenuItem value={'false'}>{t('NO')}</MenuItem>
                                    </Select>
                                    <FormHelperText>  {error && error.active && (
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    )}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item sm={12} spacing={1} >
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    disabled={false}
                                    style={{ height: '80px' }}
                                    required>
                                    <InputLabel id="bikeTypeLabel">{t('BIKE_TYPE')}</InputLabel>
                                    <Select
                                        variant="standard"
                                        autoWidth
                                        disabled={readOnly}
                                        labelId="bikeTypeLabel"
                                        id="bikeType"
                                        value={updatedBike.type || ''}
                                        onChange={handleChange('type', true)}>
                                        {emptyMenuItem()}
                                        {renderMenuItemCodeList('BI_T')}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sm={6} sx={12} style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <Grid item sm={12} spacing={1} >
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    disabled={false}
                                    style={{ height: '80px' }}
                                    required>
                                    <InputLabel id="bikeCategoryLabel">{t('BIKE_CATEGORY')}</InputLabel>
                                    <Select
                                        variant="standard"
                                        autoWidth
                                        disabled={readOnly}
                                        labelId="bikeCategoryLabel"
                                        id="bikeCategory"
                                        value={updatedBike.category || ''}
                                        onChange={handleChange('category', true)}>
                                        {emptyMenuItem()}
                                        {renderMenuItemCodeList('BI_C')}
                                    </Select>
                                </FormControl>
                            </Grid>


                            <Grid item sm={12} spacing={1} >
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    disabled={false}
                                    style={{ height: '80px' }}
                                    required>
                                    <InputLabel id="bikeSizeLabel">{t('BIKE_SIZE')}</InputLabel>
                                    <Select
                                        variant="standard"
                                        autoWidth
                                        disabled={readOnly}
                                        labelId="bikeSizeLabel"
                                        id="bikeSize"
                                        value={updatedBike.size || ''}
                                        onChange={handleChange('size', true)}>
                                        {emptyMenuItem()}
                                        {renderMenuItemCodeList('BI_S')}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={12} spacing={1} >
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    disabled={false}
                                    style={{ height: '80px' }}
                                    required
                                >
                                    <InputLabel id="electric-label">
                                        {t('BIKE_ELECTRIC')}
                                    </InputLabel>
                                    <Select
                                        variant="standard"
                                        autoWidth
                                        disabled={readOnly}
                                        value={updatedBike.electric !== undefined ? updatedBike.electric : ''}
                                        id="electric"
                                        onChange={handleChange('electric', true)}
                                    >
                                        <MenuItem value=''>-</MenuItem>
                                        <MenuItem value={'true'}>{t('YES')}</MenuItem>
                                        <MenuItem value={'false'}>{t('NO')}</MenuItem>
                                    </Select>
                                    <FormHelperText>  {error && error.active && (
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    )}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item sm={1} spacing={1} >
                            <div style={readOnly ? { color: '#B1B1B1' } : {}}>{t('BIKE_COLOR')}</div>
                        </Grid>
                        <Grid item sm={5} spacing={1}>
                            {!readOnly &&
                                <ColorPalette
                                    name="color"
                                    labelId="bikeColorLabel"
                                    defaultValue='#000'
                                    palette={palette}
                                    value={updatedBike.color || ''}
                                    onSelect={handleChange('color')}
                                    borderWidth={3}
                                    size={40}
                                />
                            }
                            {readOnly &&
                                <div style={{ backgroundColor: updatedBike.color, width: 20 }}>&nbsp;</div>
                            }
                        </Grid>
                        <Grid item sm={6} spacing={1}>
                            <FormControl
                                variant="standard"
                                required
                                fullWidth
                                disabled={false}
                                style={{ height: '60px' }}>
                                <InputLabel id="tenant-label">
                                    {t('TENANT')}
                                </InputLabel>
                                <Select
                                    variant="standard"
                                    autoWidth
                                    disabled={!user.admin || readOnly}
                                    value={updatedBike.tenantId ||
                                        (isNew && !user.admin && user.tenantId) ||
                                        ''}
                                    id="tenantId"
                                    onChange={handleChange('tenantId', updatedBike.tenantId)}>
                                    <MenuItem value=''>-</MenuItem>
                                    {tenants.map(item => (
                                        <MenuItem value={item.tenantId}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>  {error && error.active && (
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                )}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid container spacing={1} sm={12} className={classes.item} >
                            {!readOnly &&
                                <Grid item sm={6} spacing={1}>
                                    <DropzoneAreaBase
                                        dropzoneClass={classes.myDropZone}
                                        fileObjects={file}
                                        acceptedFiles={['image/*']}
                                        showPreviewsInDropzone={false}
                                        onAdd={handleAdd}
                                        dropzoneText={t('DROP_ZONE_TEXT')}
                                        maxFileSize={10000000}
                                        filesLimit={1}
                                        disabled={readOnly}
                                    />
                                </Grid>
                            }
                            <Grid item sm={readOnly ? 12 : 6} spacing={1}>
                                {updatedBike && updatedBike.img &&
                                    <img src={updatedBike.img}
                                        className={classes.attachment}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </div>
    );
}


BikeUpdate.propTypes = {};
BikeUpdate.defaultProps = {};


const mapStateToProps = (store) => ({
    user: store.authData.user,
    tenants: store.tenantsData.tenants,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchTenants,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BikeUpdate);