import React from 'react';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import {Link} from "react-router-dom";
import Info from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import GroupIcon from '@mui/icons-material/Group';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PlaceIcon from '@mui/icons-material/Place';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import { isComponentEnabledForUser, isComponentVisibleForUser } from '../../utils';
import { UC0111, UC0121, UC0131, UC0141, UC0151, UC0161 } from '../../utils/constants';

import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import KeyIcon from '@mui/icons-material/Key';
import PedalBikeIcon from '@mui/icons-material/PedalBike';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(1.25),
        marginLeft: theme.spacing(3.75),
    },
    title: {
        cursor: 'pointer',
    },
    list: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
    },
    item: {
        width: 370,
        height: 200,
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        marginRight: theme.spacing(2.5),
        marginTop: theme.spacing(2.5),
        backgroundColor: theme.palette.background.paper,
        borderRadius: 30,
        cursor: 'pointer',
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 160,
        //margin: theme.spacing(2.5, 3.75, 3.75, 0),
    },
    itemToolBar: {
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center',
        height: 20,
        width: '100%',
        paddingTop: '30px',
        paddingRight: '50px',
    },
    itemTitle: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.base[600],
    },
}));

function AdministrationList(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        userUseCases
    } = props;

    return (
        <div className={classes.container}>
            <div className={classes.list}>
                { isComponentVisibleForUser(UC0151, userUseCases) &&
                    <div className={classes.item}>
                        <div className={classes.itemToolBar}>
                            <Tooltip title={t('ROLE_ADMINISTRATION_DESCRIPTION')}><div><Info color="primary" /></div></Tooltip>
                        </div>
                        <Link to={isComponentEnabledForUser(UC0151, userUseCases) ? `/${process.env.REACT_APP_PATH}/administration/rolelist` : "#"}
                            className={classes.link}>
                            <div className={classes.titleWrapper}>
                                <div className={classes.itemTitle}>
                                    <IconButton color="secondary" aria-label="Set up parkings" size="large">
                                        <PeopleIcon style={{ fontSize: 65, color: 'black' }} />
                                    </IconButton>
                                    <Typography variant="h3" style={{color: 'black' }}>{t('ROLE_ADMINISTRATION')}</Typography>
                                </div>
                            </div>
                        </Link>
                    </div>
                }

                { isComponentVisibleForUser(UC0161, userUseCases) &&
                    <div className={classes.item}>
                        <div className={classes.itemToolBar}>
                            <Tooltip title={t('USER_ADMINISTRATION_DESCRIPTION')}><div><Info color="primary" /></div></Tooltip>
                        </div>
                        <Link to={isComponentEnabledForUser(UC0161, userUseCases) ? `/${process.env.REACT_APP_PATH}/administration/userlist` : '#'}
                            className={classes.link}>
                            <div className={classes.titleWrapper}>
                                <div className={classes.itemTitle}>
                                    <IconButton color="secondary" aria-label="Set up parkings" size="large">
                                        <PersonIcon color="secondary" style={{ fontSize: 65, color: 'black' }} />
                                    </IconButton>
                                    <Typography variant="h3" style={{color: 'black'}}>{t('USER_ADMINISTRATION')}</Typography>
                                </div>
                            </div>
                        </Link>
                    </div>
                }

                { isComponentVisibleForUser(UC0111, userUseCases) &&
                    <div className={classes.item}>
                        <div className={classes.itemToolBar}>
                            <Tooltip title={t('TENANT_ADMINISTRATION_DESCRIPTION')}><div><Info color="primary" /></div></Tooltip>
                        </div>
                        <Link to={isComponentEnabledForUser(UC0111, userUseCases) ? `/${process.env.REACT_APP_PATH}/administration/tenantlist` : '#'}
                            className={classes.link}>
                            <div className={classes.titleWrapper}>
                                <div className={classes.itemTitle}>
                                    <IconButton color="secondary" aria-label="Set up parkings" size="large">
                                        <PeopleOutlineIcon color="secondary" style={{ fontSize: 65, color: 'black' }} />
                                    </IconButton>
                                    <Typography variant="h3" style={{color: 'black'}}>{t('TENANT_ADMINISTRATION')}</Typography>
                                </div>
                            </div>
                        </Link>
                    </div>
                }

                { isComponentVisibleForUser(UC0121, userUseCases) &&
                    <div className={classes.item}>
                        <div className={classes.itemToolBar}>
                            <Tooltip title={t('PLACE_ADMINISTRATION_DESCRIPTION')}><div><Info color="primary" /></div></Tooltip>
                        </div>
                        <Link to={isComponentEnabledForUser(UC0121, userUseCases) ? `/${process.env.REACT_APP_PATH}/administration/placelist` : '#'}
                            className={classes.link}>
                            <div className={classes.titleWrapper}>
                                <div className={classes.itemTitle}>
                                    <IconButton color="secondary" aria-label="Set up parkings" size="large">
                                        <LocationOnIcon color="secondary" style={{ fontSize: 65, color: 'black' }} />
                                    </IconButton>
                                    <Typography variant="h3" style={{color:'black'}}>{t('PLACE_ADMINISTRATION')}</Typography>
                                </div>
                            </div>
                        </Link>
                    </div>
                }

                { isComponentVisibleForUser(UC0131, userUseCases) &&
                    <div className={classes.item}>
                        <div className={classes.itemToolBar}>
                            <Tooltip title={t('STAND_ADMINISTRATION_DESCRIPTION')}><div><Info color="primary" /></div></Tooltip>
                        </div>
                        <Link to={isComponentEnabledForUser(UC0131, userUseCases) ? `/${process.env.REACT_APP_PATH}/administration/standlist` : '#'}
                            className={classes.link}>
                            <div className={classes.titleWrapper}>
                                <div className={classes.itemTitle}>
                                    <IconButton color="secondary" aria-label="Set up parkings" size="large">
                                        <KeyIcon color="secondary" style={{ fontSize: 65, color: 'black' }} />
                                    </IconButton>
                                    <Typography variant="h3" style={{color: 'black'}}>{t('STAND_ADMINISTRATION')}</Typography>
                                </div>
                            </div>
                        </Link>
                    </div>
                }

                { isComponentVisibleForUser(UC0141, userUseCases) &&
                    <div className={classes.item}>
                        <div className={classes.itemToolBar}>
                            <Tooltip title={t('BIKE_ADMINISTRATION_DESCRIPTION')}><div><Info color="primary" /></div></Tooltip>
                        </div>
                        <Link to={isComponentEnabledForUser(UC0141, userUseCases) ? `/${process.env.REACT_APP_PATH}/administration/bikelist` : '#'}
                            className={classes.link}>
                            <div className={classes.titleWrapper}>
                                <div className={classes.itemTitle}>
                                    <IconButton color="secondary" aria-label="Set up parkings" size="large">
                                        <PedalBikeIcon color="secondary" style={{ fontSize: 65, color: 'black' }} />
                                    </IconButton>
                                    <Typography variant="h3" style={{color: 'black'}}>{t('BIKE_ADMINISTRATION')}</Typography>
                                </div>
                            </div>
                        </Link>
                    </div>
                }

            </div>
        </div>
    );
}

AdministrationList.propTypes = {};


export default AdministrationList;
