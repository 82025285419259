import types from '../actionTypes';

const INITIAL_STATE = {
    isFetchingAllList: false,
    isFetchingListByTenant: false,
    isCreating: false,
    isUpdating: false,
    isActivating: false,
    isOpening: false,
    errors: '',
    stands: [],
    standsByTenant: [],
    byTenantId: undefined,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.STAND_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAllList: false,
                stands: action.payload.stands,
            };
        case types.STAND_FETCH_FAIL:
            return {
                ...state,
                isFetchingAllList: false,
                errors: action.payload.error,
            };
        case types.STAND_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAllList: true,
            };

        case types.STAND_FETCH_SUCCESS_BY_TENANT:
            return {
                ...state,
                isFetchingListByTenant: false,
                standsByTenant: action.payload.standsByTenant,
                byTenantId: action.payload.tenantId,
            };
        case types.STAND_FETCH_FAIL_BY_TENANT:
            return {
                ...state,
                isFetchingListByTenant: false,
                errors: action.payload.error,
            };
        case types.STAND_FETCH_REQUEST_BY_TENANT:
            return {
                ...state,
                isFetchingListByTenant: true,
            };
        
        case types.STAND_OPEN_SUCCESS:
            return {
                ...state,
                isOpening: false,
            };
        case types.STAND_OPEN_FAIL:
            return {
                ...state,
                isOpening: false,
                errors: action.payload.error,
            };
        case types.STAND_OPEN_REQUEST:
            return {
                ...state,
                isOpening: true,
            };

        // case types.STAND_CREATE_SUCCESS:
        // case types.STAND_CREATE_REQUEST:
        //     return {
        //         ...state, isCreating: true
        //     };
        // case types.STAND_CREATE_FAIL:
        //     return {
        //         ...state, 
        //         isCreating: true,
        //         errors: action.payload.error,
        //     };

        // case types.STAND_UPDATE_SUCCESS:
        // case types.STAND_UPDATE_REQUEST:
        //     return {
        //         ...state, isUpdating: true
        //     };
        // case types.STAND_CREATE_FAIL:
        //     return {
        //         ...state, 
        //         isUpdating: true,
        //         errors: action.payload.error,
        //     };

        // case types.STAND_ACTIVE_SUCCESS:
        // case types.STAND_ACTIVE_REQUEST:
        //     return {
        //         ...state, isActivating: true
        //     };
        // case types.STAND_ACTIVE_FAIL:
        //     return {
        //         ...state, 
        //         isActivating: true,
        //         errors: action.payload.error,
        //     };

        default:
            return state;
    }
};
