import httpService from './http.service';


const getControlUnits = () => httpService
    .get(`/controlunit/all`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


export default {
    getControlUnits,
};