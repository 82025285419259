import types from '../actionTypes';
import standService from '../../services/stand.service';

export const fetchStandsByTenantId = (tenantId) => (dispatch, getState) => {
    if (getState().standData.isFetchingListByTenant) {
        return Promise.reject();
    }

    dispatch({
        type: types.STAND_FETCH_REQUEST_BY_TENANT,
    });

    return standService.getStandsByTenantId(tenantId)
        .then((standsByTenant) => {
            dispatch({
                type: types.STAND_FETCH_SUCCESS_BY_TENANT,
                payload: { standsByTenant: standsByTenant, tenantId: tenantId },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.STAND_FETCH_FAIL_BY_TENANT,
                payload: { error },
            });

            throw error;
        });
};

export const fetchStands = () => (dispatch, getState) => {
    if (getState().standData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.STAND_FETCH_REQUEST,
    });

    return standService.getStands()
        .then((stands) => {
            dispatch({
                type: types.STAND_FETCH_SUCCESS,
                payload: { stands: stands },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.STAND_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const openStand = (standId, controlUnitId) => (dispatch, getState) => {
    if (getState().standData.isOpening) {
        return Promise.reject();
    }

    dispatch({
        type: types.STAND_OPEN_REQUEST,
    });

    return standService.openStand(standId, controlUnitId)
        .then(() => {
            dispatch({
                type: types.STAND_OPEN_SUCCESS,
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.STAND_OPEN_FAIL,
                payload: { error },
            });

            throw error;
        });
};
