import httpService from './http.service';


// const createStand = (stand) => httpService
//     .post(`/stand`, stand)
//     .then(({data}) => data)
//     .catch((err) => {
//         throw err;
//     });

// const updateStand = (stand) => httpService
//     .put(`/stand`, stand)
//     .then(({data}) => data)
//     .catch((err) => Promise.reject(err));

// const activeStand = (standId, active) => httpService
//     .put(`/stand/${standId}/?active=${active}`)
//     .then(({data}) => data)
//     .catch((err) => Promise.reject(err));

const getStands = () => httpService
    .get(`/stand/all`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getStandsByTenantId = (tenantId) => httpService
    .get(`/stand/tenant/${tenantId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const openStand = (standId, controlUnitId) => httpService
    .put(`/stand/${standId}/open/${controlUnitId}`)
    .then(({data}) => data)
    .catch((err) =>Promise.reject(err.response));


export default {
    // createStand,
    // updateStand,
    // activeStand,
    getStands,
    openStand,
    getStandsByTenantId,
};