import { Loading } from '../../components/elements';
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import ToolBar from './ToolBar';
import ReservationList from './ReservationList'
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { fetchReservations, createReservation, updateReservation, deleteReservation } from '../../redux/actions/reservation'
import { fetchBikes, fetchAvailableBikes } from '../../redux/actions/bike';
import { fetchPlaces } from '../../redux/actions/place';
import { fetchCodeLists } from '../../redux/actions/codelists';
import { fetchTenants } from '../../redux/actions/tenant';
import { fetchAllUsers } from '../../redux/actions/users';
import NewReservation from '../../components/modal/reservation';
import { DATE_TIME_FORMAT, UC0101 } from '../../utils/constants';
import { getErrorMessage, isComponentVisibleForUser } from '../../utils';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import ReturnedBike from '../../components/modal/ReturnedBike';
import PDSSnackbar from '../../components/common/Snackbar';
import {openStand} from "../../redux/actions/stand";

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.base.white,
        borderRadius: '4px',
    },
    container2: {
        margin: theme.spacing(2),
        borderRadius: '4px',
    },
    fetchingProgress: {
        paddingTop: '12px',
    },
}));

const Reservation = (props) => {
    const classes = useStyles();
    const {
        user,
        users,
        fetchAllUsers,
        reservations,
        isFetchingAllList,
        fetchReservations,
        reservationsErrors,
        bikes,
        fetchBikes,
        availableBikes,
        fetchAvailableBikes,
        places,
        fetchPlaces,
        codeLists,
        fetchCodeLists,
        createReservation, updateReservation, deleteReservation,
        fetchTenants,
        tenants,
        useCases,
        userUseCases,
        openStand,
    } = props;

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isFetchedReservations, setIsFetchedReservations] = useState(false);
    const [isFetchedBikes, setIsFetchedBikes] = useState(false);
    const [isFetchedPlaces, setIsFetchedPlaces] = useState(false);
    const [isFetchedCodeList, setIsFetchedCodeLists] = useState(false);
    const [isFetchedTenants, setIsFetchedTenants] = useState(false);
    const [isFetchedUsers, setIsFetchedUsers] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [editReservation, setEditReservation] = useState(false);
    const [showReservationItem, setShowReservationItem] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState('');
    const [editValues, setEditValues] = useState();

    const [filterValue, setFilterValue] = useState();

    useEffect(() => {
        if (user) {
            setFilterValue({ 
                user: { value: user.id }, 
                tenant: { value: user.tenantId }, 
                dates: [dayjs().subtract(1, 'month').hour(0).minute(0).millisecond(0),null],
                reservationStatus: {value: 'NEW'}
            });
            setIsFetchedReservations(false);
        }
    }, [user]);

    useEffect(() => {
        if (filterValue) {
            if (!isFetchedReservations) {
                setIsLoading(true);

                let dateFrom, dateTo, userId, tenantId, state;

                if (filterValue) {
                    if (filterValue.dates) {
                        if (filterValue.dates[0]) {
                            dateFrom = filterValue.dates[0].format(DATE_TIME_FORMAT);
                        } else {
                            dateFrom = '';
                        }

                        if (filterValue.dates[1]) {
                            dateTo = filterValue.dates[1].format(DATE_TIME_FORMAT);
                        } else {
                            dateTo = '';
                        }
                    }
                    if (filterValue.user) {
                        userId = filterValue.user.value;
                    }
                    if (filterValue.tenant) {
                        tenantId = filterValue.tenant.value;
                    }
                    if (filterValue.reservationStatus) {
                        state = filterValue.reservationStatus.value
                    }
                }

                fetchReservations(dateFrom, dateTo, userId, tenantId, state).then(() => {
                    setIsFetchedReservations(true);
                }).catch((err) => {
                    setIsFetchedReservations(true);
                    setIsLoading(false)
                });
            }
        }
        if (!isFetchedBikes) {
            setIsLoading(true);
            fetchBikes().then(() => {
                setIsFetchedBikes(true);
            }).catch((err) => {
                setIsFetchedBikes(true);
                setIsLoading(false)
            });
        }
        if (!isFetchedPlaces) {
            setIsLoading(true);
            fetchPlaces().then(() => {
                setIsFetchedPlaces(true);
            }).catch((err) => {
                setIsFetchedPlaces(true);
                setIsLoading(false)
            });
        }
        if (!isFetchedCodeList) {
            setIsLoading(true);
            fetchCodeLists().then(() => {
                setIsFetchedCodeLists(true);
            }
            ).catch((err) => {
                setIsFetchedCodeLists(true);
                setIsLoading(false)
            });
        }
        if (!isFetchedTenants) {
            setIsLoading(true);
            fetchTenants().then(() => {
                setIsFetchedTenants(true);
            }).catch((err) => {
                setIsFetchedTenants(true);
                setIsLoading(false)
            });
        }
        if (!isFetchedUsers) {
            setIsLoading(true);
            fetchAllUsers().then(() => {
                setIsFetchedUsers(true);
            }).catch((err) => {
                setIsFetchedUsers(true);
                setIsLoading(false)
            });
        }
    }, [filterValue, isFetchedReservations, fetchReservations,
        isFetchedBikes, fetchBikes,
        isFetchedPlaces, fetchPlaces,
        isFetchedCodeList, fetchCodeLists,
        isFetchedTenants, fetchTenants,
        isFetchedUsers, fetchAllUsers
    ]);

    if (!user && !isLoading) {
        return (
            <Loading />
        );
    }

    const handleFilter = (values) => {
        setIsLoading(true);
        setFilterValue(values);
        setIsFetchedReservations(false);
    }

    const hadleNewReservation = (obj) => {
        setShowNew(true);
    }

    const handleNewClose = () => {
        setShowNew(false);
        setEditReservation(false);
        setEditValues();
    }

    const handleNewSave = (reservation) => {
        let today = new Date();
        let tmp = {
            'reservationId': reservation.reservationId,
            'active': true,
            'name': reservation.name,
            'creationDate': today.toISOString(),
            'dateFrom': reservation.dateFrom,
            'dateTo': reservation.dateTo,
            'items': reservation.bikes.map((item) => {
                return { 'placeConfig': item }
            })
        };
        if ( tmp.reservationId ) {
            updateReservation(tmp)
            .then(() => {
                setEditReservation(false);
                setIsFetchedReservations(false);
                setEditValues();
            })
            .catch((err)=>{
                console.log('updateReservation error', err);
                // setEditReservation(false);
                // setIsFetchedReservations(false);
                // setEditValues();
            });
        } else {
            createReservation(tmp)
            .then(() => {
                setShowNew(false);
                setIsFetchedReservations(false);
            })
            .catch((err)=>{
                console.log('createReservation error', err);
                // setShowNew(false);
                // setIsFetchedReservations(false);
            });
        }
    }

    const handleEdit = (id) => {
        reservations.map((item) => {
            if (item.reservationId === id) {
                let bikes = item.items.map((bike) => bike.placeConfig);
                bikes.forEach(item => {
                    item.selected = true;
                });

                setEditValues({
                    date: [dayjs(item.dateFrom), dayjs(item.dateTo)],
                    myBikes: bikes,
                    name: item.name,
                    reservationId: item.reservationId
                });
            }
        })
        setEditReservation(true);
    }

    const handleDelete = (id) => {
        deleteReservation(id)
            .then(()=>{
                setIsFetchedReservations(false);
            })
            .catch(()=>{
                setIsFetchedReservations(false);
            });
    }

    const handleOpenKey = (standId, controlUnitId) => {
        openStand(standId, controlUnitId)
            .then(()=>{
            })
            .catch((err)=>{
                setError(getErrorMessage(err, t));
                setSnackbarOpen(true);                
            });
    }

    const getReservationsData = () => {
        if (!reservations) return [[]];

        const header = [t('RESERVATION_NAME'), t('RESERVATION_STATE'), t('RESERVATION_CREATION_DATE'), t('DATE_FROM'), t('DATE_TO'), t('RESERVATION_LENGTH'),
        t('RESERVATION_PLACE'), t('RESERVATION_BIKE'), t('USER'), t('TENANT')];

        let data = reservations.map((item) => {
            let place = '', bike = '', tenantTmp = '';
            for (let i = 0; i < item.items.length; i++) {
                if ((i + 1) === item.items.length) {
                    place += item.items[i].placeConfig.place.name;
                    bike += item.items[i].placeConfig.bike.name;
                } else {
                    place += item.items[i].placeConfig.place.name + ', ';
                    bike += item.items[i].placeConfig.bike.name + ', ';
                }
            }

            for (let i = 0; i < users.length; i++) {
                if (users[i].id === item.createById) {
                    tenantTmp = users[i].tenantName;
                }
            }

            return [
                item.name,
                item.active ? t('ACTIVE') : t('INACTIVE'),
                item.creationDate,
                item.dateFrom,
                item.dateTo,
                ((new Date(item.dateTo).getTime() - new Date(item.dateFrom).getTime()) / (60 * 60 * 1000)),
                place,
                bike,
                item.createBy,
                tenantTmp
            ];
        })
        return [header].concat(data);
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    return (
        <>
            <PDSSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity={'error'} message={error}/>
            {(showNew || editReservation) &&
                <NewReservation
                    openNew={showNew}
                    handleClose={handleNewClose}
                    handleSave={handleNewSave}
                    places={places}
                    codeLists={codeLists}
                    editReservation={editReservation}
                    editValues={editValues}
                    availableBikes={availableBikes}
                    fetchAvailableBikes={fetchAvailableBikes}
                    reservationsErrors={reservationsErrors}
                    user={user}
                />
            }
            {showReservationItem !== null && (
                <ReturnedBike 
                    open={showReservationItem !== null}
                    handleClose={() => setShowReservationItem(null)}
                    reservationItem={showReservationItem.item}
                    reservation={showReservationItem.reservation}
                />
            )}
            <div className={classes.container}>
                <ToolBar
                    user={user}
                    users={users}
                    handleFilter={handleFilter}
                    hadleNewReservation={hadleNewReservation}
                    userUseCases={userUseCases}
                    tenants={tenants}
                    getReservationsData={getReservationsData}
                />
            </div>
            <div className={classes.container2}>
                {!isFetchingAllList && isFetchedReservations ?
                    <PerfectScrollbar options={{ suppressScrollX: true, minScrollbarLength: 50, }}>
                        {isComponentVisibleForUser(UC0101, userUseCases) &&
                            <ReservationList
                                reservations={reservations}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                userUseCases={userUseCases}
                                handleOpenKey={handleOpenKey}
                                setShowReservationItem={setShowReservationItem}
                            />
                        }
                    </PerfectScrollbar>
                    : <div className={classes.fetchingProgress}><CircularProgress size={60} /> </div>}
            </div>
        </>
    );
}

Reservation.propTypes = {
    user: PropTypes.object,
};

Reservation.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    reservations: store.reservationData.reservations,
    reservationsErrors: store.reservationData.errors,
    isFetchingAllList: store.reservationData.isFetchingAllList,
    bikes: store.bikeData.bike,
    availableBikes: store.bikeData.availableBike,
    places: store.placeData.places,
    codeLists: store.codelistData.codelist,
    useCases: store.useCasesData.useCases,
    userUseCases: store.authData.userUseCases,
    tenants: store.tenantsData.tenants,
    users: store.usersData.parkingUsers,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchReservations,
    fetchBikes,
    fetchAvailableBikes,
    fetchPlaces,
    fetchCodeLists,
    fetchTenants,
    fetchAllUsers,
    createReservation, 
    updateReservation, 
    deleteReservation,
    openStand,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Reservation);