import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import { makeStyles } from '@mui/styles';
import {getErrorMessage, isComponentEnabledForUser, isComponentVisibleForUser} from '../../../utils';
import ToolBar from './ToolBar';
import PerfectScrollbar from "react-perfect-scrollbar";
import RoleList from "./RoleList";
import {createRole, updateRole, activeRole, addUserToRole, removeUserToRole, fetchAllRoles} from "../../../redux/actions/roles";
import {fetchAllAdminUseCases} from "../../../redux/actions/usecases";
import RoleUpdate from "../../../components/modal/RoleUpdate";
import RoleDetail from "../../../components/modal/RoleDetail";
import {Grid} from '@mui/material';
import {ToggleButton} from '@mui/lab';
import TocIcon from '@mui/icons-material/Toc';
import {fetchAllUsers} from "../../../redux/actions/users";

const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    title: {
        marginBottom: theme.spacing(3.75),
        marginTop: theme.spacing(3.75),
        textTransform: 'uppercase',
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    container: {
        background: theme.palette.base.white,
        marginTop: theme.spacing(4),
    },
}));


function RoleAdministration(props) {
    const {
        userUseCases,
        useCases,
        roles,
        fetchAllRoles,
        fetchAllAdminUseCases,
        isFetchingAllList,
        createRole,
        updateRole,
        activeRole,
        addUserToRole,
        removeUserToRole,
        user,
        fetchAllUsers,
        allUsers,
    } = props;


    const classes = useStyles();
    const {t} = useTranslation();

    const [isFetchedRole, setIsFetchedRole] = useState(false);
    const [isFetchedAllAdminUseCases, setIsFetchedAllAdminUseCases] = useState(false);
    const [isFetchedAllUsers, setIsFetchedAllUsers] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [mode, setMode] = useState(STEP_ONE);
    const [userData, setUserData] = useState();

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const [tableUpdateData, setTableUpdateData] = useState();
    const [tableDetailData, setTableDetailData] = useState();

    useEffect(() => {

        if (!isFetchedRole) {
            setIsLoading(true);
            fetchAllRoles().then(() => {
                setIsFetchedRole(true);
                setIsLoading(true);
            }).catch((err) => {
                setIsFetchedRole(false);
                setIsLoading(false)
            });
        }
        if (!isFetchedAllAdminUseCases) {
            setIsLoading(true);
            fetchAllAdminUseCases().then(() => {
                setIsFetchedAllAdminUseCases(true);
                setIsLoading(true);
            }).catch((err) => {
                setIsFetchedAllAdminUseCases(false);
                setIsLoading(false)
            });
        }
        if (!isFetchedAllUsers) {
            setIsLoading(true);
            fetchAllUsers().then(() => {
                setIsFetchedAllUsers(true);
                setIsLoading(true);
            }).catch((err) => {
                setIsFetchedAllUsers(false);
                setIsLoading(false)
            });
        }
    }, [fetchAllRoles, isFetchedRole, fetchAllAdminUseCases, isFetchedAllAdminUseCases, isFetchedAllUsers]);

    useEffect(() => {
        setTableUpdateData(useCases);
    }, [useCases]);

    useEffect(() => {
        setUserData(allUsers);
    }, [allUsers]);

    const [selectedRoleName, setSelectedRoleName] = useState('');
    const [selectedUCName, setSelectedUCName] = useState('');

    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedAdminRole, setSelectedAdminRole] = useState();

    const [confirmModalOpened, setConfirmModalOpened] = useState(false);


    const [table, setTable] = useState(false);

    const handleSelectedRoleName = (value) => {
        setSelectedRoleName(value.target.value);
    };
    const handleSelectedUCName = (value) => {
        setSelectedUCName(value.target.value);

    };
    const handleSelectStatus = (value) => {
        setSelectedStatus(value);
    };
    const handleSelectAdminRole = (value) => {
        setSelectedAdminRole(value);
    };

    const matchStatus = (role) => {
        let res = false;
        for (let i = 0; i < selectedStatus.length; i++) {
            res = ( selectedStatus[i].key === 'ACTIVE' && role.active===true ) || ( selectedStatus[i].key === 'INACTIVE' && role.active===false );
            if (res) {
                return true;
            }
        }
        return false;
    }

    const matchAdminRole = (role) => {
        let res = false;
        for (let i = 0; i < selectedAdminRole.length; i++) {
            res = ( selectedAdminRole[i].key === 'YES' && role.admin===true ) || ( selectedAdminRole[i].key === 'NO' && role.admin===false );
            if (res) {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        const filRole = [];
        if ( roles && roles.length>0 ) {
            for (let i = 0; i < roles.length; i++) {
                let matchCriteria = true;
                if (selectedRoleName && selectedRoleName.trim().length > 0) {
                    if (roles[i].name) {
                        if (!(roles[i].name.toLowerCase().includes(selectedRoleName.toLowerCase()))) {
                            matchCriteria = false;
                        }
                    }
                }

                if (selectedUCName && selectedUCName.trim().length > 0) {
                    matchCriteria = false;
                    if (roles[i].useCases && roles[i].useCases.length > 0) {
                        for (let j = 0; j < roles[i].useCases.length; j++) {
                            let res = roles[i].useCases[j].code.includes(selectedUCName) || roles[i].useCases[j].description.includes(selectedUCName);
                            if (res) {
                                matchCriteria = true;
                                break;
                            }
                        }
                    }

                }

                if (selectedStatus && selectedStatus.length > 0) {
                    const res = matchStatus(roles[i]);
                    if (!res) {
                        matchCriteria = false;
                    }
                }

                if (selectedAdminRole && selectedAdminRole.length > 0) {
                    const res = matchAdminRole(roles[i]);
                    if (!res) {
                        matchCriteria = false;
                    }
                }

                if (matchCriteria) {
                    filRole.push(roles[i]);
                }
            }
            setFilteredRole(filRole);
        }
    }, [roles, selectedRoleName, selectedUCName, selectedStatus, selectedAdminRole]);


    const [filteredRole, setFilteredRole] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState('');
    const [saving, setSaving] = useState('');
    const [headerChecked, setHeaderChecked] = useState();

    const closeRoleUpdate = () => {
        setError('');
        setSnackbarOpen(false);
        setUpdateRoleConfigModel({isNew: false, role: {}, open: false});
    }

    const closeRoleDetail = () => {
        setError('');
        setSnackbarOpen(false);
        setDetailRoleConfigModel({isNew: false, role: {}, open: false});
        setIsFetchedRole(false);
        setMode(STEP_ONE)
    }

    const handleRoleUpdate = (role, isNew) => {
        setError('');
        setSnackbarOpen(false);

        setChecked(role);
        let newRole = {...role};

        newRole.active=newRole.active===true?'true':(newRole.active===false?'false':'');
        newRole.admin=newRole.admin===true?'true':(newRole.admin===false?'false':'');

        setUpdateRoleConfigModel({isNew: isNew, role: newRole, open: true});
    };

    const handleRoleDetail = (role) => {
        setError('');
        setSnackbarOpen(false);
        let tmpData = [];

        setChecked(role);
        let newRole = {...role};

        newRole.active=newRole.active===true?'true':(newRole.active===false?'false':'');
        newRole.admin=newRole.admin===true?'true':(newRole.admin===false?'false':'');

        for ( let i=0; i<tableUpdateData.length; i++) {
            if ( tableUpdateData[i].checked)
                tmpData.push(tableUpdateData[i]);
        }

        setTableDetailData(tmpData);
        setDetailRoleConfigModel({role: newRole, open: true});
    };

    const handleNext = () => {
        setMode(STEP_TWO);
    }

    const handleBack = () => {
        setMode(STEP_ONE);
    }

    const handleSearch = (value) => {
        if ( allUsers ) {
            let tmp = allUsers.filter( row => ( row.firstName && row.firstName.includes(value)) || (row.lastName && row.lastName.includes(value)) );
            setUserData(tmp);
        }
    };


    const setChecked = (role) => {
        if ( tableUpdateData && role.useCases ) {

            for ( let j=0; j<tableUpdateData.length; j++ ) {
                tableUpdateData[j].checked=0;
                if ( role.useCases ) {
                    for ( let i=0; i<role.useCases.length; i++) {
                        if ( role.useCases[i].code === tableUpdateData[j].code ) {
                            if ( role.useCases[i].execute )
                                tableUpdateData[j].checked=2;
                            else
                                tableUpdateData[j].checked=1;

                            break;
                        }

                    }
                }
            }

            if ( role.useCases ) {
                setRootChecked(tableUpdateData);
            }
        }        
    }

    const setRootChecked = (data) => {
        let rootRow;
        let all1, all2, empty;
        for ( let i=0; i<data.length; i++ ) {
            if ( !data[i].code ) {
                if ( rootRow && (!all1 || !all2) && empty) {
                    rootRow.checked=3;
                } else if ( rootRow && all1 && all2 && empty) {
                    rootRow.checked=0;
                }

                rootRow=data[i];
                all1=true;all2=true;empty=false;
            } else {
                if ( rootRow ) {
                    if ( data[i].checked===1) {
                        rootRow.checked=1;
                        all2=false;
                    }
                    if ( data[i].checked===2 ) {
                        rootRow.checked=2;
                        all1=false
                    }
                    if ( !data[i].checked ) {
                        empty=true;
                    }
                }
            }
        }
        if ( rootRow && (!all1 || !all2) && empty) {
            rootRow.checked=3;
        } else if ( rootRow && all1 && all2 && empty) {
            rootRow.checked=0;
        }
    }

    const handleActivate = (role) => {
        activeRole(role.roleId, !role.active);
        setConfirmModalOpened(false);
        setIsFetchedRole(false);
    }

    const headerClickHandel = () => {
        let valueChecked=0;
        if ( headerChecked ) {
            if (headerChecked===1) valueChecked=2;
            else if (headerChecked===2) valueChecked=0
        } else {
            valueChecked=1;
        }

        let newData = tableUpdateData.map((row)=>{
            return {... row}
        });

        for (let i=0; i<newData.length; i++) {
            newData[i].checked=valueChecked;
        }

        setTableUpdateData(newData);
        setHeaderChecked(valueChecked);
    }

    const rowClickHandel = (rowData) => {
        let newData = tableUpdateData.map((row)=>{
            return {... row}
        });

        let rootRowId=0;
        let rootChecked=0;

        for (let i=0; i<newData.length; i++) {
            if ( newData[i].useCaseId===rowData.useCaseId ) {
                if ( !newData[i].checked || newData[i].checked===0)
                    newData[i].checked=1;
                else if ( newData[i].checked===1)
                    newData[i].checked=2;
                else
                    newData[i].checked=0;

                // pokud je to koren, zmen to vse i na potomcich
                if ( !newData[i].code ) {
                    rootRowId = newData[i].useCaseId;
                    rootChecked = newData[i].checked;
                } else {
                }
            }
            if ( rootRowId === newData[i].parentId ) {
                newData[i].checked = rootChecked;
            }
        }
        setRootChecked(newData);
        setTableUpdateData(newData);
    }

    const rowSetExpanded = (rowData, expanded) => {
        let newData = tableUpdateData.map((row)=>{
            return {...row}
        });
        for (let i=0; i<newData.length; i++) {
            if ( newData[i].useCaseId===rowData.useCaseId ) {
                newData[i].expanded = expanded;
            }
        }

        setTableUpdateData(newData);
    };

    const saveRole = (role) => {
        if ( role && role.active === 'YES') role.active=true;
        if ( role && role.active === 'NO') role.active=false;
        if ( role && role.admin === 'YES') role.admin=true;
        if ( role && role.admin === 'NO') role.admin=false;

        let ucCodes=[];
        for (let i=0; i<tableUpdateData.length; i++){
            if ( tableUpdateData[i].code && tableUpdateData[i].checked===1 ) {
                ucCodes.push({code:tableUpdateData[i].code, execute:false});
            } if ( tableUpdateData[i].code && tableUpdateData[i].checked===2 ) {
                ucCodes.push({code:tableUpdateData[i].code, execute:true});
            }
        }

        role = {...role, useCases:ucCodes}
        const action = (role && role.roleId) ? updateRole : createRole;
        action(role).then((prod) => {
            setIsLoading(true);
            setIsFetchedRole(false);
            setSaving(false);
            closeRoleUpdate();
        }).catch((error) => {
            console.log('saveRole error', error);
            setSnackbarOpen(true);
            setSaving(false);
            setError(getErrorMessage(error, t));
        });
    };

    const [updateRoleConfigModel, setUpdateRoleConfigModel] = useState({
        open: false,
        role: {},
        isNew: true
    });

    const [detailRoleConfigModel, setDetailRoleConfigModel] = useState({
        open: false,
        role: {}
    });

    const searchMenuItemClick = (userId, roleId) => {
        addUserToRole(userId, roleId);
        setIsFetchedRole(false);
        setIsFetchedAllUsers(false);
    };

    const handleRemoveUser = (userId, roleId) => {
        removeUserToRole(userId, roleId);
        setIsFetchedRole(false);
        setIsFetchedAllUsers(false);
    }
    
    return (
        <PerfectScrollbar
            options={{
                suppressScrollX: true,
                minScrollbarLength: 50,
            }}
        >
            <div className={classes.container}>

                <ToolBar
                    userUseCases={userUseCases}
                    handleRoleUpdate={handleRoleUpdate}

                    selectedRoleName={selectedRoleName}
                    handleSelectedRoleName={handleSelectedRoleName}
                    selectedUCName={selectedUCName}
                    handleSelectedUCName={handleSelectedUCName}
                    selectedStatus={selectedStatus}
                    handleSelectStatus={handleSelectStatus}
                    selectedAdminRole={selectedAdminRole}
                    handleSelectAdminRole={handleSelectAdminRole}
                />
                <Grid container>
                    <Grid item sm={11}>
                    </Grid>
                    <Grid item sm={1}>
                        <ToggleButton
                            style={{width: '30px', height: '30px'}}
                            value="check"
                            selected={table}
                            onChange={() => {
                                setTable(!table);
                            }}
                        >
                            <TocIcon/>
                        </ToggleButton>
                    </Grid>
                </Grid>
                <RoleList
                    refresh={() => {
                        setIsFetchedRole(false);
                    }}
                    table={table}
                    save={saveRole}
                    userUseCases={userUseCases}
                    role={filteredRole}
                    handleRoleUpdate={handleRoleUpdate}
                    handleRoleDetail={handleRoleDetail}
                    handleActivate={handleActivate}
                    isLoading={isFetchingAllList}
                    setSnackbarOpen={setSnackbarOpen}
                    setSnackbarError={setError}
                    confirmModalOpened={confirmModalOpened}
                    setConfirmModalOpened={setConfirmModalOpened}
                />

            </div>

            <RoleUpdate 
                userUseCases={userUseCases}
                useCases={tableUpdateData}
                rowClickHandel={rowClickHandel}
                rowSetExpanded={rowSetExpanded}
                headerChecked={headerChecked}
                headerClickHandel={headerClickHandel}
                saveRole={saveRole}
                roles={roles}
                readOnly={false}
                roleConfigModel={updateRoleConfigModel}
                connect
                parkingUsers={[]}
                setIsFetchedParkings={true}
                handleClose={closeRoleUpdate}
                error={error}
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                setError={setError}
                saving={saving}
                setSaving={setSaving}
            />

            <RoleDetail 
                userUseCases={userUseCases}
                useCases={tableDetailData}
                roles={roles}
                readOnly={true}
                roleConfigModel={detailRoleConfigModel}
                connect
                parkingUsers={[]}
                setIsFetchedParkings={true}
                mode={mode}
                handleClose={closeRoleDetail}
                handleNext={handleNext}
                handleBack={handleBack}
                handleSearch={handleSearch}
                searchMenuItemClick={searchMenuItemClick}
                handleRemoveUser={handleRemoveUser}
                allUsers={userData}
            />

        </PerfectScrollbar>
    );
}

RoleAdministration.propTypes = {};

const STEP_ONE = 'STEP_1', STEP_TWO = 'STEP_2';

const mapStateToProps = (store) => ({
    user: store.authData.user,
    userUseCases: store.authData.userUseCases,
    roles: store.rolesData.roles,
    isFetchingAllList: store.rolesData.isFetchingAllList,
    useCases: store.useCasesData.useCases,
    allUsers: store.usersData.parkingUsers,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllRoles,
    createRole,
    updateRole,
    activeRole,
    addUserToRole,
    removeUserToRole,
    fetchAllAdminUseCases,
    fetchAllUsers
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RoleAdministration);
