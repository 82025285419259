import React from 'react';
import {useTranslation} from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import {isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import Add from "@mui/icons-material/Add";
import Autocomplete from "@mui/lab/Autocomplete";
import {Chip} from "@mui/material";
import { UC0112 } from '../../../utils/constants';


const useStyles = makeStyles((theme) => ({
    toolbar: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(2, 1, 1, 2),
    },
    toolbarInner1: {
        width: '100%',
        border: '1px solid #E0E0E0',
        marginLeft: 'auto',
        marginRight: 'auto',
        boxShadow: theme.palette.shadow.main,
        padding: theme.spacing(2, 1, 1, 2),

    },
    addButton: {
        backgroundColor: theme.palette.base[100],
        marginLeft: theme.spacing(10),
        padding: theme.spacing(2.5, 2.5, 2.5, 2.5),
    },
    addIcon: {
        width: 30,
        height: 30,
    },
}));

function ToolBar(props) {
    const classes = useStyles();

    const {
        userUseCases,
        selectedTenantName,
        handleSelectedTenantName,
        selectedStatus,
        handleSelectStatus,
        handleTenantUpdate
    } = props;

    const {t} = useTranslation();

    const statuses = [
        {
            value: 'ACTIVE',
            label: t('ACTIVE'),
            key: 'ACTIVE'
        },
        {
            value: 'INACTIVE',
            label: t('INACTIVE'),
            key: 'INACTIVE'
        },
    ]

    return (
        <div className={classes.toolbar}>
            <div className={classes.toolbarInner1}>
                <form autoComplete='off'>
                    <Box mb={2} height="100%" width="100%">
                        <Grid container spacing={2} style={{height: '100%', width: '100%'}}>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    variant="standard"
                                    type="search"
                                    label={t('TENANT_NAME')}
                                    fullWidth
                                    value={selectedTenantName || ''}
                                    onChange={handleSelectedTenantName} />

                            </Grid>

                            <Grid item sm={3} xs={12}>

                                <Autocomplete
                                    multiple
                                    value={selectedStatus}
                                    onChange={(event, newValue) => {
                                        handleSelectStatus(newValue);
                                    }}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    filterSelectedOptions
                                    id="statuses"
                                    options={statuses}
                                    getOptionLabel={(option) => {
                                        return option.label;
                                    }}
                                    style={{width: '100%'}}
                                    renderInput={(params) => (
                                        <TextField variant="standard" {...params} label={t('STATE')} fullWidth />
                                    )}
                                    renderTags={(value, getTagProps) => (
                                        value.map((option, index) => {
                                                return <Chip key={index}
                                                    style={{
                                                        backgroundColor: option.color,
                                                        color: 'black'
                                                    }}
                                                    variant="outlined"
                                                    label={option.label}
                                                    {...getTagProps({index})}
                                                />
                                            }
                                        )
                                    )
                                    }
                                />
                            </Grid>

                            <Grid item sm={4} xs={0}></Grid>

                            <Grid item sm={2} xs={12}>
                                {isComponentVisibleForUser(UC0112, userUseCases) &&
                                <IconButton
                                    className={classes.addButton}
                                    edge="start"
                                    color="inherit"
                                    aria-label="add role"
                                    onClick={() => {
                                        handleTenantUpdate({tenantId:0}, true);
                                    }}
                                    disabled={!isComponentEnabledForUser(UC0112, userUseCases)}
                                    size="large">
                                    <Add className={classes.addIcon}/>
                                </IconButton>
                                }
                            </Grid>

                        </Grid>
                    </Box>
                </form>
            </div>
        </div>
    );
}

ToolBar.propTypes = {};

ToolBar.defaultProps = {};

export default ToolBar;
