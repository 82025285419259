import httpService from './http.service';

const createTenant = (tenant) => httpService
    .post(`/tenant`, tenant)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const updateTenant = (tenant) => httpService
    .put(`/tenant`, tenant)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const activeTenant = (tenantId, active) => httpService
    .put(`/tenant/${tenantId}/?active=${active}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const getTenants = () => httpService
    .get(`/tenant/all`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


export default {
    getTenants,
    createTenant,
    updateTenant,
    activeTenant
};