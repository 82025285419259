import './App.css';
import { makeStyles } from '@mui/styles';
import { TopBar } from './components/layout';
import Reservation from './containers/reservation'
import MobileManagement from './containers/mobile'
import Administration from './containers/administration';
import RoleAdminitration from './containers/administration/RoleAdministration'
import UserAdminitration from './containers/administration/UserAdministration'
import TenantAdministration from './containers/administration/TenantAdministration'
import PlaceAdministration from './containers/administration/PlaceAdministration';
import StandAdministration from './containers/administration/StandAdministration';
import {Navigate, Routes, Route} from 'react-router-dom';
import BikeAdministration from './containers/administration/BikeAdministration';

const useStyles = makeStyles((theme) => ({
  root: {
      display: 'flex',
      color: theme.palette.base[800],
      height: '100%',
  },
  content: {
      flexGrow: 1,
      height: '100%',
      overflow: 'auto',
  },
  appBarSpacer: {
    height: 120,
    ...theme.mixins.toolbar,
  },
  appContent: {
    height: 'calc(100% - 120px)',
  },
}));

function App() {
  const classes = useStyles();
  return (
    <div className="App">
      <div className={classes.root}>
      <TopBar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer}/>
        <div className={classes.appContent}>
          <Routes >
            <Route path={`/${process.env.REACT_APP_PATH}`} element={ <Navigate to={`/${process.env.REACT_APP_PATH}/reservation`} /> } />
            <Route exact path={'/'} element={<Reservation />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/reservation`} element={<Reservation />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/mobile`} element={<MobileManagement />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/administration`} element={<Administration />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/administration/rolelist`} element={<RoleAdminitration />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/administration/userlist`} element={<UserAdminitration />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/administration/tenantlist`} element={<TenantAdministration />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/administration/placelist`} element={<PlaceAdministration />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/administration/standlist`} element={<StandAdministration />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/administration/bikelist`} element={<BikeAdministration />} />
          </Routes>
        </div>
      </main>
      </div>
    </div>
  );
}

export default App;

