import types from '../actionTypes';
import bikeService from '../../services/bike.service';

export const fetchBikesByTenantId = (tenantId) => (dispatch, getState) => {
    if (getState().bikeData.isFetchingAllListByTenantId) {
        return Promise.reject();
    }

    dispatch({
        type: types.BIKE_FETCH_REQUEST_BY_TENANT,
    });

    return bikeService.getBikesByTenantId(tenantId)
        .then((bikes) => {
            dispatch({
                type: types.BIKE_FETCH_SUCCESS_BY_TENANT,
                payload: { bikes: bikes, tenantId: tenantId },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.BIKE_FETCH_FAIL_BY_TENANT,
                payload: { error },
            });

            throw error;
        });
};

export const createBike = (bike) => (dispatch, getState) => {
    if (getState().bikeData.isCreating) {
        return;
    }

    dispatch({
        type: types.BIKE_CREATE_REQUEST,
    });

    return bikeService.createBike(bike)
        .then((newBike) => {
            dispatch({
                type: types.BIKE_CREATE_SUCCESS,
                payload: {bike: newBike},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.BIKE_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateBike = (bike) => (dispatch, getState) => {
    if (getState().bikeData.isUpdating) {
        return;
    }

    dispatch({
        type: types.BIKE_UPDATE_REQUEST,
    });

    return bikeService.updateBike(bike)
        .then((newBike) => {
            dispatch({
                type: types.BIKE_UPDATE_SUCCESS,
                payload: {bike: newBike},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.BIKE_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const actiavateBike = (bikeId, active) => (dispatch, getState) => {
    if (getState().bikeData.isUpdating) {
        return;
    }

    dispatch({
        type: types.BIKE_ACTIVE_REQUEST,
    });

    return bikeService.activeBike(bikeId, active)
        .then((newBike) => {
            dispatch({
                type: types.BIKE_ACTIVE_SUCCESS,
                payload: {bike: newBike},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.BIKE_ACTIVE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchBikes = () => (dispatch, getState) => {
    if (getState().bikeData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.BIKE_FETCH_REQUEST,
    });

    return bikeService.getBikes()
        .then((bikes) => {
            dispatch({
                type: types.BIKE_FETCH_SUCCESS,
                payload: { bikes },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.BIKE_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const fetchAvailableBikes = (dateFrom, dateTo, reservationId) => (dispatch, getState) => {
    if (getState().bikeData.isFetchingAvailableList) {
        return Promise.reject();
    }

    dispatch({
        type: types.BIKE_AVAILABLE_FETCH_REQUEST,
    });

    return bikeService.getAvailableBikes(dateFrom, dateTo, reservationId)
        .then((bikes) => {
            dispatch({
                type: types.BIKE_AVAILABLE_FETCH_SUCCESS,
                payload: { bikes, dateFrom, dateTo },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.BIKE_AVAILABLE_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};
