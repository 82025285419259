import React, {useState,} from 'react';
import {useTranslation} from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { CustomTable, Loading } from '../../../components/elements';
import { UC0133, UC0134, UC0135 } from '../../../utils/constants';
import { isComponentEnabledForUser, isComponentVisibleForUser } from '../../../utils';
import { BottomNavigation, BottomNavigationAction, IconButton, Tooltip, Typography } from '@mui/material';
import {ActionButton} from "../../../components/common";
import CertificateIcon from '@mui/icons-material/VerifiedUser';
import SecurityIcon from '@mui/icons-material/Security';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from "@mui/icons-material/Cancel";
import PlusIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import PDSDialog from '../../../components/common/PDSDialog';


const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: 100,
        paddingTop: 20,
    },
    standCard: {
        width: 384,
        height: 200,
        margin: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: theme.palette.base[100],
        borderStyle: 'solid',
        borderWidth: '2px',
        cursor: 'pointer',
        borderColor: theme.palette.base[200],
        boxShadow: '10px 10px 8px #EEEEEE',
    },
    actions: {
        display: 'flex',
        width: '75%',
        justifyContent: 'space-around',
    },
    green: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    green2: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.base.white,
        minWidth: '30%',
        marginRight: theme.spacing(2.5),
    },
    orange: {
        backgroundColor: theme.palette.primary.yellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red: {
        backgroundColor: theme.palette.primary.red,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red2: {
        backgroundColor: theme.palette.primary.red,
        color: theme.palette.base.white,
        minWidth: '30%',
        marginRight: theme.spacing(2.5),
    },
    white: {
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.base.black,
        minWidth: '50%',
    },
    brown: {
        backgroundColor: theme.palette.secondary.darkYellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue2: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.base.white,
        minWidth: '30%',
        marginRight: theme.spacing(2.5),
    },
    title: {
        color: theme.palette.base[700],
        '& .MuiTypography-root': {
            lineHeight: '25px',
        },
    },
    label: {
        color: theme.palette.base[400],
        fontSize: 16,
        textAlign: 'center',
        marginLeft: 20
    },
    value: {
        color: theme.palette.base[700],
        fontSize: 12,
        minHeight: 35,
        textAlign: 'center',
        marginLeft: 0
    },
    label2: {
        color: theme.palette.base[400],
        fontSize: 16,
        textAlign: 'center',
    },
    value2: {
        color: theme.palette.base[700],
        fontSize: 24,
        minHeight: 35,
        textAlign: 'center',
    },
    row1: {
        width: 380,
        height: 90,
        fontSize: 14,
        padding: 5,
        marginTop: -10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    row2: {
        width: 380,
        height: 20,
        fontSize: 22,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        marginTop: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    rowColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    rowColumn2: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 25,
    },
    attachment: {
        // height: 30,
        width: 120,
      },
}));

const tableOptions = {
    headerStyle: {
        padding: '15px 8px 15px 34px',
        lineHeight: '12px',
        color: '#1B1B28',
        textAlign: 'center',
    },
    paging: false
};
const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
};

function StandList(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {
        userUseCases,
        stands,
        isLoading,
        table,
    } = props;

    const [standToActive, setStandToActive] = useState();
    const [actionInProgress, setActionInProgress] = useState(false);


    const renderStandState = (param) => {
        if (!param) {
            return <Tooltip title={t('STAND_INACTIVE')}><SecurityIcon color="error" style={{ fontSize: 40 }}/></Tooltip>
        } else {
            if ( param.state === 'A' )
                return <Tooltip title={t('STAND_ACTIVE')}><CertificateIcon color="primary" style={{ fontSize: 40 }}/></Tooltip>
            else                
                return <Tooltip title={t('STAND_INACTIVE')}><SecurityIcon color="error" style={{ fontSize: 40 }}/></Tooltip>
        }
    }

    const handleStandDetail = () => {
        // do nothing
    }
    const handleStandUpdate = () => {
        // do nothing
    }

    const columns = [
        {   title: t('STATE'),
            field: 'state',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'center', paddingLeft: 5
            },
            render: (rowData) => (
                renderStandState(rowData)
            )
        },
        {
            title: t('STAND_NAME'),
            field: 'name',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.name
            )
        },
        {
            title: t('STAND_COUNT'),
            field: 'standCount',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.standCount
            )
        },
        {
            title: '',
            field: 'action',
            sorting: false,
            cellStyle: {
                ...cellStyle,
                padding: '12px 4px',
            },
            headerStyle: {
                padding: '18px 34px',
            },
            render: (rowData) => (
                <div className={classes.actions}>
                    {isComponentVisibleForUser(UC0134, userUseCases) &&
                        <ActionButton 
                            handleClick={() => {handleStandDetail(rowData)}}
                            disabled={!isComponentEnabledForUser(UC0134, userUseCases) || actionInProgress || true}
                            className={classes.green2}
                            action="detail"
                            title={t('SHOW_STAND_DETAIL')}
                        />
                    }
                    {isComponentVisibleForUser(UC0133, userUseCases) &&
                        <ActionButton 
                            handleClick={() => {handleStandUpdate(rowData, false)}}
                            disabled={!isComponentEnabledForUser(UC0133, userUseCases) || actionInProgress || true}
                            className={classes.red2}
                            action="edit"
                        />
                    }
                    {isComponentVisibleForUser(UC0135, userUseCases) &&
                        <ActionButton 
                            handleClick={() => {
                                setStandToActive(rowData);
                                // setConfirmModalOpened(true);
                            }}
                            disabled={!isComponentEnabledForUser(UC0135, userUseCases) || actionInProgress || true}
                            className={classes.blue2}
                            action={rowData.state==='A'?t('DEACTIVATE'):t('ACTIVATE')}
                        />
                    }
                </div>
            ),
        },

    ];

    return (
        <div className={classes.container}>
            {/* {confirmModalOpened
            && (    
                <PDSDialog
                    open={confirmModalOpened}
                    title={`${standToActive.state==='A'?t('STAND_INACTIVATION'):t('STAND_ACTIVATION')}`}
                    onClose={() => {
                        setConfirmModalOpened(false);
                        setStandToActive(null);
                    }}
                    size="sm"
                    headerColor='#5985EE'
                    height={300}
                    onSubmit={ () => {handleActivate(standToActive);}}
                    actionType={standToActive.state==='A'?'roleDeactivate':'roleActivate'}
                >
                    
                    <div className={classes.content}>
                    {standToActive.state==='A'?t('CONFIRM_STAND_ACTIVE', {standName: standToActive.name}):t('CONFIRM_STAND_DEACTIVE', {standName: standToActive.name})}
                    </div>
                </PDSDialog>
            )} */}
            {!isLoading ? table ? <CustomTable
                    title=""
                    columns={columns}
                    data={stands}
                    options={tableOptions}
                    isLoading={isLoading}
                    loadAllData={false}
                />: 
                stands.map((standItem, index) => (
                    <div className={classes.standCard} key={index}>
                        <div className={classes.row1}>
                            <div className={classes.state}>
                                {renderStandState(standItem)}
                            </div>
                            <div className={classes.title}>
                                <Tooltip title={t('STAND_NAME')}>
                                    <Typography variant="h4">{standItem.name}</Typography>
                                </Tooltip>
                            </div>
                            <div className={classes.actionIcon}>
                                    {isComponentVisibleForUser(UC0133, userUseCases) &&
                                    <Tooltip title={t('EDIT')}>
                                        <IconButton
                                            onClick={() => {
                                                    handleStandUpdate(standItem, false)
                                                }}
                                            disabled={!isComponentEnabledForUser(UC0133, userUseCases)}
                                            size="large">
                                            <EditIcon color="disable" style={{ fontSize: 30 }}/>
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                        </div>
                        <div className={classes.row2}>
                            <div className={classes.rowColumn}>
                                <div className={classes.rowColumn}>
                                </div>
                                <div className={classes.rowColumn2}>
                                    <div className={classes.label2} >{t('STAND_COUNT')}</div>
                                    <div className={classes.value2} >{standItem.standCount}</div>
                                </div>
                                <div className={classes.rowColumn}>
                            </div>                            </div>
                            <div className={classes.rowColumn2}>
                            </div>
                            <div className={classes.rowColumn}>
                            </div>
                        </div>
                        <BottomNavigation
                            className={classes.root}
                        >
                            {isComponentVisibleForUser(UC0134, userUseCases) &&
                                <Tooltip title={t('SHOW_STAND_DETAIL')}>
                                    <BottomNavigationAction onClick={() => {handleStandDetail(standItem)}}
                                        disabled={actionInProgress || !isComponentEnabledForUser(UC0134, userUseCases)}
                                        className={classes.blue}
                                        label="Detail"
                                        icon={<InfoIcon className={classes.actionIcon}/>}
                                    />
                                </Tooltip>
                            }
                            {isComponentVisibleForUser(UC0135, userUseCases) &&
                                <Tooltip title={standItem.state==='A'?t('SHOW_STAND_DEACTIVE'):t('SHOW_STAND_ACTIVE')}>
                                    <BottomNavigationAction onClick={() => {
                                        setStandToActive(standItem);
                                        //setConfirmModalOpened(true);
                                    }}
                                        disabled={actionInProgress || !isComponentEnabledForUser(UC0135, userUseCases)}
                                        className={classes.orange}
                                        label="Active"
                                        icon={standItem.state==='A'?<CancelIcon className={classes.actionIcon}/>:<PlusIcon className={classes.actionIcon}/>}
                                    />
                                </Tooltip>
                            }
                        </BottomNavigation>
                    </div>
                )) : <Loading />
            }
        </div>
    );
}

StandList.propTypes = {};

StandList.defaultProps = {};

export default StandList;