import React, {useState,} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import { makeStyles } from '@mui/styles';
import {CustomTable, Loading} from '../../../components/elements';
import {connect} from 'react-redux';
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import BottomNavigation from "@mui/material/BottomNavigation";
import {ActionButton} from "../../../components/common";
import {isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from '@mui/icons-material/Info';
import CertificateIcon from '@mui/icons-material/VerifiedUser';
import SecurityIcon from '@mui/icons-material/Security';
import Tooltip from '@mui/material/Tooltip';
import IconButton from "@mui/material/IconButton";
import PlusIcon from "@mui/icons-material/Add";
import PDSDialog from '../../../components/common/PDSDialog';
import { UC0163, UC0164, UC0165 } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: 100,
        paddingTop: 20,
    },
    navIcon: {
        fontSize: 11,
    },
    actionIcon: {
        fontSize: 20,
    },
    actions: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
    },
    parkingCard: {
        width: 384,
        height: 200,
        margin: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: theme.palette.base[100],
        borderStyle: 'solid',
        borderWidth: '2px',
        cursor: 'pointer',
        borderColor: theme.palette.base[200],
        boxShadow: '10px 10px 8px #EEEEEE',
    },
    yellowPaper: {
        background: theme.palette.secondary.lightYellow,

    },
    expiringPos: {
        backgroundColor: '#e5cfcc',
    },
    grey: {
        backgroundColor: theme.palette.base[100],
    },
    disabled: {
        backgroundImage: 'linear-gradient(135deg, #FFFFFF00 25%, #FFFFFF3F 25%, #FFFFFF3F 50%, #FFFFFF00 50%, #FFFFFF00 75%, #FFFFFF3F 75%, #FFFFFF3F 100%)',
        backgroundSize: '20px 20px',
    },
    root: {
        width: 380,
        height: 40,
        background: theme.palette.base[100],
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    state: {
        display: 'flex',
    },
    row1: {
        width: 380,
        height: 90,
        fontSize: 14,
        padding: 5,
        marginTop: -10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    row2: {
        width: 380,
        height: 90,
        fontSize: 12,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        marginTop: -30,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    row3: {
        width: 380,
        height: 20,
        fontSize: 14,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 20,
        marginTop: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    rowColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    rowColumn2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'right',
    },
    green: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    green2: {
        backgroundColor: theme.palette.primary.main,
        minWidth: '30%',
        color: theme.palette.base.white,
        marginRight: theme.spacing(2.5),
    },
    orange: {
        backgroundColor: theme.palette.primary.yellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red: {
        backgroundColor: theme.palette.primary.red,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red2: {
        backgroundColor: theme.palette.primary.red,
        color: theme.palette.base.white,
        minWidth: '30%',
        marginRight: theme.spacing(2.5),
    },
    white: {
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.base.black,
        minWidth: '50%',
    },
    brown: {
        backgroundColor: theme.palette.secondary.darkYellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue2: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.base.white,
        minWidth: '30%',
        marginRight: theme.spacing(2.5),
    },
    title: {
        color: theme.palette.base[700],
        '& .MuiTypography-root': {
            lineHeight: '25px',
        },
    },
    label: {
        color: theme.palette.base[400],
        fontSize: 18,
        textAlign: 'center',
        marginLeft: 20
    },
    value: {
        color: theme.palette.base[700],
        fontSize: 24,
        minHeight: 25,
        textAlign: 'center',
        marginLeft: 20
    },
    label2: {
        color: theme.palette.base[400],
        fontSize: 12,
        textAlign: 'right',
    },
    value2: {
        color: theme.palette.base[400],
        fontSize: 12,
        textAlign: 'right',
    },
}));

const tableOptions = {
    headerStyle: {
        padding: '15px 8px 15px 34px',
        lineHeight: '12px',
        color: '#1B1B28',
        textAlign: 'center',
    },
    paging: false
};
const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
};


function UserList(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {
        role,
        userUseCases,
        isLoading,
        handleUserDetail,
        table,
        handleActivate,
        confirmModalOpened,
        setConfirmModalOpened,
    } = props;
    
    const [userToActive, setUserToActive] = useState();
    const [actionInProgress, setActionInProgress] = useState(false);

    const renderUserState = (param) => {
        if (!param) {
            return <Tooltip title={t('USER_INACTIVE')}><SecurityIcon color="error" style={{ fontSize: 40 }}/></Tooltip>
        } else {
            if ( param.active )
                return <Tooltip title={t('USER_ACTIVE')}><CertificateIcon color="primary" style={{ fontSize: 40 }}/></Tooltip>
            else                
                return <Tooltip title={t('USER_INACTIVE')}><SecurityIcon color="error" style={{ fontSize: 40 }}/></Tooltip>
        }
    }

    const renderRoleAdmin = (param) => {
        if (param && param.admin) {
            return <Tooltip title={t('USER_ADMIN')}><SecurityIcon color="secondary" style={{ fontSize: 40 }}/></Tooltip>
        }
    }

    const renderRoleAdminTable = (param) => {
        if ( param && param.admin) {
            return <>{t('YES')}</>
        } else {
            return <>{t('NO')}</>
        }
    }

    const renderRoleName = (param) => {
        if ( param && param.roles) {
            let roles='';
            for (let i = 0; i < param.roles.length; i++) {
                if ( roles === '') {
                    roles=param.roles[i].name;
                } else {
                    roles+=','+param.roles[i].name;
                }
            }
            return <>{roles}</>
        } 
    }

    const columns = [
        {   title: t('STATE'),
            field: 'active',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'center', paddingLeft: 5
            },
            render: (rowData) => (
                renderUserState(rowData)
            )
        },
        {
            title: t('FIRST_NAME'),
            field: 'firstName',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.firstName
            )
        },
        {
            title: t('LAST_NAME'),
            field: 'lastName',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.lastName
            )
        },
        {
            title: t('LOGIN'),
            field: 'login',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },

            render: (rowData) => (
                rowData.userName
            ),
        },
        {
            title: t('EMAIL'),
            field: 'email',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.email
            ),
        },
        {
            title: t('ROLE_ADMIN'),
            field: 'admin',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                renderRoleAdminTable(rowData)
            ),
        },
        {
            title: t('ROLE'),
            field: 'role',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                renderRoleName(rowData)
            ),
        },
        {
            title: '',
            field: 'action',
            sorting: false,
            cellStyle: {
                ...cellStyle,
                padding: '12px 4px',
            },
            headerStyle: {
                padding: '18px 34px',
            },
            render: (rowData) => (
                <div className={classes.actions}>
                    {isComponentVisibleForUser(UC0164, userUseCases) &&
                        <Tooltip title={t('SHOW_USER_DETAIL')}>
                            <ActionButton 
                                handleClick={() => {handleUserDetail(rowData, false, true)}}
                                disabled={!isComponentEnabledForUser(UC0164, userUseCases) || actionInProgress}
                                className={classes.green2}
                                action="detail"
                            />
                        </Tooltip>
                    }
                    {isComponentVisibleForUser(UC0163, userUseCases) &&
                        <Tooltip title={t('SHOW_USER_EDIT')}>
                            <ActionButton 
                                handleClick={() => {handleUserDetail(rowData, false, false)}}
                                disabled={!isComponentEnabledForUser(UC0163, userUseCases) || actionInProgress}
                                className={classes.red2}
                                action="edit"
                            />
                        </Tooltip>
                    }
                    {isComponentVisibleForUser(UC0165, userUseCases) &&
                        <Tooltip title={rowData.active?t('SHOW_USER_DEACTIVE'):t('SHOW_USER_ACTIVE')}>
                            <ActionButton 
                                handleClick={() => {
                                    setUserToActive(rowData);
                                    setConfirmModalOpened(true);
                                }}
                                disabled={!isComponentEnabledForUser(UC0165, userUseCases) || actionInProgress}
                                className={classes.blue2}
                                action={rowData.active?t('DEACTIVATE'):t('ACTIVATE')}
                            />
                        </Tooltip>
                    }
                </div>
            ),
        },

    ];

    return (
        <div className={classes.container}>
            {confirmModalOpened
                && (    
                <PDSDialog
                    open={confirmModalOpened}
                    title={`${userToActive.active?t('USER_INACTIVATION'):t('USER_ACTIVATION')}`}
                    onClose={() => {
                        setConfirmModalOpened(false);
                        setUserToActive(null);
                    }}
                    size="sm"
                    headerColor='#5985EE'
                    height={300}
                    onSubmit={ () => {handleActivate(userToActive);}}
                    actionType={userToActive.active?'userDeactivate':'roleActivate'}
                >
                    
                    <div className={classes.content}>
                    {userToActive.active?t('CONFIRM_USER_DEACTIVE', {userName: userToActive.userName}):t('CONFIRM_USER_ACTIVE', {userName: userToActive.userName})}
                    </div>
                </PDSDialog>
            )}            
            {!isLoading ?
                table ? <CustomTable
                    title=""
                    columns={columns}
                    data={role}
                    options={tableOptions}
                    isLoading={isLoading}
                    loadAllData={false}
                />:
                role.map((userItem, index) => (
                    <div className={classes.parkingCard}>

                        <div className={classes.row1}>
                            <div className={classes.state}>
                                {renderUserState(userItem)}
                            </div>
                            <div className={classes.title}>
                                <Tooltip title={t('NAME')}>
                                    <Typography variant="h4">{(userItem.firstName && userItem.firstName.length>0) || (userItem.lastName && userItem.lastName)
                                        ? (userItem.firstName&&userItem.firstName.length>0?userItem.firstName+' ':'') + ( userItem.lastName && userItem.lastName.length>0?userItem.lastName:''):userItem.userName}</Typography>
                                </Tooltip>
                            </div>
                            <div className={classes.actionIcon}>
                                {isComponentVisibleForUser(UC0163, userUseCases) &&
                                <Tooltip title={t('EDIT')}>
                                    <IconButton
                                        onClick={() => {
                                                handleUserDetail(userItem, false, false)
                                            }}
                                        disabled={!isComponentEnabledForUser(UC0163, userUseCases)}
                                        size="large">
                                        <EditIcon color="disable" style={{ fontSize: 30 }}/>
                                    </IconButton>
                                </Tooltip>
                                }
                            </div>
                        </div>
                        <div className={classes.row2}>
                            <div className={classes.title}>
                                <Tooltip title={t('EMAIL')}>
                                    <Typography variant="h12">{userItem.email}</Typography>
                                </Tooltip>
                            </div>
                        </div>
                        <div className={classes.row3}>

                            <div className={classes.rowColumn}>
                                <Typography variant="h6">
                                    {renderRoleAdmin(userItem)}
                                </Typography>
                            </div>
                            <div className={classes.rowColumn2}>
                                <div className={classes.label2}>{userItem.tenantName}</div>
                                <div className={classes.value2}>{userItem.tenantAddress}</div>
                            </div>
                        </div>
                        <BottomNavigation
                            className={classes.root}
                        >
                            {isComponentVisibleForUser(UC0164, userUseCases) &&
                                <Tooltip title={t('SHOW_USER_DETAIL')}>
                                    <BottomNavigationAction onClick={() => {handleUserDetail(userItem, false, true)}}
                                        disabled={actionInProgress || !isComponentEnabledForUser(UC0164, userUseCases)}
                                        className={classes.blue}
                                        label="Detail"
                                        icon={<InfoIcon className={classes.actionIcon}/>}
                                    />
                                </Tooltip>
                            }
                            {isComponentVisibleForUser(UC0165, userUseCases) &&
                                <Tooltip title={userItem.active?t('SHOW_USER_DEACTIVE'):t('SHOW_USER_ACTIVE')}>
                                    <BottomNavigationAction onClick={() => {
                                        setUserToActive(userItem);
                                        setConfirmModalOpened(true);
                                    }}
                                        disabled={actionInProgress || !isComponentEnabledForUser(UC0165, userUseCases)}
                                        className={classes.orange}
                                        label="Active"
                                        icon={userItem.active?<CancelIcon className={classes.actionIcon}/>:<PlusIcon className={classes.actionIcon}/>}
                                    />
                                </Tooltip>
                            }
                        </BottomNavigation>
                    </div>
                ))
                :
                <Loading/>
            }
        </div>
    );
}
UserList.propTypes = {
    parkings: PropTypes.array.isRequired,
};

UserList.defaultProps = {};
const mapStateToProps = (store) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserList);