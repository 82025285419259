export default {
    // Set user info
    SET_USER_INFO: 'SET_USER_INFO',
    // Logout
    LOG_OUT: 'LOG_OUT',

    // Fetch code list
    ALL_CODE_FETCH_REQUEST: 'ALL_CODE_FETCH_REQUEST',
    ALL_CODE_FETCH_SUCCESS: 'ALL_CODE_FETCH_SUCCESS',
    ALL_CODE_FETCH_FAIL: 'ALL_CODE_FETCH_FAIL',
   
    // use cases
    USECASE_FETCH_REQUEST: 'USECASE_FETCH_REQUEST',
    USECASE_FETCH_SUCCESS: 'USECASE_FETCH_SUCCESS',
    USECASE_FETCH_FAIL: 'USECASE_FETCH_FAIL',

    // reservation
    RESERVATION_FETCH_REQUEST: 'RESERVATION_FETCH_REQUEST',
    RESERVATION_FETCH_SUCCESS: 'RESERVATION_FETCH_SUCCESS',
    RESERVATION_FETCH_FAIL: 'RESERVATION_FETCH_FAIL',

    RESERVATION_CREATE_REQUEST: 'RESERVATION_CREATE_REQUEST',
    RESERVATION_CREATE_SUCCESS: 'RESERVATION_CREATE_SUCCESS',
    RESERVATION_CREATE_FAIL: 'RESERVATION_CREATE_FAIL',

    RESERVATION_UPDATE_REQUEST: 'RESERVATION_UPDATE_REQUEST',
    RESERVATION_UPDATE_SUCCESS: 'RESERVATION_UPDATE_SUCCESS',
    RESERVATION_UPDATE_FAIL: 'RESERVATION_UPDATE_FAIL',

    RESERVATION_DELETE_REQUEST: 'RESERVATION_DELETE_REQUEST',
    RESERVATION_DELETE_SUCCESS: 'RESERVATION_DELETE_SUCCESS',
    RESERVATION_DELETE_FAIL: 'RESERVATION_DELETE_FAIL',

    // bike
    BIKE_FETCH_REQUEST: 'BIKE_FETCH_REQUEST',
    BIKE_FETCH_SUCCESS: 'BIKE_FETCH_SUCCESS',
    BIKE_FETCH_FAIL: 'BIKE_FETCH_FAIL',

    BIKE_FETCH_REQUEST_BY_TENANT: 'BIKE_FETCH_REQUEST_BY_TENANT',
    BIKE_FETCH_SUCCESS_BY_TENANT: 'BIKE_FETCH_SUCCESS_BY_TENANT',
    BIKE_FETCH_FAIL_BY_TENANT: 'BIKE_FETCH_FAIL_BY_TENANT',

    BIKE_AVAILABLE_FETCH_REQUEST: 'BIKE_AVAILABLE_FETCH_REQUEST',
    BIKE_AVAILABLE_FETCH_SUCCESS: 'BIKE_AVAILABLE_FETCH_SUCCESS',
    BIKE_AVAILABLE_FETCH_FAIL: 'BIKE_AVAILABLE_FETCH_FAIL',

    BIKE_CREATE_REQUEST: 'BIKE_CREATE_REQUEST',
    BIKE_CREATE_SUCCESS: 'BIKE_CREATE_SUCCESS',
    BIKE_CREATE_FAIL: 'BIKE_CREATE_FAIL',

    BIKE_UPDATE_REQUEST: 'BIKE_UPDATE_REQUEST',
    BIKE_UPDATE_SUCCESS: 'BIKE_UPDATE_SUCCESS',
    BIKE_UPDATE_FAIL: 'BIKE_UPDATE_FAIL',

    BIKE_ACTIVE_REQUEST: 'BIKE_ACTIVE_REQUEST',
    BIKE_ACTIVE_SUCCESS: 'BIKE_ACTIVE_SUCCESS',
    BIKE_ACTIVE_FAIL: 'BIKE_ACTIVE_FAIL',


    // place
    PLACE_FETCH_REQUEST: 'PLACE_FETCH_REQUEST',
    PLACE_FETCH_SUCCESS: 'PLACE_FETCH_SUCCESS',
    PLACE_FETCH_FAIL: 'PLACE_FETCH_FAIL',

    PLACE_CREATE_REQUEST: 'PLACE_CREATE_REQUEST',
    PLACE_CREATE_SUCCESS: 'PLACE_CREATE_SUCCESS',
    PLACE_CREATE_FAIL: 'PLACE_CREATE_FAIL',

    PLACE_UPDATE_REQUEST: 'PLACE_UPDATE_REQUEST',
    PLACE_UPDATE_SUCCESS: 'PLACE_UPDATE_SUCCESS',
    PLACE_UPDATE_FAIL: 'PLACE_UPDATE_FAIL',

    PLACE_ACTIVE_REQUEST: 'PLACE_ACTIVE_REQUEST',
    PLACE_ACTIVE_SUCCESS: 'PLACE_ACTIVE_SUCCESS',
    PLACE_ACTIVE_FAIL: 'PLACE_ACTIVE_FAIL',

    // code list
    CODELIST_FETCH_REQUEST: 'CODELIST_FETCH_REQUEST',
    CODELIST_FETCH_SUCCESS: 'CODELIST_FETCH_SUCCESS',
    CODELIST_FETCH_FAIL: 'CODELIST_FETCH_FAIL',

    // mobile device
    MOBILEDEVICE_FETCH_REQUEST: 'MOBILEDEVICE_FETCH_REQUEST',
    MOBILEDEVICE_FETCH_SUCCESS: 'MOBILEDEVICE_FETCH_SUCCESS',
    MOBILEDEVICE_FETCH_FAIL: 'MOBILEDEVICE_FETCH_FAIL',

    MOBILEDEVICE_UPDATE_REQUEST: 'MOBILEDEVICE_UPDATE_REQUEST',
    MOBILEDEVICE_UPDATE_SUCCESS: 'MOBILEDEVICE_UPDATE_SUCCESS',
    MOBILEDEVICE_UPDATE_FAIL: 'MOBILEDEVICE_UPDATE_FAIL',

    MOBILEDEVICE_DELETE_REQUEST: 'MOBILEDEVICE_DELETE_REQUEST',
    MOBILEDEVICE_DELETE_SUCCESS: 'MOBILEDEVICE_DELETE_SUCCESS',
    MOBILEDEVICE_DELETE_FAIL: 'MOBILEDEVICE_DELETE_FAIL',

    // users
    USERS_FETCH_REQUEST: 'USERS_FETCH_REQUEST',
    USERS_FETCH_SUCCESS: 'USERS_FETCH_SUCCESS',
    USERS_FETCH_FAIL: 'USERS_FETCH_FAIL',

    USERS_BY_ROLE_FETCH_REQUEST: 'USERS_BY_ROLE_FETCH_REQUEST',
    USERS_BY_ROLE_FETCH_SUCCESS: 'USERS_BY_ROLE_FETCH_SUCCESS',
    USERS_BY_ROLE_FETCH_FAIL: 'USERS_BY_ROLE_FETCH_FAIL',

    USER_CREATE_REQUEST: 'USER_CREATE_REQUEST',
    USER_CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
    USER_CREATE_FAIL: 'USER_CREATE_FAIL',
    
    USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
    USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    USER_UPDATE_FAIL: 'USER_UPDATE_FAIL',

    // roles
    ROLES_FETCH_REQUEST: ' ROLES_FETCH_REQUEST',
    ROLES_FETCH_SUCCESS: 'ROLES_FETCH_SUCCESS',
    ROLES_FETCH_FAIL: ' ROLES_FETCH_FAIL',

    ROLES_UPDATE_REQUEST: 'ROLES_UPDATE_REQUEST',
    ROLES_UPDATE_SUCCESS: 'ROLES_UPDATE_SUCCESS',
    ROLES_UPDATE_FAIL: 'ROLES_UPDATE_FAIL',

    ROLES_ACTIVE_REQUEST: 'ROLES_ACTIVE_REQUEST',
    ROLES_ACTIVE_SUCCESS: 'ROLES_ACTIVE_SUCCESS',
    ROLES_ACTIVE_FAIL: 'ROLES_ACTIVE_FAIL',

    ROLES_CREATE_REQUEST: 'ROLES_CREATE_REQUEST',
    ROLES_CREATE_SUCCESS: 'ROLES_CREATE_SUCCESS',
    ROLES_CREATE_FAIL: 'ROLES_CREATE_FAIL',

    ROLES_ADD_ROLE_REQUEST: 'ROLES_ADD_ROLE_REQUEST',
    ROLES_ADD_ROLE_SUCCESS: 'ROLES_ADD_ROLE_SUCCESS',
    ROLES_ADD_ROLE_FAIL: 'ROLES_ADD_ROLE_FAIL',

    ROLES_REMOVE_ROLE_REQUEST: 'ROLES_REMOVE_ROLE_REQUEST',
    ROLES_REMOVE_ROLE_SUCCESS: 'ROLES_REMOVE_ROLE_SUCCESS',
    ROLES_REMOVE_ROLE_FAIL: 'ROLES_REMOVE_ROLE_FAIL',

    // tenants
    TENANT_FETCH_REQUEST: 'TENANT_FETCH_REQUEST',
    TENANT_FETCH_SUCCESS: 'TENANT_FETCH_SUCCESS',
    TENANT_FETCH_FAIL: 'TENANT_FETCH_FAIL',

    TENANT_UPDATE_REQUEST: 'TENANT_UPDATE_REQUEST',
    TENANT_UPDATE_SUCCESS: 'TENANT_UPDATE_SUCCESS',
    TENANT_UPDATE_FAIL: 'TENANT_UPDATE_FAIL',

    TENANT_ACTIVE_REQUEST: 'TENANT_ACTIVE_REQUEST',
    TENANT_ACTIVE_SUCCESS: 'TENANT_ACTIVE_SUCCESS',
    TENANT_ACTIVE_FAIL: 'TENANT_ACTIVE_FAIL',

    TENANT_CREATE_REQUEST: 'TENANT_CREATE_REQUEST',
    TENANT_CREATE_SUCCESS: 'TENANT_CREATE_SUCCESS',
    TENANT_CREATE_FAIL: 'TENANT_CREATE_FAIL',

    // stand
    STAND_FETCH_REQUEST: 'STAND_FETCH_REQUEST',
    STAND_FETCH_SUCCESS: 'STAND_FETCH_SUCCESS',
    STAND_FETCH_FAIL: 'STAND_FETCH_FAIL',

    STAND_FETCH_REQUEST_BY_TENANT: 'STAND_FETCH_REQUEST_BY_TENANT',
    STAND_FETCH_SUCCESS_BY_TENANT: 'STAND_FETCH_SUCCESS_BY_TENANT',
    STAND_FETCH_FAIL_BY_TENANT: 'STAND_FETCH_FAIL_BY_TENANT',

    STAND_OPEN_REQUEST: 'STAND_OPEN_REQUEST',
    STAND_OPEN_SUCCESS: 'STAND_OPEN_SUCCESS',
    STAND_OPEN_FAIL: 'STAND_OPEN_FAIL',

    // STAND_CREATE_REQUEST: 'STAND_CREATE_REQUEST',
    // STAND_CREATE_SUCCESS: 'STAND_CREATE_SUCCESS',
    // STAND_CREATE_FAIL: 'STAND_CREATE_FAIL',

    // STAND_UPDATE_REQUEST: 'STAND_UPDATE_REQUEST',
    // STAND_UPDATE_SUCCESS: 'STAND_UPDATE_SUCCESS',
    // STAND_UPDATE_FAIL: 'STAND_UPDATE_FAIL',

    // STAND_ACTIVE_REQUEST: 'STAND_ACTIVE_REQUEST',
    // STAND_ACTIVE_SUCCESS: 'STAND_ACTIVE_SUCCESS',
    // STAND_ACTIVE_FAIL: 'STAND_ACTIVE_FAIL',

    // control unit
    CONTROL_UNIT_FETCH_REQUEST: 'CONTROL_UNIT_FETCH_REQUEST',
    CONTROL_UNIT_FETCH_SUCCESS: 'CONTROL_UNIT_FETCH_SUCCESS',
    CONTROL_UNIT_FETCH_FAIL: 'CONTROL_UNIT_FETCH_FAIL',

};
