import httpService from './http.service';

const createBike = (bike) => httpService
    .post(`/bike`, bike)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const updateBike = (bike) => httpService
    .put(`/bike`, bike)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const activeBike = (bikeId, active) => httpService
    .put(`/bike/${bikeId}/?active=${active}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const getBikes = () => httpService
    .get(`/bike/all`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getBikesByTenantId = (tenantId) => httpService
    .get(`/bike/tenant/${tenantId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getAvailableBikes = (dateFrom, dateTo, reservationId) => httpService
    .get(`/bike/available?dateFrom=${encodeURIComponent(dateFrom)}&dateTo=${encodeURIComponent(dateTo)}&ignoreReservationId=${reservationId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

export default {
    getBikes,
    getAvailableBikes,
    createBike,
    updateBike,
    activeBike,
    getBikesByTenantId,
};