import React from 'react';
import {useTranslation} from 'react-i18next';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import {useState} from 'react';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        width: '100%',
        padding: theme.spacing(2, 1, 1, 2),
        backgroundColor: theme.palette.background.paper,
    },
}));

const ToolBar = (props) => {
    const {
        handleFilter,
    } = props;

    const classes = useStyles();
    const {t} = useTranslation();

    const [searched, setSearched] = useState();

    const handleChange = (event) => {
        let tmp = {...searched};

        if ( event.target.id==='deviceId'){
            tmp.device = event.target.value;
        } else if ( event.target.id==='email') {
            tmp.email = event.target.value;
        }
        handleFilter(tmp);
        setSearched(tmp);
    }

    return(
        <div classes={classes.toolbar}>
            <Grid container spacing={2} style={{height: '100%', width: '100%', paddingTop: '2px', paddingLeft: '10px'}}>
                <Grid item sm={2} >
                    <TextField id='deviceId' label={t('DEVICE_ID')} variant='outlined' onChange={handleChange}/>
                </Grid>
                <Grid item sm={2} >
                    <TextField id='email' label={t('EMAIL')} variant='outlined' onChange={handleChange}/>
                </Grid>
                <Grid item sm={8}>
                </Grid>
            </Grid>
        </div>
    );
}

export default ToolBar;